html,
body,
div,
span,
applet,
object,
iframe,
h1,
.page-title .title,
h2,
h3,
.blog-grid .post .title,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
.draw-buttons .svg,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
.order-table th,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	margin: 0;
	padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
canvas,
main {
	display: block;
	font-family: "Public Sans", sans-serif !important;
}
html,
body {
	height: 100%;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
	cursor: pointer;
	outline: none;
	text-decoration: none;
}
*:focus {
	outline: none;
}
*::-moz-focus-inner {
	border: 0;
}
.clear {
	clear: both;
}
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
a:active,
a:hover {
	outline: 0;
}
/** Corrects `inline-block` display not defined in IE 8/9.*/
audio,
canvas,
video {
	display: inline-block;
}
/** Prevents modern browsers from displaying `audio` without controls. * Remove excess height in iOS 5 devices. */
audio:not([controls]) {
	display: none;
	height: 0;
}
*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
a,
button {
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img {
	border: 0px;
	outline: none;
}
/*---------------------------------------------base Css end------------------------------------------------------------*/

/*---------------------------------------------Default Css start------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Public Sans", sans-serif !important;
	color: #000;
}
body,
button,
input,
select,
table,
textarea,
p {
	font-family: "Public Sans", sans-serif !important;
	color: #636363;
	font-size: 14px;
}
/*h1{font-size:26px;line-height:32px;margin-bottom:32px;}*/
/*h2{font-size:22px;line-height:28px;margin-bottom:28px;}*/
/*h3{font-size:16px;line-height:22px;margin-bottom:22px;}*/
/*h4{font-size:15px;line-height:18px;margin-bottom:18px;}*/
/*h5{font-size:14px;line-height:18px;margin-bottom:18px;}*/
.clear {
	clear: both;
}
.top {
	width: 100%;
	float: left;
	min-height: calc(100vh - 88px);
}
.container {
	margin: 0px auto;
	height: auto;
	text-align: left;
	width: 100%;
	max-width: 1440px;
}
.master {
	margin: 0px;
	padding: 0px;
	height: auto;
	width: 100%;
	float: left;
}
/*---------------------------------------------Default Css end------------------------------------------------------------*/

/*****************************************************************default css start******************************************************************/
.sideBarLeftCol .top {
	width: calc(100% - 255px);
}
.overflowHidden {
	overflow: hidden;
}
.sideBarVisible.sideBarLeftCol .top {
	width: calc(100% - 103px);
}
.sidebarLogoNav.UlUnderLogo {
	display: none;
}

body .fontSize20{
	font-size: 20px;
}

/*.sideBarLeftCol {  background: #E6F1FB;  min-height:100vh;}*/
.sideBarLeftCol {
	background: #e6f1fb;
	min-height: 100vh;
	height: 100%;
	float: left;
	padding-right: 20px;
}
.zIndex2 {
	z-index: 2;
}
.mainRightSidebare {
	width: calc(100% - 235px);
	float: right;
	position: relative;
	z-index: 15;
}
.sideBarVisible .mainRightSidebare {
	width: calc(100% - 102px);
}
.fullWidth {
	width: 100%;
	float: left;
}
.width37 {
	width: 37%;
	float: left;
}
.width63 {
	width: 63%;
	float: left;
}

.width60 {
	width: 60%;
	float: left;
}
.width40 {
	width: 40%;
	float: left;
}
.width50 {
	width: 50%;
	float: left;
}
.width48 {
	width: 48%;
	float: left;
}
body .addressRow{
	padding-left: 120px;
}
body .marginNone{
	margin:0px;
}

body .addressRow .marginNone{
	margin:0px;
}

body .dependentBorderBox .institutionStateLabel{
	top:-11px;
}

body .addressRow .institutionStateLabel{
	top:-11px;
}

.addressRow label{
	width: 120px;
	text-align: right;
	margin-top: 8px;
	padding-right:20px;
}

.lblExpType.institutionNameLabel {
	top:13px
}
.width45 {
	width: 45%;
	float: left;
}
.width33 {
	width: 33.33%;
	float: left;
}
.cursorPointer{
	cursor: pointer;
}

.width32 {
	width: 32%;
	float: left;
}
.maxWidth600 {
	max-width: 600px;
}

.js-btw{
	justify-content: space-between;
}

body .maxWidth360{
	max-width: 360px;
}

.maxWidth392{
	max-width: 392px;
}
.displayInline{
	display: inline-block;
}
::placeholder {
	color: #c6cace;
	opacity: 1; /* Firefox */
	font-family: "Public Sans", sans-serif !important;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
}
:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #c6cace;
	opacity: 1; /* Firefox */
	font-family: "Public Sans", sans-serif !important;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
}

.myProfileAccount ::placeholder {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #c6cace !important;
	padding-bottom: 4px;
}

input.inputColum {
	filter: none;
}
.myAccountPersonalInfo ::placeholder {
	font-family: Public Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #a9aeb1;
}
.button {
	background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0466c8;
	background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0466c8;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0466c8;
	border-radius: 8px;
	width: 100%;
	max-width: 388px;
	height: 56px;
	cursor: pointer;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.15px;
	text-transform: capitalize;
	color: #ffffff;
	border: 0px;
	padding: 0 20px;
    text-overflow: ellipsis;
    overflow: hidden;
	/*margin:10px 0px;*/
}

.button:hover {
	background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
	background: -webkit--linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
}
.buttonDisable {
	opacity: 1;
	/*background: #c6cace;*/
	background: -moz-linear-gradient(0deg, rgba(198, 202, 206, 0.2), rgba(198, 202, 206, 0.2)), #c6cace;
	background: -webkit-linear-gradient(0deg, rgba(198, 202, 206, 0.2), rgba(198, 202, 206, 0.2)), #c6cace;
	background: linear-gradient(0deg, rgba(198, 202, 206, 0.2), rgba(198, 202, 206, 0.2)), #c6cace;
	border-radius: 8px;
	width: 100%;
	max-width: 388px;
	height: 56px;
	cursor: not-allowed;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.15px;
	text-transform: capitalize;
	color: #ffffff;
	border: 0px;
}

.saveButton {
	padding: 0px 20px;
	height: 44px;
	background: #34a0a4;
	background: -moz-linear-gradient(0deg, rgba(52, 160, 164, 0.2), rgba(52, 160, 164, 0.2)), #34a0a4;
	background: -webkit-linear-gradient(0deg, rgba(52, 160, 164, 0.2), rgba(52, 160, 164, 0.2)), #34a0a4;
	background: linear-gradient(0deg, rgba(52, 160, 164, 0.2), rgba(52, 160, 164, 0.2)), #34a0a4;
	box-shadow: 0px 1px 3px rgba(0, 94, 162, 0.24);
	border-radius: 4px;
	border: 1px solid #34a0a4;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #ffffff;
	cursor: pointer;
	min-width: 106px;
}

.saveButton:hover {
	background: -moz-linear-gradient(136.91deg, rgba(52, 160, 164, 0.2) 13.98%, rgba(133, 200, 148, 0.2) 94.33%), linear-gradient(132.46deg, #34a0a4 0%, #85c894 100%);
	background: -webkit--linear-gradient(136.91deg, rgba(52, 160, 164, 0.2) 13.98%, rgba(133, 200, 148, 0.2) 94.33%), linear-gradient(132.46deg, #34a0a4 0%, #85c894 100%);
	background: linear-gradient(136.91deg, rgba(52, 160, 164, 0.2) 13.98%, rgba(133, 200, 148, 0.2) 94.33%), linear-gradient(132.46deg, #34a0a4 0%, #85c894 100%);
	box-shadow: 0px 1px 3px rgba(0, 94, 162, 0.24);
	border-radius: 4px;
}
.saveButtonDisable {
	padding: 0px 20px;
	height: 44px;
	/*background: #c6cace;*/
	background: -moz-linear-gradient(0deg, rgba(198, 202, 206, 0.2), rgba(198, 202, 206, 0.2)), #c6cace;
	background: -webkit-linear-gradient(0deg, rgba(198, 202, 206, 0.2), rgba(198, 202, 206, 0.2)), #c6cace;
	background: linear-gradient(0deg, rgba(198, 202, 206, 0.2), rgba(198, 202, 206, 0.2)), #c6cace;
	box-shadow: 0px 1px 3px rgba(0, 94, 162, 0.24);
	border-radius: 4px;
	border: 1px solid #c6cace;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #ffffff;
	cursor: not-allowed;
	min-width: 106px;
	pointer-events: none;
}


.doneButton {
	padding: 0px 20px;
	height: 44px;
	background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0466c8;
	background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0466c8;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0466c8;
	box-shadow: 0px 1px 3px rgba(0, 94, 162, 0.24);
	border-radius: 4px;
	border: 1px solid #34a0a4;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #ffffff;
	cursor: pointer;
	min-width: 106px;
}

.doneButton:hover {
	background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
	background: -webkit--linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
	border-radius: 4px;
}
.doneButtonDisable {
	padding: 0px 20px;
	height: 44px;
	background: #c6cace;
	background: -moz-linear-gradient(0deg, rgba(198, 202, 206, 0.2), rgba(198, 202, 206, 0.2)), #c6cace;
	background: -webkit-linear-gradient(0deg, rgba(198, 202, 206, 0.2), rgba(198, 202, 206, 0.2)), #c6cace;
	background: linear-gradient(0deg, rgba(198, 202, 206, 0.2), rgba(198, 202, 206, 0.2)), #c6cace;
	box-shadow: 0px 1px 3px rgba(0, 94, 162, 0.24);
	border-radius: 4px;
	border: 1px solid #c6cace;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #ffffff;
	cursor: not-allowed;
	min-width: 106px;
}
.widthAuto {
	width: auto;
	display: inline-block;
}
.cancelButton1 {
	width: 120px;
	height: 44px;
	background: #ffffff;
	background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #ffffff;
	background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #ffffff;
	background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #ffffff;

	border: 1px solid #8d9297;
	box-sizing: border-box;
	border-radius: 4px;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #8d9297;
	text-decoration: none;
	cursor: pointer;
}
.buttonGreenColor {
	padding: 0px 20px;
	height: 56px;
	background: #34a0a4;
	background: -moz-linear-gradient(0deg, rgba(52, 160, 164, 0.2), rgba(52, 160, 164, 0.2)), #34a0a4;
	background: -webkit-linear-gradient(0deg, rgba(52, 160, 164, 0.2), rgba(52, 160, 164, 0.2)), #34a0a4;
	background: linear-gradient(0deg, rgba(52, 160, 164, 0.2), rgba(52, 160, 164, 0.2)), #34a0a4;
	box-shadow: 0px 1px 3px rgba(0, 94, 162, 0.24);
	border-radius: 4px;
	border: 0px solid #34a0a4;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #ffffff;
	cursor: pointer;
	width: 100%;
}
.buttonlaerUp {
	position: relative;
	z-index: 1;
}
.buttonGreenColor:hover {
	background: -moz-linear-gradient(136.91deg, rgba(52, 160, 164, 0.2) 13.98%, rgba(133, 200, 148, 0.2) 94.33%), linear-gradient(132.46deg, #34a0a4 0%, #85c894 100%);
	background: -webkit--linear-gradient(136.91deg, rgba(52, 160, 164, 0.2) 13.98%, rgba(133, 200, 148, 0.2) 94.33%), linear-gradient(132.46deg, #34a0a4 0%, #85c894 100%);
	background: linear-gradient(136.91deg, rgba(52, 160, 164, 0.2) 13.98%, rgba(133, 200, 148, 0.2) 94.33%), linear-gradient(132.46deg, #34a0a4 0%, #85c894 100%);
	box-shadow: 0px 1px 3px rgba(0, 94, 162, 0.24);
	border-radius: 4px;
}
.grayButtonColorBorder {
	color: #8d9297;
	border: 1px solid #8d9297;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.15px;
	color: #8d9297;
	border-radius: 4px;
	line-height: 44px;
	text-align: center;
	display: inline-block;
	padding: 0px 16px;
	height: 44px;
	cursor: pointer;
	min-width: 120px;
}

.inviteUserPopUpButtonYes {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0466c8;
	border-radius: 4px;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.15px;
	color: #ffffff;
	height: 44px;
	width: 133px;
	line-height: 44px;
	float: right;
	cursor: pointer;
}
.inviteUserPopUpButtonYes:hover {
	background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
	background: -webkit--linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
}
.inviteUserPopUpButtonYesDisable {
	background: #c6cace;
	color: #ffffff;
	height: 44px;
	cursor: not-allowed;
	border-radius: 4px;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.15px;
	width: 133px;
	line-height: 44px;
	float: right;
}
.width100Percent {
	width: 100%;
}
.textAlignRight {
	text-align: right;
}
.texAlingCener {
	text-align: center;
}
.texAlingLeft {
	text-align: left;
}
.title {
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 44px;
	color: #1c1d1f;
}
.title1 {
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 44px;
	color: #1c1d1f;
}
.positionRelative {
	position: relative;
}
.errorRelativeRow {
	position: relative;
	width: 100%;
	float: left;
}

.inputColum {
	border: 0px;
	border-bottom: 1px solid #3D4551;
	width: 100%;
	margin-bottom: 2px;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #2d2e2f;
	padding-bottom: 1px;
}
.inputColum24size, .react-tel-input .form-control {
	border: 0px;
	border-bottom: 1px solid #3D4551;
	width: 100%;
	margin-bottom: 2px;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #2d2e2f;
	padding-bottom: 1px;
}
input:focus,
textarea:focus,
select:focus {
	outline: none !important;
	border-color: #2f6db3 !important;
}

.borderBottomBlue {
	border-bottom:1px solid #0096c7;
}
/* .react-tel-input .form-control:focus{
	border-bottom:1px solid #2D6CB21F !important;
}
.react-tel-input .form-control{
	border-bottom:1px solid #cacaca !important;
} */

input[type="button"]:focus {
	outline: none !important;
	border-color: transparent;
}

.height100{
	height: 100%;
}

.height90{
	height: 90px;
}
.height50{
	height: 70px;
}
.maxWidth338 {
	max-width: 338px;
	width: 100%;
}
.maxWidth275 {
	max-width: 275px;
	width: 100%;
}
.maxWidth138 {
	max-width: 138px;
	width: 100%;
}
.maxWidth388 {
	max-width: 388px;
	width: 100%;
}
.maxWidth270 {
	max-width: 270px;
	width: 100%;
}

.maxWidth438 {
	max-width: 438px;
	width: 100%;
}

.bgWhite{
	background:#fff;
}

.width192{
	width: 192px;
}

.successMessageWithBackground {
	background: rgba(0, 169, 28, 0.08);
	border-radius: 4px;
	width: 100%;
	padding: 12px;
	display: flex;
	flex-direction: row;
	float: left;
}
.successIcon {
	margin-right: 12px;
	display: inline-block;
}
.successTextContent {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	/* or 117% */
	display: flex;
	align-items: center;
	letter-spacing: 0.4px;
	color: #4d8055;
}
.checkboxColMain {
	display: flex;
	align-items: center;
	width: auto;
	float: left;
}
.checkboxCustom {
	position: relative;
}
.checkboxCustom input[type="checkbox"] {
	/*transform: scale(1.25);*/
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 16px;
	height: 16px;
	border: 1px solid #71767a;
	box-sizing: border-box;
	border-radius: 2px;
	background: #fff;
	position: relative;
	z-index: 2;
}
.checkboxCustom input[type="checkbox"]:checked + label {
	width: 16px;
	height: 16px;
	display: inline-block;
	background: #0096c7fc;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}
.checkboxCustom input[type="checkbox"]:checked {
	background: transparent;
}

.checkboxCustom input[type="checkbox"]:checked + label span {
	position: absolute;
	display: inline-block;
	transform: rotate(45deg);
	transform: rotate(45deg);
	transform: rotate(45deg);
	height: 10px;
	width: 6px;
	border-bottom: 2px solid #fff;
	border-right: 2px solid #fff;
	left: 6px;
	top: 2px;
}

/*.loader-1 {*/
/*width: 48px;*/
/*height: 48px;*/
/*border: 5px solid #fff;*/
/*border-bottom-color: #0397C8;*/
/*border-radius: 50%;*/
/*display: inline-block;*/
/*-webkit-animation: rotation 1s linear infinite;*/
/*animation: rotation 1s linear infinite;*/
/*}*/
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/*.divLoader {*/
/*width: 100vw;*/
/*height: 100%;*/
/*display: flex;*/
/*align-items: center;*/
/*justify-content: center;*/
/*position: fixed;*/
/*left: 0px;*/
/*top: 0px;*/
/*background: rgba(0,0,0,0.3);*/
/*z-index: 9;*/
/*}*/
.loader-1 {
	width: 28px;
	height: 28px;
	border: 3px solid #ccc;
	border-bottom-color: #0397c8;
	border-radius: 50%;
	display: inline-block;
	animation: rotation 1s linear infinite;
}
.divLoader {
	width: 100%;
	height: auto;
	align-items: center;
	justify-content: center;
	left: 0px;
	top: 0px;
	z-index: 9;
	display: flex;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.transparentLayer {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 199;
	left: 0px;
	top: 0px;

}

.paddingLeft1 {
	padding-left: 9px;
}
.paddingRight1 {
	padding-right: 9px;
}
.marginLeftRightAuto {
	margin: 0px auto;
}
.height44 {
	height: 44px;
}
body .borderRadius4 {
	border-radius: 4px;
}
body .paddingLeftPaddingRight35 {
	padding-left: 35px;
	padding-right: 35px;
}
.marginTopBottom10 {
	margin-top: 10px;
	margin-bottom: 10px;
}
.marginTopBottom20 {
	margin-top: 20px;
	margin-bottom: 20px;
}
.marginTopBottom5 {
	margin-top: 5px;
	margin-bottom: 5px;
}
.marginTop10 {
	margin-top: 10px;
}

body .padLeftRigh40 {
	padding-left:40px;
	padding-right:40px;
}
body .paddingBottom70{
	padding-bottom: 70px;
}

body .paddingBottom80{
	padding-bottom: 80px;
}
body .paddingBottom100{
	padding-bottom: 100px;
}

.marginTop15 {
	margin-top: 15px;
}
.marginTop12 {
	margin-top: 15px;
}
.marginTop18 {
	margin-top: 18px;
}
.marginTop7 {
	margin-top: 7px;
}
.marginTop5 {
	margin-top: 5px;
}
.marginTop30 {
	margin-top: 30px;
}
.marginTop20 {
	margin-top: 20px;
}
.marginTop40 {
	margin-top: 40px;
}
.marginTop50 {
	margin-top: 50px;
}
.marginTop25 {
	margin-top: 25px;
}
.marginTop35 {
	margin-top: 35px;
}
.marginTop45 {
	margin-top: 45px;
}
.paddingBottom10 {
	padding-bottom: 10px;
}
.paddingBottom15 {
	padding-bottom: 15px;
}
.paddingBottom20 {
	padding-bottom: 20px;
}
.paddingBottom5 {
	padding-bottom: 5px;
}
.marginTop28 {
	margin-top: 28px;
}
.marginTop12 {
	margin-top: 12px;
}
.marginTop70 {
	margin-top: 70px;
}
body .marginTop60 {
	margin-top: 60px;
}
.marginButton {
	margin-left: 20px;
	margin-right: 62px;
}
.marginBottom80 {
	margin-bottom: 80px;
}
.marginBottom15 {
	margin-bottom: 15px;
}
.marginLeft20 {
	margin-left: 20px;
}
.marginLeft10 {
	margin-left: 10px;
}
.marginBottom50 {
	margin-bottom: 50px;
}
.marginBottom70 {
	margin-bottom: 70px;
}
.marginBottom20 {
	margin-bottom: 20px;
}
.marginBottom30 {
	margin-bottom: 30px;
}
.marginBottom40 {
	margin-bottom: 40px;
}
.marginBottom80 {
	margin-bottom: 80px;
}
.marginBottom10 {
	margin-bottom: 10px;
}
.marginBottom5 {
	margin-bottom: 5px;
}
.paddingRight16 {
	padding-right: 16px;
}
.paddingleft16 {
	padding-left: 16px;
}
.marginLeft40 {
	margin-left: 40px;
}
.marginRight40 {
	margin-right: 40px;
}
.marginLeftRight2percent {
	margin-left: 2%;
	margin-right: 2%;
}
.marginRight30 {
	margin-right: 30px;
}
.marginRight20 {
	margin-right: 20px;
}
.marginRight10 {
	margin-right: 10px;
}
body .marginTop80 {
	margin-top: 80px;
}
.paddingTop30 {
	padding-top: 30px;
}
.paddingTop20 {
	padding-top: 20px;
}
.paddingTop35 {
	padding-top: 35px;
}
.paddingLeft35 {
	padding-left: 35px;
}
div.paddingBottom100 {
	padding-bottom: 100px;
}
.paddingRight35 {
	padding-right: 35px;
}
.marginTop7 {
	margin-top: 7px;
}
.positionAbsouteBottom {
	position: absolute;
	bottom: 0px;
}
.displayBlock {
	display: block;
}
.displayInlineBlock {
	display: inline-block;
}
.lineHeight57 {
	line-height: 57px;
}
body .padding15{
	padding:15px
}
body .padding10{
	padding:10px
}
body .margoinNone {
	margin: 0px !important;
}
body .left10{
	left: 10px !important;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}
.codeInput {
	background: rgba(4, 102, 200, 0.05);
	border-radius: 4px;
	max-width: 160px;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 2px;
	color: #2d2e2f;
	border: 1px solid rgba(4, 102, 200, 0.05);
	text-align: center;
	height: 44px;
	padding-left: 5px;
	padding-right: 6px;
}
.errorMessage {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.4px;
	color: #D96941;
	position: absolute;
	left: 0px;
	top: 0px;
}
.errorInputBorderRed, .selectError.dependentResidencCol .dependentResidence__control {
	border-bottom: 1px solid #d96941 !important;
}
body .errorInputBorderRedInput input.form-control {
	border-bottom: 1px solid #d96941 !important;
}
.errorInputAllBorderRed {
	border: 1px solid #d96941 !important;
}

.errorMessageWithBackground {
	background: rgba(216, 57, 51, 0.04);
	border-radius: 4px;
	width: 100%;
	padding: 12px;
	display: flex;
	flex-direction: row;
}
.exclamationErrorIcon {
	margin-right: 12px;
	display: inline-block;
	width: 17px;
}
.errorTextContent {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	/* or 125% */
	display: flex;
	align-items: center;
	letter-spacing: 0.4px;
	color: #d83933;
}
.bg_ligthBlueColor {
	background: #e6f1fb;
}
.acceptSubscription {
	font-size: 20px;
	color:#3D4551;
}

.minScreenHeight {
	height: 100vh;
}
.displayBlock {
	display: block;
}

.right65_bottom30{
	bottom:30px;
	right:65px
}
/*!*****Drawer css start****!*/
/*.MuiDrawer-paperAnchorDockedLeft{width:235px; border-right:0px !important;}*/
/*.MuiPaper-root{background:transparent !important;}*/
/*.MuiList-padding{padding-left:10px; padding-right:10px;}*/

/*!******Drawer css start*****!*/

.sideBarNav {
	max-width: 235px;
	height: 100%;
	float: left;
	width: 235px;
	position: fixed;
	z-index: 10;
}

.sideBarNav.sideBarIconOnly .sidebarLogo {
	width: 24px;
	overflow: hidden;
}
.sideBarNav ul {
	padding: 0px 10px;
	float: left;
	width: 100%;
	min-width: 225px;
}
.versionNameNavBottom {
	width: 100%;
	padding-left: 22px;
	position: absolute;
	bottom: 20px;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	text-transform: uppercase;
	color: #a9aeb1;
}

.sideBarIconOnly .versionNameNavBottom {
	font-size: 14px;
	text-align: center;
	padding: 0px;
}
.sideBarNav.sideBarIconOnly ul {
	min-width: auto;
}
.sideBarNav ul li {
	height: 60px;
	float: left;
	width: 100%;
}
.sideBarNav ul li a {
	display: block;
	padding: 18px 0px;
	-khtml-border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	-border-radius: 8px;
	display: flex;
	align-items: center;
}
.sideBarNav ul li .activeNave {
	background: #0352a0;
}
.sideBarNav ul li a.activeNave:hover {
	background: #0352a0;
}
.sideBarNav ul li a:hover {
	background: #f6fbfe;
}
.sideBarNavIcon {
	width: 23px;
	margin-left: 15px;
	margin-right: 13px;
	float: left;
	text-align: center;
}
.sideBarNavText {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	text-transform: uppercase;
	color: #7ba4cd;
	width: 150px;
}
/*.sideBarNavText span{*/
/*opacity:1;*/
/*}*/
/*.sideBarIconOnly .sideBarNavText span{*/
/*opacity:0;*/
/*}*/
.sideBarNav ul li .activeNave .sideBarNavText {
	color: #fff;
}
.navIcon {
	margin-left: 25px;
	margin-right: 9px;
	cursor: pointer;
}
.sidebarLogoNav {
	width: 100%;
	float: left;
}
.sidebarLogoNav {
	display: flex;
	align-items: center;
	width: 100%;
	float: left;
	margin-top: 45px;
	margin-bottom: 22px;
}
.sidebarLogo img {
	max-width: 144px;
}
.sidebarLogo {
	width: 144px;
	cursor: pointer;
}
.sideBarNav.sideBarIconOnly {
	max-width: 102px;
}
.sideBarNav.sideBarIconOnly .sideBarNavText {
	/*display: none;*/
	width: 0px;
	overflow: hidden;
}
.sideBarNav.sideBarIconOnly span.sideBarNavIcon {
	width: 100%;
	/* margin: 0px; */
}

.sideBarNav span.sideBarNavLabel {
	width: 150px;
	display: block;
}

.headerBack {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.15px;
	color: rgba(0, 150, 199, 0.99);
	cursor: pointer;
	margin-left: 0px;
	position: absolute;
}
/******Tab start*********/

.tabRow ul li.displayNone{display: none;}

.tabRow ul li {
	width: 33.33%;
	display: inline-block;
	text-align: center;
	padding: 24px 0px;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 24px;
	color: #8d9297;
	cursor: pointer;
}
.tabRow ul li.active {
	background: rgba(0, 150, 199, 0.99);
	-moz-border-radius: 0px 0px 0px 0px;
	-khtml-border-radius: 8px 0px 0px 0px;
	-webkit-border-radius: 8px 0px 0px 0px;
	border-radius: 8px 0px 0px 0px;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: rgba(255, 255, 255, 0.99);
	position: relative;
}
.tabRow ul li:nth-child(1).active,
.tabRow ul li:nth-child(1):hover {
	border-radius: 8px 0px 0px 0px;
}
.tabRow ul li:nth-child(2).active,
.tabRow ul li:nth-child(2):hover {
	border-radius: 0px 0px 0px 0px;
}
.tabRow ul li:nth-child(3).active,
.tabRow ul li:nth-child(3):hover {
	border-radius: 0px 8px 0px 0px;
}

.tabRow ul.twoChildLi li {
	width: 50%	;
}
.minWidht190 {
	min-width: 190px;
}
.tabContainer {
	background: #ffffff;
	border: 1px solid #ebf2f9;
	box-sizing: border-box;
	-moz-border-radius: 0px 0px 8px 8px;
	-khtml-border-radius: 0px 0px 8px 8px;
	-webkit-border-radius: 0px 0px 8px 8px;
	border-radius: 0px 0px 8px 8px;
	min-height: 590px;
	float: left;
}
.myAcHeight{
	min-height: calc(100vh - 204px);
}
.tabRow ul li.active:before {
	content: "";
	width: 0px;
	height: 0px;
	border-color: rgba(0, 150, 199, 0.99) transparent transparent;
	bottom: -20px;
	border-style: solid;
	border-width: 10px 10px 10px 10px;
	position: absolute;
	left: 44%;
	z-index: 1;
}

.tabRow ul li:hover:before {
	content: "";
	width: 0px;
	height: 0px;
	border-color: rgba(235, 241, 248, 0.99) transparent transparent;
	bottom: -20px;
	border-style: solid;
	border-width: 10px 10px 10px 10px;
	position: absolute;
	left: 44%;
}

.tabRow ul li:hover {
	background: rgba(235, 241, 248, 0.99);
	-moz-border-radius: 0px 0px 0px 0px;
	-khtml-border-radius: 8px 0px 0px 0px;
	-webkit-border-radius: 8px 0px 0px 0px;
	border-radius: 8px 0px 0px 0px;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #8d9297;
	position: relative;
}
.tabRow ul li.active:hover {
	background: rgba(0, 150, 199, 0.99);
	color: rgba(255, 255, 255, 0.99);
	font-style: normal;
	font-weight: 600;
}
.tabRow ul li.active:hover:before {
	border-color: rgba(0, 150, 199, 0.99) transparent transparent;
}
/******Tab end*********/
/*!******Drawer css end*****!*/

/*.rightPanelWidhtSidebar{*/
/*width: calc(100% - 255px);*/
/*float:left*/
/*}*/
.rightPanelWidhtSidebarContainer {
	background: #fff;
	-khtml-border-radius: 16px;
	-moz-border-radius: 16px;
	-webkit-border-radius: 16px;
	border-radius: 16px;
	margin-top: 12px;
	padding: 30px;
	min-height: calc(100vh - 24px);
}
.titleWithSidebar {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: #3d4551;
}

.titleBackButton {
	display: none;
}
.tabRow ul {
	background: #e2e8ef;
	-khtml-border-radius: 8px 8px 0px 0px;
	-moz-border-radius: 8px 8px 0px 0px;
	-webkit-border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
}
.maxWidth1168 {
	max-width: 1168px;
}
.selectBoxParend {
	position: relative;
	padding-left: 160px;
}
.selectBox {
	border: 0px;
	border-bottom: 1px solid #ccc;
	min-width: 132px;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #3d4551;
}
.positionAbsoute {
	left: 0px;
	top: 0px;
	position: absolute;
}
.positionAbsouteTop4 {
	left: 0px;
	top: 4px;
	position: absolute;
}
.positionAbsouteOnly {
	position: absolute;
}
.positionFixed {
	position: fixed;
	z-index: 50;
}
.maxWidth138px {
	max-width: 138px;
}
.floatLeft {
	float: left;
}
.floatRight {
	float: right;
}

.astrciBlue {
	color: #34a0a4;
}

.fullScreenLoader, .loaderPol.height100.width100.positionAbsouteOnly {
	background: rgba(215, 215, 215, 0.4);
	box-shadow: 6px 27px 48px 11px rgba(0, 80, 157, 0.02);
	border-radius: 8px;
	display: flex;
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0px;
	top: 0px;
	text-align: center;
	align-items: center;
	justify-content: center;
	z-index: 50;
}

/***pop up css start*****/
.popMaxWidth {
	max-width: 607px;
}
.popTop {
	width: 100%;
	float: left;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	left: 0px;
	top: 0px;
	background: rgba(0, 0, 0, 0.06);
	z-index: 100;
}
.popContainer {
	width: 100%;
	margin: 0px auto;
}
.popMaster {
	width: 100%;
	float: left;
}
.popUpMainBg {
	padding: 25px;
	background: #ffffff;
	box-shadow: 6px 27px 48px 11px rgba(0, 0, 0, 0.02);
	-khtml-border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}
.popUpYesButton {
	background: #0466c8;
	background: -moz-linear-gradient(0deg, rgba(4, 102, 200, 0.2), rgba(4, 102, 200, 0.2)), #0466c8;
	background: -webkit-linear-gradient(0deg, rgba(4, 102, 200, 0.2), rgba(4, 102, 200, 0.2)), #0466c8;
	background: linear-gradient(0deg, rgba(4, 102, 200, 0.2), rgba(4, 102, 200, 0.2)), #0466c8;

	box-shadow: 0px 1px 3px rgba(0, 94, 162, 0.24);
	-khtml-border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	height: 44px;
	padding: 0px 16px;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 44px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #ffffff;
	display: inline-block;
	min-width: 120px;
	cursor: pointer;
}
.popUpYesButton:hover {
	background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
	background: -webkit--linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(132.46deg, #0352a0 0%, #0184bd 51.04%, #00b4d8 100%);
}
.popUpNoButton {
	background: #ffffff;
	border: 1px solid #0466c8;
	box-sizing: border-box;
	-khtml-border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	font-style: normal;
	font-weight: 500;
	min-width: 120px;
	font-size: 20px;
	line-height: 44px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #0466c8;
	display: inline-block;
	padding: 0px 16px;
	height: 44px;
	cursor: pointer;
}
/***pop up css end*****/

.animationall {
	-web-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.animationWidthOnly {
	-web-transition: width 0.5s ease-in-out;
	-moz-transition: width 0.5s ease-in-out;
	-o-transition: width 0.5s ease-in-out;
	transition: width 0.5s ease-in-out;
}

.loaderSpinner {
	margin: 10px auto;
	border-radius: 4px;
	overflow: hidden;
	max-width: 338px;
}
.loaderSpinner8radius {
	margin: 10px auto;
	border-radius: 8px;
	overflow: hidden;
	max-width: 338px;
}
.MuiLinearProgress-colorPrimary {
	background: #88bef5;
}
.MuiLinearProgress-barColorPrimary {
	color: #0466c8;
}

body 	label.practiceOrHosLabel{display: inline-block;
	width: 190px;
	text-align: left;
	top: -4px;}

.displayFlexCol {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.displayFlexColJC-start {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.logOutMinHeight795 {
	min-height: 769px;
}
.alignCenterVH {
	align-items: center;
	justify-content: center;
	display: flex;
}
.clickDisable, .activeDependentTab {
	pointer-events: none;
}
.disabled {
	pointer-events: none;
	opacity: 0.7;
}

/****loader start***/
.loaderCenterCol {
	width: 142px;
	height: 102px;
	background: #fdfdfe;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.justifyCenter {
	display: flex;
	justify-content: center;
}
.dot-pulse {
	position: relative;
	left: -9999px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #89b5ee;
	color: #89b5ee;
	box-shadow: 9999px 0 0 -5px #89b5ee;
	animation: dotPulse 1.5s infinite linear;
	animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #89b5ee;
	color: #89b5ee;
	left: 0px;
}

.dot-pulse::before {
	box-shadow: 9984px 0 0 -5px #89b5ee;
	animation: dotPulseBefore 1.5s infinite linear;
	animation-delay: 0s;
}

.dot-pulse::after {
	box-shadow: 10014px 0 0 -5px #89b5ee;
	animation: dotPulseAfter 1.5s infinite linear;
	animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
	0% {
		box-shadow: 9984px 0 0 -5px #89b5ee;
	}
	30% {
		box-shadow: 9984px 0 0 2px #89b5ee;
	}
	60%,
	100% {
		box-shadow: 9984px 0 0 -5px #89b5ee;
	}
}

@keyframes dotPulse {
	0% {
		box-shadow: 9999px 0 0 -5px #89b5ee;
	}
	30% {
		box-shadow: 9999px 0 0 2px #89b5ee;
	}
	60%,
	100% {
		box-shadow: 9999px 0 0 -5px #89b5ee;
	}
}

@keyframes dotPulseAfter {
	0% {
		box-shadow: 10014px 0 0 -5px #89b5ee;
	}
	30% {
		box-shadow: 10014px 0 0 2px #89b5ee;
	}
	60%,
	100% {
		box-shadow: 10014px 0 0 -5px #89b5ee;
	}
}

/****loader end****/
/*****************************************************************default css end******************************************************************/

/********Sign in screen css 1440 start*******************/
.topLogoRow {
	width: 100%;
	float: left;
}

.logoCol {
	width: auto;
	height: 88px;
	align-items: center;
	display: flex;
	padding-left: 40px;
}
.topRelativeCol {
	position: relative;
}
.top.topRelativeCol:after {
	content: "";
	position: absolute;
	z-index: -1;
	right: 0px;
	top: 0px;
	height: 100%;
	display: block;
	width: 47%;
	/*opacity:0.8;*/
	/*background: linear-gradient(45deg, #fff, #CFF1F8);*/
	/*background: -moz-linear-gradient(-45deg, rgba(255,255,255,0) 0%, rgba(225,225,225,0) 62%, rgba(207,241,248,1) 100%, rgba(207,241,248,1) 100%); !* FF3.6-15 *!*/
	/*background: -webkit-linear-gradient(-45deg, rgba(255,255,255,0) 0%,rgba(225,225,225,0) 62%,rgba(207,241,248,1) 100%,rgba(207,241,248,1) 100%); !* Chrome10-25,Safari5.1-6 *!*/
	/*background: linear-gradient(135deg, rgba(255,255,255,0) 0%,rgba(225,225,225,0) 62%,rgba(207,241,248,1) 100%,rgba(207,241,248,1) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
	/*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#e0e0e0',GradientType=1 ); !* IE6-9 fallback on horizontal gradient *!*/
	background: -moz-linear-gradient(130.84deg, #00b4d8 -7.13%, rgba(255, 255, 255, 0) 49.07%);
	background: -webkit-linear-gradient(130.84deg, #00b4d8 -7.13%, rgba(255, 255, 255, 0) 49.07%);
	background: linear-gradient(130.84deg, #00b4d8 -7.13%, rgba(255, 255, 255, 0) 49.07%);
	transform: rotate(-180deg);
	opacity: 0.3;
}

.top.topRelativeCol:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0px;
	top: -88px;
	height: 100%;
	display: block;
	width: 53%;
	/*opacity:0.8;*/
	/*background: linear-gradient(45deg, #fff, #CFF1F8);*/
	/*background: -moz-linear-gradient(-45deg, rgba(255,255,255,0) 0%, rgba(225,225,225,0) 57%, rgba(227,242,230,1) 75%, rgba(227,242,230,1) 100%); !* FF3.6-15 *!*/
	/*background: -webkit-linear-gradient(-45deg, rgba(255,255,255,0) 0%,rgba(225,225,225,0) 57%,rgba(227,242,230,1) 75%,rgba(227,242,230,1) 100%); !* Chrome10-25,Safari5.1-6 *!*/
	/*background: linear-gradient(-44deg, rgba(255,255,255,0) 0%,rgba(225,225,225,0) 57%,rgba(227,242,230,1) 75%,rgba(227,242,230,1) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
	/*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#e0e0e0',GradientType=1 ); !* IE6-9 fallback on horizontal gradient *!*/
	/*background: -moz-linear-gradient(125.63deg, #85C894 -3.11%, rgba(133, 200, 148, 0.533333) 19.19%, rgba(133, 200, 148, 0) 44.68%);*/
	/*background: -webkit-linear-gradient(125.63deg, #85C894 -3.11%, rgba(133, 200, 148, 0.533333) 19.19%, rgba(133, 200, 148, 0) 44.68%);*/
	/*background: linear-gradient(125.63deg, #85C894 -3.11%, rgba(133, 200, 148, 0.533333) 19.19%, rgba(133, 200, 148, 0) 44.68%);*/
	background: -moz-linear-gradient(125.63deg, #85c894 -3.11%, rgba(133, 200, 148, 0.533333) 19.19%, rgba(133, 200, 148, 0) 44.68%);
	background: -webkit--linear-gradient(125.63deg, #85c894 -3.11%, rgba(133, 200, 148, 0.533333) 19.19%, rgba(133, 200, 148, 0) 44.68%);
	background: linear-gradient(125.63deg, #85c894 -3.11%, rgba(133, 200, 148, 0.533333) 19.19%, rgba(133, 200, 148, 0) 44.68%);
	opacity: 0.3;
}

.imgLeftSignCol {
	padding-left: 69px;
	width: 53%;
	float: left;
	height: 100%;
	display: flex;
	align-items: flex-end;
	height: calc(100vh - 88px);
}

.imgRightSignCol {
	width: 47%;
	float: left;
	position: relative;
}

.SignInLeftBanner {
	max-width: 100%;
}
.allRightReserveText {
	position: absolute;
	width: 100%;
	bottom: 12px;
	text-align: center;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: flex-end;
	letter-spacing: 0.25px;
	color: #8d9297;
	justify-content: center;
}
.allRightReserveText.positionNspacing {
	position: static;
	margin: 10px 0px;
}
.allRightReserveText span.signBottomLink {
	text-decoration: underline;
	padding: 0px 2px;
}
.signBottomLink {
	color: #8d9297;
	text-decoration: underline;
}

.signInFormBox {
	min-height: calc(100vh - 88px);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.signColCenter {
	width: 100%;
	padding: 55px 62px 50px 62px;
	max-width: 512px;
	background: #fff;
	/*box-shadow: -10px 0px 10px 1px #aaaaaa;*/
	-webkit-box-shadow: 6px 27px 48px 11px rgba(0, 0, 0, 0.02);
	-moz-box-shadow: 6px 27px 48px 11px rgba(0, 0, 0, 0.02);
	box-shadow: 6px 27px 48px 11px rgba(0, 0, 0, 0.02);
}

.signColCenterInviteUser {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 35px;
	max-width: 512px;
	background: #fff;
	/*box-shadow: -10px 0px 10px 1px #aaaaaa;*/
	-webkit-box-shadow: 6px 27px 48px 11px rgba(0, 0, 0, 0.02);
	-moz-box-shadow: 6px 27px 48px 11px rgba(0, 0, 0, 0.02);
	box-shadow: 6px 27px 48px 11px rgba(0, 0, 0, 0.02);
}

.signColCenter ::placeholder {
	color: #71767a;
	opacity: 1; /* Firefox */
}

.signColCenter :-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #71767a;
}
.marginBottomInputCol {
	margin-bottom: 40px;
	margin-top: 20px;
}
.signColCenterInviteUser .marginBottomInputCol {
	margin-bottom: 35px;
	margin-top: 20px;
}

.forgotPasswordLink {
	margin-top: 6px;
}
.forgotPasswordLinkText {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #0397c8;
	cursor: pointer;
	margin-top: 4px;
	display: inline-block;
}
.keepLoggedInInput {
	margin-right: 17px;
	margin-left: 1px;
}
.keepLoggedIn {
	margin-top: 18px;
	margin-bottom: 16px;
}
.keepLoggedIn label {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.25px;
	color: #1c1d1f;
	display: flex;
	align-items: center;
}
/*.keepLoggedIn input[type=checkbox] {*/
/*transform: scale(1.25);*/
/*}*/
.signColCenterInviteUser .joinNowText {
	margin-top: 10px;
}
.joinNowText {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	color: #71767a;
	text-align: center;
	margin-top: 29px;
}

.joinNowText span {
	color: #0096c7fc;
	cursor: pointer;
}
.marginBottomTitl20 {
	margin-bottom: 20px;
}

.passwordTextShow {
	display: inline-block;
	position: absolute;
	right: 0px;
	top: -3px;
	color: #a9aeb1;
	cursor: pointer;
}
.PaddingPasswordIcon {
	padding-right: 30px;
}
.titleWelcomeToAccount {
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 44px;
	color: #1c1d1f;
	/*margin-bottom: 12px;*/
}
.titleWelcomeToAccount span {
	color: #0466c8;
}
.signInformPaddingTop {
	padding-top: 34px;
}
.sameUserLoggedInRow {
	margin-top: 12px;
	margin-bottom: 50px;
	position: relative;
	min-height: 68px;
	padding-left: 68px;
}
.userImg {
	width: 68px;
	height: 68px;
	overflow: hidden;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-khtml-border-radius: 50%;
	border-radius: 50%;
	position: absolute;
	left: 0px;
	top: 0px;
}
.userImg img {
	width: 100%;
}
.userNameEMail {
	padding-left: 20px;
	padding-right: 10px;
	margin-top: 13px;
}
.userName {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #3d4551;
	display: block;
}
.userEmail {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #3d4551;
}
.notYou {
	position: absolute;
	width: 70px;
	right: 0px;
	top: 13px;
}
.notYou span {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: rgba(0, 150, 199, 0.99);
	cursor: pointer;
}

/********Sign in screen css 1440px end*******************/

/********SignUp screen css 1440px start*******************/
.firstLastNameSpaceSignUp {
	margin: 32px 0px 23px 0px;
}
.signColCenterInviteUser .firstLastNameSpaceSignUp {
	margin: 37px 0px 10px 0px;
}
.signColCenterInviteUser .loaderSpinner {
	max-width: 388px;
}

.termAndPrivacyPolicyRowSignUp {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 31px;
	margin-bottom: 0px;
}
.termAndPrivacyPolicyText {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	color: #565c65;
	margin-top: -3px;
}
.signUpTextLinkColor {
	color: #0096c7;
	cursor: pointer;
}
.passwordMatchBox ul li {
	width: 50%;
	float: left;
	display: flex;
	align-items: flex-start;
	padding-left: 4px;
	margin: 5px 0px;
}
.passwordMatchBox ul li .passowrdListOfConditionText {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.4px;
	color: #565c65;
}
.signColCenterInviteUser .passwordMatchBox ul li .passowrdListOfConditionText {
	font-size: 14px;
}
.signColCenterInviteUser .passwordMatchBox ul li {
	width: 51%;
}
.signColCenterInviteUser li.passowrdListOfCondition:nth-child(2),
.signColCenterInviteUser li.passowrdListOfCondition:nth-child(4) {
	width: 46%;
}
li.passowrdListOfCondition:nth-child(2),
li.passowrdListOfCondition:nth-child(4) {
	width: 45%;
	float: right;
}
.rightIocn .checkIcon {
	width: 20px;
	height: 18px;
	margin-right: 5px;
	color: #8d9297;
}
.rightIocn .checkIconActive {
	width: 20px;
	height: 18px;
	margin-right: 5px;
	color: #00a91c;
}
.passwordMatchBox {
	margin-top: 25px;
}
input#duplicatePassword {
	/*opacity: 0;*/
	left: 0px;
	top: 0px;
	/*position: absolute;*/
	width: 95%;
	padding-right: 0px;
}
.allRightReserveText .signBottomLink {
	margin: 0px 4px;
}
.allRightReserveText span {
	margin-left: 3px;
}
/********SignUp screen css 1440px end*******************/

/******** email Verification screen css 1440px start *******************/

.mainChildBoxCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}
.emailVerificationCenterBox {
	max-width: 552px;
	padding: 36px 42px;
	background: #ffffff;
	box-shadow: 6px 27px 48px 11px rgba(0, 0, 0, 0.02);
	border-radius: 8px;
	width: 100%;
}
.emailVerificationIcon {
	margin-bottom: 13px;
}
.emailVerificationMessage {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #3d4551;
	margin-top: 8px;
	width: 100%;
	max-width: 460px;
	margin: 8px auto 0px auto;
}
.dontReceiveCodeText {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	color: #71767a;
	margin-top: 11px;
	margin-bottom: 13px;
}
.dontReceiveCodeText span {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: rgba(0, 150, 199, 0.99);
	cursor: pointer;
}
.cancelButton {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	text-decoration-line: underline;
	color: #71767a;
}
.cancelButton span {
	cursor: pointer;
}
.inputColField {
	display: flex;
	justify-content: center;
	margin-top: 50px;
}
.inputColFieldMarginNone {
	display: flex;
	justify-content: center;
}
.codeInputField {
	border: 0px;
	width: 44px !important;
	float: left;
	display: inline-block;
	margin: 5px 5px 5px 5px !important;
	text-align: center;
	border-bottom: 1px solid #c6cace !important;
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #1c1d1f;
}
.errorvisibleCode {
	border-bottom: 1px solid #e24444 !important;
}
/******** email Verification screen css 1440px end *******************/

.emailVerificationCenterBox .passwordMatchBox ul li .passowrdListOfConditionText {
	font-size: 14px;
}

/************************ my account 1440px start *******************/

/******** my account  - personal info 1440px start *******************/
.firstLastNameSpace,
.formRowGap {
	margin: 32px 0px 23px 0px;
}
.formRowGapSM{
	margin: 22px 0px 13px 0px;
}
.personalInfoNameRow,
.formRow {
	padding-left: 182px;
	position: relative;
	padding-right: 62px;
}
.payRow .width50 {
	padding-left: 182px;
}
.payRow .width50 + .width50{
	padding-left: 210px;
}
.payRow .width50 + .width50 label{
	padding-left: 40px;
}
.myAccountPersonalInfo label,
.formRow label {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #a9aeb1;
	padding-left: 40px;
	position: absolute;
	left: 0px;
	top: 33px;
	text-transform: uppercase;
}

.passwordLabel {
	padding-left: 220px;
}

.formRow sup{
	color: #0466c8;
	font-size: 18px;
	line-height: 1;
}

.myAccountPersonalInfo label.nameLable {
	top: 39px;
}
.myAccountPersonalInfo label.addressLable {
	top: 36px;
}
.myAccountPersonalInfo label.passordLable {
	top: 36px;
}

.myAccountPersonalInfo label.passordLable2 {
	top: 20px;
}

.myAccountPersonalInfo .react-tel-input .flag-dropdown {
	display: none;
}
.myAccountPersonalInfo .react-tel-input .form-control {
	border: 0px;
	border-bottom: 1px solid #3D4551;
	width: 100%;
	margin-bottom: 2px;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #a9aeb1;
	padding-left: 10px;
	border-radius: 0px;
}

.myAccountPersonalInfo .react-tel-input.phoneNumber_Active .form-control {
	color: #2d2e2f;
	/*color: red;*/
}
/****dropdown css********/
/*.phoneListIcon{*/
/*width:30px;*/
/*margin-right: 10px;*/
/*}*/
/*.phoneListText{*/
/*font-style: normal;*/
/*font-weight: normal;*/
/*font-size: 24px;*/
/*line-height: 24px;*/
/*letter-spacing: 0.4px;*/
/*color: #71767A;*/
/*}*/

/*.phoneListRow{*/
/*display: flex;*/
/*flex-direction:row;*/
/*padding:8px 0px;*/
/*align-items: center;*/
/*}*/
.phoneTypeCol .phoneType1__control {
	border: 0px;
	border-bottom: 1px solid #3D4551;
	border-radius: 0px;
	margin-top: -6px;
	min-height: 34px;
	height: 34px;
}
.phoneTypeCol .phoneType1__control:hover {
	border: 0px;
	border-bottom: 1px solid #3D4551;
}
.phoneType1__single-value .phoneListIcon {
	display: none;
}
.phoneTypeCol .phoneType1__value-container {
	padding: 0px;
}
span.css-1okebmr-indicatorSeparator {
	display: none;
}
.phoneTypeCol .phoneType1__control
 {
	border-color: #c4c4c4 !important;
	box-shadow: none !important;
}

.phoneTypeCol .phoneType1__control--is-focused.phoneType1__control--menu-is-open,
.phoneTypeCol .phoneType1__control--is-focused.phoneType1__control--menu-is-open:focus{
border-bottom:1px solid #0096c7 !important;
}

.formRowGap .assetType__control--is-focused.assetType__control--menu-is-open,
.formRowGap .assetType__control--is-focused.assetType__control--menu-is-open:focus{
	border-bottom:1px solid #0096c7 !important;
}
/* .formRowGap div.assetType__control, .formRowGap div.assetType__control--is-focused {
	border-bottom: 1px solid #c4c4c4 !important;
} */
.phoneTypeCol .phoneType1__menu {
	width: 250px !important;
	border-radius: 8px;
	overflow: hidden;
}
.phoneType1__value-container .selectColor {
	color: #c6cace;
}
.phoneType1__value-container .selectColor.phone1LabelActive {
	color: #3d4551;
}
body .zIndex19 {
	z-index: 19;
}
.phoneType1__input {
	caret-color: transparent;
}
.selectColor {
	color: #0297c8;
}
.stateCol .phoneType1__value-container {
	padding: 0px;
	padding-right: 0px;
}

.stateCol .phoneType1__control--is-focused.phoneType1__control--menu-is-open,
.stateCol .phoneType1__control--is-focused.phoneType1__control--menu-is-open:focus{
	border-bottom:1px solid #0096c7 !important;
}


.stateCol .phoneType1__control:hover {
	border: 0px;
	border-bottom: 1px solid #3D4551;
}

.stateCol .phoneType1__control {
	border: 0px;
	border-bottom: 1px solid #3D4551;
	border-radius: 0px;
	margin-top: -11px;
	height: 34px;
	min-height: 34px;
}
.stateCol .phoneType1__placeholder {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #c6cace;
}
.stateCol .phoneType1__option,
.stateCol .phoneType1__single-value {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #2d2e2f;
}
.stateCol .phoneType1__control,
.stateCol .phoneType1__control--is-focused,
.stateCol .phoneType1__control--is-focused:focus {
	border-color: #c4c4c4 !important;
	box-shadow: none !important;
}
.phoneTypeCol .phoneType1__menu-list {
	max-height: inherit !important;
}
.phoneTypeCol .phoneType1__menu-list div:before {
	width: 22px;
	margin-right: 12px;
	display: inline-block;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}



.newPhoneList .phoneTypeCol .phoneType1__menu-list div:nth-child(1):before {
	content: url("../images/mobile_gray.svg");
}
.newPhoneList .phoneTypeCol .phoneType1__menu-list div.phoneType1__option--is-selected:nth-child(1):before {
	content: url("../images/mobile_blue.svg");
}
.newPhoneList .phoneTypeCol .phoneType1__menu-list div:nth-child(2):before {
	content: url("../images/office_gray.svg");
}
.newPhoneList .phoneTypeCol .phoneType1__menu-list div.phoneType1__option--is-selected:nth-child(2):before {
	content: url("../images/office_blue.svg");
}
.newPhoneList .phoneTypeCol .phoneType1__menu-list div:nth-child(3):before {
	content: url("../images/home_gray.svg");
}
.newPhoneList .phoneTypeCol .phoneType1__menu-list div.phoneType1__option--is-selected:nth-child(3):before {
	content: url("../images/home_blue.svg");
}
.newPhoneList .phoneTypeCol .phoneType1__menu-list div:nth-child(4):before {
	content: url("../images/otherPhone_gray.svg");
}
.newPhoneList .phoneTypeCol .phoneType1__menu-list div.phoneType1__option--is-selected:nth-child(4):before {
	content: url("../images/otherPhone_blue.svg");
}


.phoneTypeCol .phoneType1__menu-list .phoneType1__option{display: flex; align-items: center; line-height: 17px;}

.phoneTypeCol .phoneType1__menu-list div:nth-child(1):before {
	content: url("../images/do-not-call.svg");
}
.phoneTypeCol .phoneType1__menu-list div.phoneType1__option--is-selected:nth-child(1):before {
	content: url("../images/do-not-call.svg");
}

.phoneTypeCol .phoneType1__menu-list div:nth-child(2):before {
	content: url("../images/mobile_gray.svg");
}
.phoneTypeCol .phoneType1__menu-list div.phoneType1__option--is-selected:nth-child(2):before {
	content: url("../images/mobile_blue.svg");
}
.phoneTypeCol .phoneType1__menu-list div:nth-child(3):before {
	content: url("../images/office_gray.svg");
}
.phoneTypeCol .phoneType1__menu-list div.phoneType1__option--is-selected:nth-child(3):before {
	content: url("../images/office_blue.svg");
}
.phoneTypeCol .phoneType1__menu-list div:nth-child(4):before {
	content: url("../images/home_gray.svg");
}
.phoneTypeCol .phoneType1__menu-list div.phoneType1__option--is-selected:nth-child(4):before {
	content: url("../images/home_blue.svg");
}
.phoneTypeCol .phoneType1__menu-list div:nth-child(5):before {
	content: url("../images/otherPhone_gray.svg");
}
.phoneTypeCol .phoneType1__menu-list div.phoneType1__option--is-selected:nth-child(5):before {
	content: url("../images/otherPhone_blue.svg");
}



.transferIco{
	position: relative;
	top: 3px;
}

.phoneTypeCol .phoneType1__menu-list .phoneType1__option {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #71767a;
}
.phoneTypeCol .phoneType1__menu-list div.phoneType1__option--is-selected {
	color: #0297c8;
}
.phoneTypeCol .phoneType1__single-value, .head24 {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #3d4551;
}
.phoneTypeCol .phoneNumberActive.phoneType1__single-value {
	color: #2d2e2f;
}
.phoneTypeCol .phoneType1__placeholder {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #c6cace;
}
/****dropdown css********/

/*****popUp css start********/
.cancelIcon {
	text-align: center;
}
.areYouText {
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 44px;
	text-align: center;
	color: #3d4551;
}
.useInfo {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #565c65;
	max-width: 455px;
	margin: 10px auto 0;
}
/*****popUp css end********/
.addressLin1DropDown {
	width: 100%;
	position: relative;
	z-index: 200;
}
.addressLin1DropDownInner {
	position: absolute;
	background: #ffffff;
	box-shadow: 6px 27px 48px 11px rgba(0, 0, 0, 0.02);
	-khtml-border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	width: 100%;
	padding: 15px 0px;
	height: 250px;
	overflow-y: scroll;
	overflow-x: auto;
	font-size: 18px;
	line-height: 24px;
	border: 1px solid #efefef;
	margin-top: 5px;
}
.addressListDropDown {
	padding: 5px 20px;
	border-bottom: 1px solid #efefef;
	cursor: pointer;
}
.addressListDropDownHighlight {
	padding: 5px 20px;
	border-bottom: 1px solid #efefef;
	cursor: pointer;
	background: #f1f1f1;
}
.addressListDropDown{
	padding: 5px 20px;
	border-bottom: 1px solid #efefef;
	cursor: pointer;
}

.addressHover:hover{
	padding: 5px 20px;
	border-bottom: 1px solid #efefef;
	cursor: pointer;
	background: #f1f1f1;
}


/******** my account  - personal info 1440px end *******************/
.passwordTabUsername {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #2d2e2f;
}
.EnterNewPasswordLabel {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #1c1d1f;
}

.width300Center {
	width: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}

/****************************************************************************************/
.logOutText {
	font-size: 20px;
}
.boder1px {
	border: 1px solid #efefef;
}
/****************************************************************************************/

/******** my account 1440px end *******************/

.myProfileBilling {
	align-items: center;
	padding: 70px 300px;
	font-size: 40px!important;
}

/***************************************Income and expense start*******************************************************************/
.attachedImgList.fileList > div + div{
	border:0px;
	padding-top:0px;
	margin-top:0px;
}

.attachedImgList.fileList{
	padding-left: 0px;
	padding-right: 0px;
	margin-top: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.attachedImgList.fileList .fileInner{
	width:46%;
	float: left;
	padding-top: 20px;
	margin-top: 10px;
	margin-right: 4%;
}

.attachedImgList.fileList .fileInner:nth-child(even){
	margin-left:4%;
	margin-right: 0px;
}
.attachedImgList.fileList .fileInner:nth-child(odd) {
	clear: both;
}
.uploadFileName {
	word-break: break-all;
	line-height: 1;
}
/***************************************Income and expense end*******************************************************************/



/*********document css start ***********/
.documentListRow{
	width:100%;
	float: left;
}
.documentListCol{
	width: 122px;
	float: left;
}
.inputfolderName input {
	width: 100%;
}
.inputfolderName input {
	font-size: 12px;
	width: 100%;
	line-height: 12px;
	border:0px;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #3D4551;
	text-align: center;
	overflow:hidden;
}

.inputfolderName input:focus {
	background: #f1f1f1;
	overflow-x: hidden;
	overflow-y: auto;
}

.inputfolderName ::placeholder {
	font-size: 12px !important;
	color:red
}


.documentDefaultCenterBox{
	max-width:715px;
	margin: 0px auto;
}
.pTopBottom15{
	padding-top: 15px;
	padding-bottom: 15px;
}
.documentTopRow{
	border-top:1px solid #EBEBEB;
	border-bottom:1px solid #EBEBEB;
}
.documentTopRow ul li {
	float: left;
	width: 30px;
	display: inline-block;
	margin-right: 25px;
	margin-left: 20px;
	cursor: pointer;

}
.documentPopUp .popUpYesButton{
	border: 0px;
}
.maxWidth465{
	max-width: 465px;
}
.marginAuto{
	margin:0px auto;
}
.cancelButton1:focus{
	border: 1px solid #8d9297 !important;
}
.dependentNameTitle {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color:#122E52;
	text-transform: capitalize;
}
.addIconDocument {
	display: none;
}
.border_top{
	border-top: 1px solid #EBEBEB;
}
.documentListRow {
	width: 100%;
	float: left;
	height: calc(100vh - 288px);
	overflow-y: auto;
}
.bottomLeftPart ul li{
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 20px;
	letter-spacing: 0.15px;
	text-transform: capitalize;
	color: #3D4551;
	display: flex;
	float: left;
	align-items: center;
	margin-right: 10px;
	margin-top: 20px;
	min-height: 26px;
}
.dateTime{
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.15px;
	text-transform: capitalize;
	color: #71767A;
}
.bottomLeftPart ul li span{
	margin-left: 5px;
	display: inline-block;
	margin-top: 4px;
}
.folderImgIcon img{
	width: 100%;
}
.documentListCol {
	text-align: center;
	width: 170px;
	height: 200px;
	padding:15px;
	margin: 25px 0px 0px 0px;
	border: 1px solid transparent;
	position: relative;
	/*z-index: 20;*/
}
.zIndex20 {
	z-index: 20;
}
.docNavIcon {
	display: none;
}
.bottomRightPart{
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	text-transform: capitalize;
	color: #71767A;
	margin-top:20px;
}

.inputfolderName textarea[readonly]:focus {
	background: transparent;
}

.inputfolderName textarea {
	border: 0px;
	text-align: center;
	width: 100%;
	border:0px;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #3D4551;
	word-break: break-word;
	resize: none;
	background: none;
	overflow: hidden;
}

.inputfolderName p {
	display: none;
}

.inputfolderName textarea:focus {
	background: #f1f1f1;
	overflow-x: hidden;
	overflow-y: auto;
}

.bgWhite{
	background: #fff;
}

.documentTitle span{ text-transform: capitalize;
}
.documentDragDrop{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.documentBottomRow{
	z-index: 2;
	position: relative;
}
.documentListRow .documentListCol:hover {
	background: rgba(0, 150, 199, 0.06);
	border-radius: 8px;
}
.documentListRow .documentListCol.selectedFileFolder {
	background: rgba(0, 150, 199, 0.06);
	border-radius: 8px;
	border:1px solid rgba(0, 150, 199, 0.3);
}
.documentListRow .documentListCol:hover .inputfolderName input, .documentListRow .documentListCol.selectedFileFolder input{
	background: transparent;
}
.documentListCol.imgCol img {
	height: 94px;
	width: auto;
}
.documentTopRow ul li span {
	cursor: pointer;
}

.reName_move {
	background: #fff;
	width: 228px;
	border-radius: 8px;
	text-align: left;
}

.reName_move ul li span.label{
	color:#565C65;
	font-size: 16px;
	line-height: 24px;
	margin-left: 15px;
}
.reName_move ul li {
	padding:10px;
	display: flex;
	align-items: center;
	padding-left: 20px;
}
.reName_move ul li:hover {
	background:#FBFCFD;
}
.docTop{top:115px;}

.listOfFolder {
	background: #fff;
	width: 328px;
	border-radius: 8px;
	text-align: left;
}
.listOfFolder .boxListTitle {
	color:#3D4551;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	padding:15px 25px;
	border-bottom:1px solid #DFE1E2;
}

.documentListbuttonRow {
	padding:10px 0px;
}
.documentListbuttonRow .buttonColCancel{
	font-size: 20px;
	line-height: 24px;
	color:#000;
}
.documentListbuttonRow .buttonColMove{
	font-size: 20px;
	line-height: 24px;
	color:#2D6CB2;
}
.borderRightDocument{
	border-right:1px solid #DFE1E2;
}
.docFolderNamIconRow {
	padding:10px 10px 10px 25px;

}
.docFolderName {
	font-size: 16px;
	line-height: 24px;
	color:#3D4551;
}

.docFolderNamIconRow:hover, .docFolderNamIconRow.selectedParentFolder {
	background:rgba(47, 109, 179, 0.12);
	position: relative;
}

.docFolderNamIconRow:hover:before, .docFolderNamIconRow.selectedParentFolder:before{content: '';position: absolute;background: #2F6DB3;width: 4px;height: 100%;left: 0px;}
body .reNameTitle {
	max-width:465px;
	margin:0px auto;
	padding-bottom: 20px;
	text-align: left;
	color:#3D4551;
}
.docList {
	height:215px;
	overflow: auto;
}
.textcapitalize{
	text-transform: capitalize;
}
.inputfolderName textarea {
	background:transparent !important;
}
.rightAlign{right:0px; left: inherit}
.bottomAlign {bottom:0px; top:inherit}
/*********document css end ***********/
.maxWidth310{max-width:310px;}


.bottom40{bottom: 40px;}
.paddingRight140{padding-right:140px}
.paddingRight70{padding-right: 70px;}

/************************************************ Dependent profile start 1440*************************************************************************************************/
/************************************************ Dependent profile main start  1440*************************************************************************************************/
.dependentBorderBox {
	border: 1px solid #ebf2f9;
	height: 100%;
	width: 100%;
	float: left;
	border-radius: 8px;
	padding: 30px 40px;
}
.dependentDefauldMinHeight {
	min-height: calc(100vh - 144px);
}
.dependentSubTitle {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #0466c8;
}
.dependentDefaultCenterBox {
	margin: 0px auto;
	max-width: 655px;
}
.thisDependentText p {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #565c65;
}
.dependentEditProfile label {
	max-width: 170px;
}
.dependentBorderBox .labelCol {
	left: 0px;
	top: 5px;
	text-transform: uppercase;
	position: absolute;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #a9aeb1;
}
.dependentBorderBox .labelCol.label_guardianApp {
	top: -13px;
}
.dependentBorderBox .labelCol.label_gender {
	top: 15px;
}
.dependentBorderBox .labelCol.residence {
	top: -2px;
}
.dependentBorderBox .labelCol.phoneStep4 {
	top: 8px;
}
.dependentBorderBox .labelCol.emailStep4 {
	top: 6px;
}
.dependentBorderBox .labelCol.phoneEditProfile {
	top: 8px;
}

.dependentRowSpace {
	margin: 32px 0px 23px 0px;
}
.dependentStepFormRow {
	padding-left: 182px;
	position: relative;
}
.dependentInviteuserFormRow {
	padding-left: 154px;
	position: relative;
}
.dependentStepEditFormRow {
	padding-left: 300px;
	position: relative;
}

.dependentGenderCol .dependentGender__control {
	border: 0;
	border-bottom: 1px solid #c4c4c4 !important;
	border-radius: 0;
	margin-top: -10px;
}
.dependentGenderCol .dependentGender__single-value .phoneListIcon {
	display: none;
}
.dependentGenderCol .dependentGender__value-container {
	padding: 0;
	margin-top: 9px;
}

.dependentGenderCol span.css-1okebmr-indicatorSeparator {
	display: none;
}
.dependentGenderCol .dependentGender__control,
.dependentGenderCol .dependentGender__control--is-focused,
.dependentGenderCol .dependentGender__control--is-focused:focus {
	border-bottom:1px solid #c4c4c4 !important;
	box-shadow: none !important;
}
/*.dependentGenderCol .dependentGender__menu{width:250px!important;border-radius:8px;overflow:hidden}*/
.dependentGenderCol .dependentGender__value-container .selectColor {
	color: #c6cace;
}
.dependentGenderCol .dependentGender__value-container .selectColor.phone1LabelActive {
	color: #3d4551;
}
.dependentGenderCol .dependentGender__input {
	caret-color: transparent;
}
.dependentGenderCol .dependentGender__placeholder {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #c6cace;
}
.dependentGenderCol .dependentGender__option {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #71767a;
}
.dependentGenderCol .dependentGender__option.dependentGender__option--is-disabled {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #565c65;
	background: #fbfcfd;
}
.dependentGenderCol .dependentGender__single-value {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #3d4551;
}
.dependentGenderCol .dependentGender__option.dependentGender__option--is-selected {
	background: #fbfcfd;
}
.DependentStep1FormCol {
	min-height: calc(100vh - 238px);
}
.DependentStep2FormCol {
	min-height: calc(100vh - 315px);
}
.dependentStep3FormCol {
	min-height: calc(100vh - 268px);
}
.dependentButtonRowBottom {
	position: absolute;
	bottom: 70px;
	right: 0px;
}
.phoneTypeCol .phoneType1__menu {
	max-width: 100% !important;
}
.step4Main .myProfilePhoneCol1_600 .phoneDropDownResponsiveCol, .step4Main .myProfilePhoneCol2_600 .phoneDropDownResponsiveCol {
	margin-bottom: 15px;
}

.dependentResidencCol .dependentResidence__control {
	min-height: 34px;
	height: 34px;
}
label.custom-file-uploadImage:before{
	opacity: 1;
}
.dependentGenderCol input[type="radio"] {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
	width: 67px;
	height: 44px;
	background: #ffffff;
	border: 1px solid #c6cace;
	box-sizing: border-box;
	border-radius: 4px;
}
/* .dependentGenderCol input[type="radio"]:hover, .dependentGenderCol input[type="radio"]:focus {
	background: #0096C7;
	border: 1px solid rgba(4, 102, 200, 0.68);
	box-sizing: border-box;
	border-radius: 4px;
}
.dependentGenderCol [type="radio"]:checked:hover + span {
	background: #0096C7;
	color: #c6cace;
} */
.dependentGenderCol span {
	display: inline-block;
	position: relative;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #c6cace;
	width: 67px;
	border-radius: 4px;
	height: 44px;
	line-height: 42px;
	border:1px solid #c6cace;
}

/* .dependentGenderCol [type="radio"]:checked, .dependentGenderCol [type="radio"]:checked:hover {
	background: #0096C7;
	border: 1px solid #0096C7;
} */

.dependentBorderBox labelCol.labelColCity {
	top:12px;
}
.dependentGenderCol span:hover, .dependentGenderCol input[type="radio"]:focus + span{
	border:1px solid #0466C8;
}

.dependentGenderCol [type="radio"]:checked + span {
	background: #0466C8;
	border:1px solid #0466C8;
	color: #fff;

}
.dependentGenderCol label {
	width: 67px;
	display: inline-block;
	position: relative;
	margin-right: 30px;
	cursor: pointer;
}

.dependentGenderCol span {
	position: absolute;
	left: 0px;
	top: 3px;
}
.dependentGenderCol [type="radio"]:focus + span{
	outline: 1px dotted #0096C7;
}
input[type="file"] {
	display: none;
}
.custom-file-upload {
	width: 144px;
	height: 144px;
	position: relative;
	display: inline-block;
	cursor: pointer;
}
label.custom-file-upload:before {
	content: url("../images/cameraUploadImg.svg");
	position: absolute;
	width: 126px;
	height: 126px;
	line-height: 144px;
	background: rgba(0, 0, 0, 0.45);
	border-radius: 50%;
	margin: 9px;
	opacity: 0;
	display: inline-block;
	-web-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	/**/
}
label.custom-file-upload:hover:before {
	opacity: 1;
}

.custom-file-uploadEditComponent {
	width: 76px;
	height: 76px;
	position: relative;
	display: inline-block;
	cursor: pointer;
	text-align: center;
	border-radius: 50%;
	overflow: hidden;
}
label.custom-file-uploadEditComponent:before {
	content: url("../images/cameraIcon.svg");
	position: absolute;
	width: 76px;
	height: 76px;
	line-height: 90px;
	background: rgba(0, 0, 0, 0.45);
	border-radius: 50%;
	opacity: 0;
	display: inline-block;
	-web-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	left: 0px;
	/**/
}
label.custom-file-uploadEditComponent:hover:before {
	opacity: 1;
}
.custom-file-uploadEditComponent svg {
	width: 76px;
	height: 76px;
}
.dependentImageColEdit {
	width: 76px;
	height: 76px;
}
.avtarTextCol {
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 44px;
	text-align: center;
	color: #3d4551;
}
.avtarTextCol span {
	max-width: 365px;
	display: inline-block;
}
.broseRow {
	width: 100%;
	text-align: center;
}
.file-preview {
	margin: 0 10px;
}

.custom-file-uploadImage {
	width: 100%;
	height: 100%;
	position: relative;
	display: block;
	cursor: pointer;
}
label.custom-file-uploadImage:before {
	content: url("../images/cameraUploadImg.svg");
	position: absolute;
	width: 128px;
	height: 128px;
	line-height: 144px;
	background: rgba(0, 0, 0, 0.45);
	border-radius: 50%;
	margin: 9px;
	opacity: 0;
	display: inline-block;
	-web-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	left: -9px;
	top: -9px;
	text-align: center;
}
label.custom-file-uploadImage:hover:before {
	opacity: 1;
}
.imgCropBox {
	/*position: absolute;*/
	left: 40px;
	top: 15px;
	max-width: inherit;
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
	min-width: 300px;
}
.imageCropBoxMain {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	float: left;
	position: fixed;
	left: 0px;
	top: 0px;
	height: 100%;
	padding-left: 235px;
	z-index: 70;
}
.imgCropBox .ReactCrop__image {
	max-height: 355px;
}
.imgCropBox:before {
	content: "";
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.06);
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 3;
}
.imgCropBoxInner {
	padding: 47px 50px 30px 50px;
	background: #fff;
	z-index: 3;
	position: relative;
}
.dependentImgageCol {
	width: 144px;
	border: 9px solid #aecbda;
	height: 144px;
	/*overflow: hidden;*/
	-khtml-border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin-bottom: 24px;
	cursor: pointer;
	background:#aecbda;
}

label.custom-file-uploadImage:before{
	left: -10px;
	top:-10px;
}
.dependentImgageCol img {
	width:100%;
    height:100%;
    border-radius: 50%;
	border-radius: 50%;
}
.dependentAfterUploadImgCol {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/*****Edit profile screen start********/
.generalInfoProfileImg .dependentImgageCol {
	width: 84px;
	border: 4px solid #aecbda;
	height: 84px;
	overflow: hidden;
	-khtml-border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin-bottom: 24px;
	cursor: pointer;
	min-width:84px;
}
.generalInfoProfileImg.dependentPro .alignCenterVH{align-items: flex-start}
.generalInfoProfileImg.dependentPro label.custom-file-uploadImage:before{
	content: url("../images/cameraUploadImgSmall.svg");
	height: 84px;
	width: 84px;
	left: -13px;
	top: -10px;
	line-height: 90px;
}
.generalInfoProfileImg.dependentPro .avtarTextCol{
	display: none;
}
.generalInfoProfileImg.dependentPro .imageOptionList li label{
	cursor: pointer;
}

.generalInfoProfileImg.dependentPro .imageOptionList li{
	padding-left: 20px;
	padding-top: 12px;
	padding-bottom: 12px;
	font-size: 16px;
}
.generalInfoProfileImg.dependentPro .width300Center{
	width:165px;
	left: -75px;
	top:-13px;
}
.generalInfoProfileImg.dependentPro .imageOptionList ul:before{
	border-width: 10px;
	left: 42%;
	top:-20px
}
.generalInfoProfileImg.dependentPro .custom-file-upload{
	width: 84px;
	height: 84px;
}
.generalInfoProfileImg.dependentPro .custom-file-upload svg{
	width: 84px;
	height: 84px;
	float: left;
}
.generalInfoProfileImg.dependentPro label.custom-file-upload:before{
	width: 74px;
	height: 74px;
	left: 0px;
	margin:5px;
	content: url("../images/cameraUploadImgSmall.svg");
	line-height: 93px;
}
/*****Edit profile screen end********/
.generalInfoProfileImg .dependentImgageCol img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	overflow: hidden;
}
.generalInfoProfileImg .dependentAfterUploadImgCol {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}


.imgCropBox .ReactCrop--circular-crop .ReactCrop__crop-selection {
	box-shadow: 0px 0px 1px 1px #fff, 0 0 0 9999em rgb(255 255 255 / 86%);
}
.imageOptionList {
	background: #ffffff;
	box-shadow: 8px 22px 53px rgba(0, 0, 0, 0.04);
	width: 267px;
	border-radius: 4px;
}
.imageOptionList ul {
	position: relative;
}
.imageOptionList ul:before {
	content: "";
	width: 0px;
	height: 0px;
	border-color: transparent transparent #fff transparent;
	border-style: solid;
	border-width: 14px;
	position: absolute;
	top: -28px;
	left: 44%;
	box-shadow: 8px 22px 53px rgb(0 0 0 / 4%);
}
.imageOptionList li {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #3d4551;
	padding-left: 52px;
	padding-top: 17px;
	padding-bottom: 17px;
	cursor: pointer;
}
.imageOptionList li:nth-child(1) {
	border-bottom: 1px solid #f1f3f6;
}
.imageOptionList li.red {
	color: #f2938c;
	border-top: 1px solid #f7f9fa;
}
.dependentOnboardLaterText {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: rgba(0, 150, 199, 0.99);
	cursor: pointer;
	margin-top: 20px;
	z-index: 0;
}
.dependentStep3FormRow {
	padding-left: 360px;
	position: relative;
}
.DependentFileUpload {
	border: 1px dashed #c6cace;
	box-sizing: border-box;
	border-radius: 8px;
	display: flex;
	width: 100%;
	float: left;
	text-align: center;
	flex-direction: column;
	padding: 25px 0px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: #71767a;
}
.positionAbsoutePhoneLabel {
	top: 4px;
	position: absolute;
	left: 0px;
}
.DependentFileUpload span {
	display: inline-block;
	margin-bottom: 5px;
}

.dependentBorderBox .react-tel-input .flag-dropdown {
	display: none;
}
.dependentBorderBox .react-tel-input .form-control {
	border-width: 0 0 1px;
	/* border-bottom: 1px solid #3D4551; */
	width: 100%;
	margin-bottom: 2px;
	/* font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px; */
	color: #a9aeb1;
	padding-left: 10px;
	border-radius: 0px;
}
.dependentBorderBox .react-tel-input .form-controlFax {
	border-width: 0 0 1px;
	/* border-bottom: 1px solid #3D4551; */
	width: 100%;
	margin-bottom: 2px;
	/* font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px; */
	color: #2d2e2f;
	padding-left: 10px;
	border-radius: 0px;
}
.dependentBorderBox .react-tel-input.form-controlFax .form-control{
	border-width: 0 0 1px;
	/* border-bottom: 1px solid #3D4551; */
	width: 100%;
	margin-bottom: 2px;
	/* font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px; */
	color: #3D4551;
	padding-left: 0px;
	border-radius: 0px;
}
.borderNone {
	border:0px !Important;
}
.dependentBorderBox .faxInput input{
	padding-left: 3px !important;
}

.dependentBorderBox .react-tel-input.phoneNumber_Active .form-control {
	color: hsl(0, 0%, 20%);
	/*color: red;*/
}
.dependentBorderBox .phoneTypeCol .phoneType1__control {
	margin-top: -3px;
}

/******************Dependent profile view start**************************/
.titleNameAndTabHeader {
	width: calc(100% - 255px);
	position: fixed;
	background: #fff;
	top: 11px;
	padding-top: 30px;
	margin-left: -30px;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 16px 16px 0px 0px;
	z-index: 20;
}

.titleNameAndTabHeader:before {
	content: "";
	height: 18px;
	background: green;
	width: 100%;
	position: absolute;
	top: -11px;
	background: #e6f1fb;
	left: 0px;
}
.dependentTabRow {
	width: 100%;
	background: #fcfdfe;
	border: 1px solid #f1f3f6;
	box-sizing: border-box;
	border-radius: 8px 8px 0px 0px;
	height: 70px;
}
.titleNameAndTabHeader:after {
	background: #fff;
	content: "";
	position: absolute;
	width: 100%;
	height: 15px;
	left: 0px;
	top: 0px;
	border-radius: 10px 10px 0px 0px;
}
.dependentTab {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 24px;
	color: #8d9297;
	padding: 22px 0px;
	cursor: pointer;
	border-bottom: 3px solid transparent;
}
.activeDependentTab {
	/*background: #0096C7;*/
	border-bottom: 3px solid #0096c7;
	border-radius: 0px;
	color: rgba(0, 150, 199, 0.99);
}

.dependentTabContainer {
	margin-top: 130px;
	border: 1px solid #f1f3f6;
	border-top: 0px;
}
.dependentTabContainerCol {
	float: left;
	width: 100%;
	border-bottom: 1px solid #f1f3f6;
}
.paddingTopCol1 {
	padding-top: 100px;
}

#General_Information {
	min-height: 680px;
}

#Advocate_Team {
	min-height: 263px;
}
.bottom-spacer {
	min-height: 400px;
	float: left;
	width: 100%;
}
.dependentProfileMainViewBox {
	width: 100%;
	float: left;
	padding: 50px;
}
.generalInfoProfileImg img {
	/*width: 100%;*/
	max-width: 100%;
	height: 100%;
	border-radius: 50%;
}
.generalInfoProfileImg .ReactCrop img {
	border-radius: 0px;
}
.generalInfoProfileImg {
	width: 84px;
	height: 84px;
	border-radius: 50%;
	/*overflow: hidden;*/
	/*padding: 4px;*/
	background: linear-gradient(132.46deg, rgba(3, 82, 160, 0.24) 0%, rgba(1, 132, 189, 0.24) 51.04%, rgba(0, 180, 216, 0.24) 100%);
	float: left;
	margin-right: 32px;
	z-index:1;
	position: relative;
}
.dependentTabContainerCol .generalInfoProfileImg{
	overflow: hidden;
	padding: 4px;
}
.generalInfoProfileDefaultImg {
	width: 84px;
	min-width: 84px;
	height: 84px;
	border-radius: 50%;
	overflow: hidden;
	background: linear-gradient(132.46deg, rgba(3, 82, 160, 0.24) 0%, rgba(1, 132, 189, 0.24) 51.04%, rgba(0, 180, 216, 0.24) 100%);
	float: left;
	margin-right: 32px;
}
.generalInfoProfileDefaultImg svg {
	width: 100%;
	height: 100%;
}
.generalInfoUserName {
	width: auto;
	float: left;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: #3d4551;
}
.generalInfoTitle {
	display: flex;
	align-items: center;
	padding-right: 100px;
}
.generalInfo_buttonEditCol {
	background: rgba(0, 150, 199, 0.99);
	border-radius: 4px;
	padding-left: 12px;
	padding-right: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	position: absolute;
	right: 0px;
	cursor: pointer;
}
.generalInfo_buttonEditCol:hover {
	background: rgba(0, 150, 199, 0.67);
}
.generalInfo_buttonEditCol span {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #f5f6f7;
	margin-left: 6px;
}
.generalInfoSubTitle {
	color: #0466c8;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.8px;
}
.generaInfoRow {
	position: relative;
	padding-left: 320px;
}
.cancelButton1:hover {
	color:#71767A;
	border-color:#71767A;
}
.geneInfo_label {
	position: absolute;
	left: 0px;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.8px;
	text-transform: uppercase;
	color: #71767a;
}
.geneInfo_value {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.8px;
	color: #3d4551;
}
.geneInfo_value:empty:after{
	content: "-";
	/* color: transparent; */
}
.geneInfo_value span {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	color: rgba(0, 150, 199, 0.99);
	cursor: pointer;
}
.dependentAddress {
	padding: 40px 50px 25px 50px;
}
.dependentAddressTitle {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: #0466c8;
	display: flex;
	align-items: center;
	padding-right: 145px;
}
.dependentAddressRow {
	background: rgba(0, 80, 157, 0.02);
	border-radius: 8px;
	padding: 20px;
	padding-right: 110px;
	position: relative;
}
.dependentCurrentAddressRow {
	margin-bottom: 8px;
}
/* .borderBottom:before {
	content: "";
	bottom: 0px;
	right: 0px;
	background: #edeff0;
	position: absolute;
	height: 1px;
	width: 96%;
} */
.dependentColAddress {
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.4px;
	max-width: 750px;
	color: #3d4551;
}
.dependentColradioButton {
	left: 0px;
	text-align: left;
}
.dependentColradioButton label {
	display: flex;
	flex-direction: row;
}
.dependentColActionBUtton {
	width: 110px;
	position: absolute;
	right: 0px;
	top: 17px;
}
.dependentColActionBUtton span {
	cursor: pointer;
	margin-right: 20px;
}
.dependentColradioButton [type="radio"]:checked {
	background: #00a91c;
	border: 4px solid #afe3ba;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	min-width: 28px;
	margin-top: 2px;
}
.dependentColradioButton [type="radio"] {
	background: url("../images/addressRadioBackground.png");
	background: url("../images/clockIcon1.png") 4px 2px no-repeat;
	-webkit-appearance: none;
	appearance: none;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	margin-right: 15px;
	min-width: 28px;
	margin-top: 2px;
	background-size:22px;
}
.dependentColradioButton [type="radio"] {
	border:0px;
}
.dependentAdvocateTeamRow {
	background: rgba(0, 80, 157, 0.02);
	border-radius: 8px;
	padding: 20px;
	padding-right: 110px;
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
}

.dependentAdvocateTeamRow:last-child:before {
	display: none;
}

.dependentAddressRow:last-child:before {
	display: none;
}

.dependentAdvocateTeamIcon {
	width: auto;
	float: left;
	margin-right: 12px;
}
.dependentAdvocateTeamUserName {
	float: left;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.4px;
	color: #2d2e2f;
	width: 200px;
	margin-right: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 15px;
}
.dependentAdvocateTeamUserEmailId {
	float: left;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.4px;
	color: rgba(0, 150, 199, 0.99);
	width: 310px;
	margin-right: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-grow: 0.7;
}
.dependentAdvocateTeamUserEmailId a{
	color: rgba(0, 150, 199, 0.99);
}
.dependentAdvocateTeamUserStatus {
	width: auto;
	float: left;
}
.dependentPendingStatus {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #f5af13;
	background: rgba(255, 190, 46, 0.11);
	border-radius: 3px;
	width: 126px;
	height: 44px;
	line-height: 44px;
	text-align: center;
}
.dependentActiveStatus {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #00a91c;
	background: rgba(0, 169, 28, 0.08);
	border-radius: 3px;
	width: 126px;
	height: 44px;
	line-height: 44px;
	text-align: center;
}
.dependentAdvocateTeamUserActionButton {
	width: auto;
	float: left;
}
.dependentAdvocateActionBUtton {
	position: absolute;
	right: 30px;
}
.dependentAdvocateActionBUtton span {
	margin-left: 20px;
	cursor: pointer;
}
.dependentNoAddressData {
	padding: 5px 0px 0 0px;
}
.noAddressText {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: #3d4551;
}
.noAddressDescription {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #565c65;
}

.dependentResidencCol .phoneType1__control {
	border: 0px;
	border-bottom: 1px solid #3D4551;
	border-radius: 0px;
	margin-top: -10px;
}
.dependentResidencCol .errorResidence.dependentResidence__control {
	border-bottom: 1px solid rgba(242, 147, 140, 0.8);
}
.dependentResidencCol .dependentResidence__menu {
	background: #ffffff;
	box-shadow: 0px 10px 30px 7px rgba(0, 0, 0, 0.04);
	border-radius: 8px;
}
.dependentResidence__control--is-focused {
	border: 4px solid rgba(0, 150, 199, 0.15);
}
.dependentResidence__control--is-focused:hover {
	border-bottom: 1px solid rgba(0, 150, 199, 0.15) !important;
}
.dependentResidence__input {
	caret-color: transparent;
}

.dependentResidencCol .dependentResidence__control {
	border: 0px;
	border-bottom: 1px solid #c4c4c4 !important;
	border-radius: 0px;
	margin-top: -12px;
}
.dependentResidencCol .dependentResidence__value-container {
	padding-left: 0px;
}
.dependentResidencCol .dependentResidence__control--is-focused.dependentResidence__control--menu-is-open,
.dependentResidencCol .dependentResidence__control--is-focused.dependentResidence__control--menu-is-open:focus{
	border-bottom:1px solid #0096c7 !important;
}
.dependentResidencCol .dependentResidencdependentResidence__e__option,
.dependentResidencCol .dependentResidence__single-value {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.4px;
	color: #71767a;
}
.dependentResidencCol .dependentResidence__option {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #71767a;
	padding-top: 12px;
	padding-bottom: 12px;
}
.dependentResidencCol .dependentResidence__option--is-selected {
	color: #0096c7 !important;
	background-color: #0096c714 !important;
}
.dependentResidencCol .dependentResidence__option--is-focused {
	color: #0096c7;
	background: #0096c708;
}
.dependentResidencCol .dependentResidence__single-value {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.4px;
	color: #3d4551;
}
.dependentResidencCol .dependentResidence__placeholder {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #c6cace;
}
.popUpMessageWidth {
	width: 278px;
	margin: 10px auto 0px auto;
}
.incomeTypeCol .incomeType__input-container, .inputColum24size div[class*="ValueContainer"] div[class*="-Input"]{
	padding-bottom:0px;
	margin-bottom: 0px;
}

.inputColum24size div[class*="ValueContainer"] div[class*="-Input"] input[type="text"] {
	height: 29px !important;
}

.incomeTypeCol .incomeType__control, .inputColum24size > span + span + div[class*="-control"]{
	min-height: 30px !important;
}
.incomeTypeCol .incomeType__indicator {
	padding:3px;
}
.incomeTypeCol .incomeType__value-container, .inputColum24size div[class*="ValueContainer"] {
	padding:0px !important;
}

.inputColum24size div[class*="-indicatorContainer"],
.expenseTypeCol .expenseType__indicator {
	padding:3px;
}
.expenseTypeCol .expenseType__value-container {
	padding:0px !important;
}
.expenseTypeCol .expenseType__input-container{
	padding-bottom:0px;
	margin-bottom: 0px;
}
.expenseTypeCol .expenseType__control{
	min-height: inherit;
}
.columBox{
	flex-direction: column;
}
/****************Dependent profile view end*********************/

/******************************infoBoxInviteUser start*********************************/
.infoBoxInviteUser {
	width: 100%;
	float: left;
	background: rgba(0, 80, 157, 0.02);
	border: 1px solid rgba(0, 80, 157, 0.08);
	box-sizing: border-box;
	border-radius: 6px;
	padding: 8px 12px;
	display: flex;
	align-items: flex-start;
}
span.infoIconInviteUser {
	margin-top: -6px;
}
.infoTextInviteUser {
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 24px;
	letter-spacing: 0.25px;
	color: #565c65;
	display: inline-block;
	max-width: 972px;
}

.popUpInviteUserMessage {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #565c65;
	max-width: 338px;
	margin: 0px auto;
}
.positionRelative span.addressTooltip {
	display: none;
}
.positionRelative:hover span.addressTooltip {
	border-radius: 8px;
	background: #2d2e2f;
	position: absolute;
	height: 50px;
	padding: 0px 20px;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	display: flex;
	align-items: center;
	color: #ffffff;
	left: 45px;
	top: -7px;
}
span.addressTooltip:before {
	content: "";
	width: 0px;
	height: 0px;
	border-color: transparent #2d2e2f transparent transparent;
	border-width: 11px 14px 11px 14px;
	position: absolute;
	z-index: 10;
	border-style: solid;
	left: -28px;
	top: 13px;
}

.folderBottomIcon svg{width:33px; height:26px;}
/******************************infoBoxInviteUser end*********************************/

.ViewIcon{
	display: none;
}
.desktopCol{
	display: inline-block;
}
.mobileCol{
	display:none;
}
.formRowMarginBottom10 {
	margin-bottom: 10px;
}

.accountWidth{
	min-width: 118px;
	height: 32px;
}
.lineHeight{
	line-height: 40px;
}
.zIndex1{
	z-index: 1;
}
.loaderSpinner input:focus, .loaderSpinner textarea:focus, .loaderSpinner select:focus {
	border-color: transparent;
}
body .lableTop0 {
	top:0px
}

body .medicalNoData .bulletUl{
	margin-left:70px;
}
/************************************************ Dependent profile main end 1440*************************************************************************************************/

/************************************************ Dependent profile  end*************************************************************************************************/



/************************************************ Inventory start 1440*************************************************************************************************/
.inventoryScreenTitle {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color:#122E52;
}

.inventoryBox .inventoryScreenTitle{
	cursor: pointer;
}

.inventoryRow {
	position: relative;
	padding-left:240px;
}
.inventory_label {
	color: #A9AEB1;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.8px;
	position: absolute;
	left: 0px;
	top: 4px;
	text-transform: uppercase;
}
.inventory_value {
	color: #3D4551;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.8px;
	min-height: 24px;
	display: inline-block;
	word-break: break-all;
}
div.accessRow{
	padding-left: 470px;
}

.borderBottom {
	border-bottom: 1px solid #EBF2F9;
}
.paddingNone{
	padding:0px;
}


.inventory_Col_padding {
	padding:25px 35px 20px;
}

.autoMobileForm label {
	min-width: 170px;
}

label.lblExpType7{
	top:7px
}

/******************************asset start 1440*************************************************/
.popMaxWidthAsset{
	max-width: 985px;
}
.popMinHeigt {
	min-height:calc(100vh - 160px)
}
.assetPopUpTitle {
	font-size: 32px;
	line-height: 44px;
	padding: 0px 0px 8px 0px;
	color:#0466C8;
	font-weight: 600;
}
.countinueButton{
	background: #0466c8;
	background: linear-gradient(
			0deg
			, rgba(4, 102, 200, 0.2), rgba(4, 102, 200, 0.2)), #0466c8;
	box-shadow: 0px 1px 3px rgb(0 94 162 / 24%);
	border-radius: 4px;
	height: 44px;
	padding: 0px 16px;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 44px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #ffffff;
	display: inline-block;
	min-width: 130px;
	cursor: pointer;
}
.poupAssetMain {
	display: flex;
	justify-content: space-between;
	flex-direction: column;

}

.assetPopUpDescription {
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	text-align: center;
	color:#3D4551;
	margin-bottom: 30px;
}

.paymentTemrs.radioAsset label{
	color:#3D4551;
	font-size: 24px;
	line-height: 24px;
	font-weight: normal;
}

.addAssetForm .payRow .width50, .addAssetForm .formRow {
	padding-left: 200px;
	padding-right: 25px;
}
.addAssetForm .payRow label{
	min-width: 180px;
}

.loaderDisplayFlex{
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255,255,255,0.9);
}
/*.radioAsset input{*/
/*	*/
/*}*/

/*input[type="radio"] {*/
/*	!* ...existing styles *!*/
/*	appearance: none;*/
/*	background-color: #fff;*/
/*	margin: 0;*/
/*	font: inherit;*/
/*	color: currentColor;*/
/*	width: 1.15em;*/
/*	height: 1.15em;*/
/*	border: 0.15em solid currentColor;*/
/*	border-radius: 50%;*/
/*	transform: translateY(-0.075em);*/
/*}*/

.input[type="radio"]::before {
	content: "";
	width: 0.65em;
	height: 0.65em;
	border-radius: 50%;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	background: red;

}

/*input[type="radio"]:checked::before {*/
/*	transform: scale(1);*/
/*}*/

input[type="radio"] {
	appearance: none;
	background-color: #fff;
	margin: 0;
	font: inherit;
	color: currentColor;
	width: 22px;
	height: 22px;
	border: 1px solid #71767A;
	border-radius: 50%;
	transform: translateY(0.225em);
}
input[type="radio"]:checked {
	border: 5px solid rgba(0, 150, 199, 0.99);
}
input[type="radio"]:checked::before {
	/*transform: scale(1);*/
	border: 5px solid rgba(0, 150, 199, 0.99);
}
.radioAsset label.static input, .radioAsset label.static{float: left}
.addBeneficiary .phoneTypeCol .phoneType1__control {
	border-bottom: 1px solid #c4c4c4 !important;
}
.addBeneficiary .hasValue.phoneTypeCol .phoneType1__control {
	border-bottom: 1px solid hsl(0, 0%, 20%) !important;
}
.addBeneficiary .phoneType1__option.phoneType1__option--is-selected {
	background: #0096C708;
}
.contorlAccessViewScreen {
	left: inherit;
	right: 0px;
	width:290px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.contorlAccessViewScreen label{
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-transform: uppercase;
	color: #112E53;
}

.invoiceText {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color:  #0096C7;
}
.invoiceText span:hover{
	color:#058DBA !important;
}
.downloadImge{
	width: 16px;
	margin-left: 10px;
}

.uploadFileName{
	font-size: 18px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	width: calc(100% - 40px);
	margin-top: 5px;
	margin-bottom: 2px;
    height: 20px;
}

.fInfo, .fInfo *{
	line-height: 1 !important;
}

.padding3040{
	padding:30px 40px;
}
.phoneMarginRight{
	margin-right: 80px;
}

.linkLightBlueColor{
color:rgba(0, 150, 199, 0.99) !important;
}

.ViewDoctorMain .docotorDetails label{
	width: 100%;
	display: block;
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #A9AEB1;
}


.ViewDoctorMain .docotorDetails > div > div{
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.0025em;
	color: #3D4551;
}
.ViewDoctorMain .docotorDetails > div{
	padding: 7px 0px;
}
.ViewDoctorMain .docotorDetails label {
	width: 222px;
	font-weight: 600;
	font-size: 16px;
	line-height: 36px;
	color: #a9aeb1;
	flex-shrink: 0;
}

.ViewDoctorMain .docotorDetails > div > div {
	font-size: 24px;
	line-height: 36px;
	color: #2d2e2f;
}


body .formRow .prescribeDoctorLable{
	top:-5px
}

.medicationStatus {
	left: inherit;
	right: 0px;
	width:225px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.medicationStatus label{
	Font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color:#A9AEB1
}
.medicationStatus .yesNo{
	width: 80px;
	font-weight: 400;
}
.paddingLeft{
	padding-left: 220px;
}

.medicationImg .generalInfoProfileImg.dependentPro .width300Center {
	width: 267px;
}
.medicationImg .generalInfoProfileImg.dependentPro .imageOptionList li{
	padding-top:22px;
	padding-bottom: 22px;
	padding-left: 30px;
}
.medicationImg .generalInfoProfileImg.dependentPro .imageOptionList li {
	color:#3D4551;
	font-size: 20px;
	line-height: 24px;
}
.medicationImg .generalInfoProfileImg.dependentPro .imageOptionList li:hover {
	background:rgba(0, 150, 199, 0.08);
	color:#0096C7;
}

.generalInfoProfileImg.dependentPro .imageOptionList li:hover {
	background:rgba(0, 150, 199, 0.08);
	color:#0096C7;
}
.sepcialtyCol {
	width: 320px;
}

.medicationImg .generalInfoProfileImg.dependentPro .width300Center{
	left: -40px;
}

.medicationImg .generalInfoProfileImg .dependentImgageCol {
	width: 135px;
	border: 8px solid #aecbda;
	height: 131px;
	overflow: hidden;
	-khtml-border-radius: 7px;
	-moz-border-radius: 7px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
	margin-bottom:17px;
	cursor: pointer;

}
.medicationImg .generalInfoProfileImg.dependentPro .imageOptionList ul:before {
	left: 12%;
}
.medicationImg .generalInfoProfileImg {
	margin-right: 0px;
	width: 135px;
	height: 131px;
	-khtml-border-radius: 7px;
	-moz-border-radius: 7px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
	background: #F6FBFF;
}
.medicationImg .generalInfoProfileImg .dependentImgageCol img {
	-khtml-border-radius: 7px;
	-moz-border-radius: 7px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
}
.medicationImg .generalInfoProfileImg.dependentPro label.custom-file-uploadImage:before {
	content: url("../images/medicalImgUpload.svg");
	height: 100%;
	width: 100%;
	left: 0px;
	top: 0px;
	margin: 0px;
	border-radius:7px;
	line-height: 138px;
}
.medicationImg .broseRow{
	border: 8px solid #aecbda;
	height: 131px;
	-khtml-border-radius: 7px;
	-moz-border-radius: 7px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
	background:#91B7E2;
}
.medicationImg .generalInfoProfileImg.dependentPro .custom-file-upload {
	width: auto;
	height: 115px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.medicationImg .generalInfoProfileImg.dependentPro label.custom-file-upload:before {
	width: 100%;
	height: 100%;
	left: 0px;
	margin: 0px;
	content: url("../images/medicalImgUpload.svg");
	line-height: 133px;
	border-radius: 7px;
}

.medicationImg .generalInfoProfileImg.dependentPro .custom-file-upload svg{
	width:119px;
	height: 114px;
}
body .dragFileMedical {
	display: block;
	color: #71767A;
	font-size: 14px;
	width: 100%;
	text-align: left;
	margin-top: 7px;
}

.doctorPrescribingCol .doctorPrescribing__menu-list { position: relative;  padding-bottom:0px;}
.doctorPrescribingCol .doctorPrescribing__option:last-child {
	position: sticky;
	left: 0px;
	bottom:0px;
	padding-top:20px;
	padding-bottom:20px;
	padding-left: 20px;
	color:rgba(0, 150, 199, 0.99);
	background: #FFF !important;
	border-top:1px solid #DFDFDF;
	cursor: pointer;
	z-index: 999;
}

.doctorPrescribingCol .doctorPrescribing__option:last-child:before {
	content: '+';
	margin-right: 15px;
	font-size: 30px;
}
.upperCase{
	text-transform: uppercase;
}
.ViewMedicationMain .MedicationDetails label{
	width: 100%;
	display: block;
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #A9AEB1;
}
.ViewMedicationMain .MedicationDetails > div > div{
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.0025em;
	color: #3D4551;
}
.ViewMedicationMain .MedicationDetails > div{
	padding: 10px 0px;
}
.ViewMedicationMain .MedicationDetails label {
	width: 222px;
	font-weight: 600;
	font-size: 16px;
	line-height: 36px;
	color: #a9aeb1;
	flex-shrink: 0;
}

.ViewMedicationMain .MedicationDetails .paddingLeft220 {
	padding-left: 220px;
}

.ViewMedicationMain .MedicationDetails > div > div {
	font-size: 24px;
	line-height: 36px;
	color: #2d2e2f;
}
.imgBoxMedicalView {
	width: 151px;
	height: 147px;
	overflow: hidden;
	border-radius: 7px;
	border:8px solid #E2EDF8;
}
.imgBoxMedicalView img{
	width: 100%;
	height: auto;
}
.medicationinstruction {
	color:#3D4551;
	font-size: 24px;
	line-height: 24px;
	font-weight: 300;
}
.medicalTableTitle .tableBody th, .tableBody td {
	padding: 18px 12px;
}



.doctorPrescribingCol .phoneType1__control {
	border: 0px;
	border-bottom: 1px solid #3D4551;
	border-radius: 0px;
	margin-top: -10px;
}
.doctorPrescribingCol .errorResidence.doctorPrescribing__control {
	border-bottom: 1px solid rgba(242, 147, 140, 0.8);
}
.doctorPrescribingCol .doctorPrescribing__menu {
	background: #ffffff;
	box-shadow: 0px 10px 30px 7px rgba(0, 0, 0, 0.04);
	border-radius: 8px;
}

.doctorPrescribing__input {
	caret-color: transparent;
}

.doctorPrescribingCol .doctorPrescribing__control {
	border: 0px;
	border-bottom: 1px solid #c4c4c4 !important;
	border-radius: 0px;
	margin-top: -12px;
}
.doctorPrescribingCol .doctorPrescribing__value-container {
	padding-left: 0px;
}
.doctorPrescribingCol .doctorPrescribing__option,
.doctorPrescribingCol .doctorPrescribing__single-value {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #71767a;
}
.doctorPrescribingCol .doctorPrescribing__option {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #71767a;
	padding-top: 12px;
	padding-bottom: 12px;
}
.doctorPrescribingCol .doctorPrescribing__option--is-selected {
	background: transparent;
}
.doctorPrescribingCol .doctorPrescribing__option--is-focused {
	color: #0096c7;
	background: #0096c708;
}
.doctorPrescribingCol .doctorPrescribing__single-value {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #3d4551;
}
.doctorPrescribingCol .doctorPrescribing__placeholder {
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #c6cace;
}
.doctorPrescribingCol .doctorPrescribing__indicator {
	padding:2px;
}
.doctorPrescribingCol .doctorPrescribing__control{
	align-items: flex-end;
}
.doctorPrescribingCol .doctorPrescribing__option {
	color:#71767A;
	padding-left:42px;
	font-weight: 400	;
}

.statusCol {
	width: 110px;
}

body .btn-outline.printGrayColor{
	color:#C6CACE;
	border-color:#C6CACE;
}

.imageCropBoxMain .imgCropBoxInner{
	 text-align: center;
}

.paddingLeft220{
	padding-left: 220px;
}
/******************************asset end 1440*************************************************/

/************************************************ Inventory end 1440*************************************************************************************************/


/************************************************ Mediaal start 1440*************************************************************************************************/
.medical .MuiTabs-flexContainer button{
	width: 50%;
}
.justifySpaceBetween{
	justify-content: space-between;
}
.doctorListTabelButton{min-width: 150px;}
/************************************************ Medical end 1440*************************************************************************************************/


.inventoryAmountCol{
	width: 22%;
}



.documentListRow {
	display: table !important;
}
.documentListRow #dropZoneWrapDocument{
	z-index: 0;
}

.documentListRow.hovered #dropZoneWrapDocument{
	z-index: 20;
}

/************************************************Responsive css start*********************************************************************************************/

/************************************************Responsive css start for 1365 screen*********************************************************************************************/
@media screen and (max-width: 1365px) {
	/**css for 1280 screen **/
	.allRightReserveText {
		display: block;
	}
	.allRightReserveCol {
		display: block;
	}
	.signColCenter {
		padding-left: 32px;
		padding-right: 32px;
	}

	/************************ my account 1365px start *******************/

	/******** my account  - personal info 1365px start *******************/

	.personalInfoNameRow,
	.formRow {
		padding-left: 160px;
		padding-right: 20px;
	}

	.dependentStepFormRow{
		padding-left: 160px;
	}

	/************************ my account 1365px start *******************/

	/******** my account  - personal info 1365px start *******************/
	/*-------------------- Expense --------------------*/
	.expenseType__value-container {
		padding-left: 0px;
		padding-right: 0px;
	}
	.formRow label {
		padding-left: 15px;
	}
	.payRow .width50 {
		padding-left: 160px;
	}
	/*-------------------- /Expense --------------------*/
}
/************************************************Responsive css end for 1280 screen*********************************************************************************************/

/************************************************Responsive css start for 1280 screen*********************************************************************************************/
@media screen and (max-width: 1279px) {
	/**css for 1280 screen **/
	.allRightReserveText {
		display: block;
	}
	.allRightReserveCol {
		display: block;
	}
	.signColCenter {
		padding-left: 32px;
		padding-right: 32px;
	}

	/************************ my account 1365px start *******************/

	/******** my account  - personal info 1365px start *******************/

	.myAccountPersonalInfo .width50.selectBoxParend {
		width: 100%;
		padding-right: 0px;
	}
	.myAccountPersonalInfo .width48.selectBoxParend {
		width: 100%;
		padding-right: 0px;
	}

	/************************ my account 1280 start *******************/

	/***********/

	/******** my account  - personal info 1280 start *******************/
}
/************************************************Responsive css end for 1280 screen*********************************************************************************************/

@media only screen and (min-width: 950px) and (max-width: 1150px) {
	.imgLeftSignCol {
		padding-left: 30px;
	}
	.imgLeftSignCol {
		width: 44%;
	}
	.signInFormBox .button {
		width: 100%;
	}
	.imgRightSignCol {
		float: right;
		margin-right: 2%;
	}
}
@media screen and (max-width: 950px) {
	.imgLeftSignCol {
		display: none;
	}
	.imgRightSignCol {
		width: 100%;
	}
	.top.topRelativeCol:before {
		width: 100%;
	}
	.top.topRelativeCol:after {
		width: 100%;
	}
	.signInFormBox {
		height: auto;
		margin-top: 40px;
	}
	.allRightReserveText {
		position: static;
		margin-top: 20px;
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 768px) {
	/*****************************************************************default css start 768px******************************************************************/
	.margignTop768_13 {
		margin-top: 13px;
	}
	.margignBottom768_10 {
		margin-bottom: 10px;
	}
	.margignBottom768_20 {
		margin-bottom: 20px;
	}
	.margignBottom768_48 {
		margin-bottom: 48px;
	}
	.paddingTop768_15 {
		padding-top: 15px;
	}
	.width50Percent768 {
		width: 50%;
	}
	.button {
		height: 66px;
	}
	.buttonDisable {
		height: 66px;
	}

	.sideBarVisible.sideBarLeftCol .top,
	.sideBarLeftCol .top {
		width: 100%;
	}
	.sideBarLeftCol {
		background: #FAFCFD;
		min-height: inherit;
	}
	.generaInfoRow {
		padding-left: 120px;
	}

	.generalInfoSubTitle{
		font-size: 18px;
		line-height: 24px;
		margin-top: 15px;
	}
	.generalInfoProfileDefaultImg{
		width: 56px;
		height: 56px;
		min-width: 56px;
		margin-right:16px;
	}
	.generalInfoUserName{
		font-size: 16px;
		line-height: 24px;
	}

	.dependentTabRow{
		height: 44px;
	}

	.dependentTab{
		padding:9px 0px;
	}
	.dependentTabContainer{
		background:#fff;
		margin-top: 60px;
	}
	.geneInfo_label {
		font-size: 12px;
		line-height: 24px;
	}
	.geneInfo_value {
		font-size: 16px;
		line-height: 24px;
	}
	.mainRightSidebare {
		width: 100%;
		float: right;
		margin-top: 80px;
	}
	.sideBarVisible .mainRightSidebare {
		width: 100%;
		z-index: 3;
	}
	.rightPanelWidhtSidebarContainer {
		min-height: inherit;
	}
	.sideBarLeftCol {
		padding-right: 0px;
	}

	/*****************************************************************default css end 768px******************************************************************/

	.navOverlay {
		width: 0%;
		height: 100%;
		background: rgba(0, 0, 0, 0.3);
		position: fixed;
		z-index: 50;
		top:80px;
	}
	.navOverlay.overlayShow {
		width: 100%;
		top:80px
	}

	.sidebarLogo{
			margin: 0px auto;
	}

	.marginTop768_10 {
		margin-top: 10px;
	}
	.marginTop768_20 {
		margin-top: 20px;
	}
	/***nav ui start****/
	.sideBarNav {
		height: auto;
		z-index: 80;
	}
	.sideBarNav ul {
		float: left;
		width: 0px;
		min-width: 0px;
		z-index: 60;
		background: #e6f1fb;
		position: absolute;
		top: 88px;
		overflow: hidden;
		padding: 0px;
		height: 100vh;
	}

	.sideBarNav.sideBarIconOnly span.sideBarNavIcon {
		margin: 0px 15px;
		width: 23px;
	}
	.sideBarNav.sideBarIconOnly {
		max-width: inherit;
	}
	.sideBarNav.sideBarIconOnly .sidebarLogo {
		width:144px;
	}
	.sidebarLogo img {
		max-width: 144px;
	}

	.sideBarNav.sideBarIconOnly .sideBarNavText {
		display: inline-block;
		width: 150px;
	}
	/*.sideBarIconOnly .sideBarNavText span {*/
	/*	opacity: 1;*/
	/*}*/
	.sidebarLogoNav.UlUnderLogo {
		display: block;
	}
	.sideBarNav ul {
		top: 80px;
	}

	.UlUnderLogo span.navIcon {
		margin-left: 15px;
		margin-top: 4px;
		float: left;
	}

	.navIcon{
		position: absolute;
		margin-left: 5px;
	}

	.sideBarNav .sideBarNavText{
		font-size: 14px;
	}

	.sideBarNav.sideBarIconOnly ul {
		min-width: 235px;
		padding: 15px 10px 0px 10px;
		height: 100vh;
	}

	.sideBarNav ul {
		padding-top: 15px
	}
	.sideBarNav.sideBarIconOnly ul li {
		min-width: 216px;
	}
	.sideBarNav ul li {
		min-width: 216px;
	}
	.sideBarNav {
		background: #fff;
		width: 100%;
		max-width: 100%;
		position: static;
	}
	.sidebarLogoNav {
		width: 100%;
		margin: 0px;
		padding-top: 27px;
		padding-bottom: 23px;
		padding-right: 15px;
		padding-left: 15px;
		background:#fff;

	}

	.sideBarNav ul .sidebarLogoNav{
		padding-bottom: 15px;
	}

	/*.sidebarLogoNav{*/
	/*	justify-content: flex-end;*/
	/*}*/


	/*.sidebarLogoNav .sidebarLogo{*/
	/*	display:none;*/
	/*}*/

	ul .sidebarLogoNav .sidebarLogo{
		display:inline-block;
	}
	ul .sidebarLogoNav .navIcon {
		display: none;
	}

	.sideBarIconOnly .sidebarLogoNav {
		position: relative;
		z-index: 60;
		display: flex;
	}
	.sidebarLogoNav.UlUnderLogo {
		display: flex;
	}
	.closeIcon{
		margin-right: 10px;
		margin-left: 5px;
		height: 30px;
	}
	/*.sidebarLogoNav:before{*/

	/*content: '';*/
	/*background: #E6F1FB;*/
	/*width:0%;*/
	/*height: 100%;*/
	/*position: absolute;*/
	/*z-index: -1;*/
	/*-web-transition:all .5s ease-in-out;*/
	/*-moz-transition:all .5s ease-in-out;*/
	/*-o-transition:all .5s ease-in-out;*/
	/*transition:all .5s ease-in-out;*/
	/*}*/
	.sideBarIconOnly .sidebarLogoNav:before {
		width: 100%;
	}
	/***nav ui end****/

	.rightPanelWidhtSidebar {
		width: 100%;
		height: auto;
	}
	.rightPanelWidhtSidebarContainer {
		background: transparent;
		padding-top: 0px;
	}

	/************************ Sign up 768px start *******************/
	.signUpFirstName {
		width: 100%;
		padding-right: 0px;
		margin-bottom: 44px;
	}
	.signUpLastName {
		width: 100%;
		padding-left: 0px;
		float: left;
	}
	.signColCenter {
		max-width: 614px;
	}
	.titleWelcomeToAccount {
		font-size: 32px;
	}
	.signInFormBox .button,
	.signInFormBox .buttonDisable,
	.signInFormBox .loaderSpinner {
		max-width: 100%;
	}
	.signColCenter .firstLastNameSpaceSignUp {
		margin-top: 18px;
	}
	.allRightReserveText {
		position: absolute;
	}
	.signInFormBox {
		height: calc(100vh - 88px);
	}
	.signInFormBox {
		margin-top: 0px;
	}

	/************************ Sign up 768px start *******************/

	/******** my account  - personal info 1440px start *******************/
	.firstLastNameSpace {
		margin: 22px 0px 13px 0px;
	}
	.myAccountPersonalInfo .positionTop {
		top: 10px;
	}

	.myAccountPersonalInfo .positionTop768_25 {
		top: 25px;
	}
	.myProfile_personal_city {
		margin-bottom: 38px;
	}
	.myProfile_personal_state {
		width: 47%;
		margin-left: 0px;
		margin-right: 0px;
		float: right;
		margin-bottom: 40px;
	}
	/******** my account  - personal info 7680px end *******************/
	/******** my account  - passsword change 7680px start *******************/
	.myAccountPassordScreen {
		padding-top: 15px;
		/* margin-top: 15px; */
	}
	.myAccountPersonalInfo.myAccountPassordScreen label {
		top: 25px;
	}
	/******** my account  - passsword change 7680px end *******************/

	.billingImage {
		max-width: 248px;
	}
	/************************ my account 7680px end *******************/

	/*** email verification co 768px start*******/
	.emailVerificationCenterBox {
		max-width: 614px;
	}
	.emailVerificationCenterBox {
		padding: 50px 59px;
	}
	.emailVerficationMaxWidth {
		max-width: 500px;
	}
	.emailVerficationMaxWidth .loaderSpinner,
	.emailVerficationMaxWidth .buttonDisable,
	.emailVerficationMaxWidth .button {
		max-width: 500px;
	}
	body .forgotPassdependentInput768 {
		margin-top: 65px;
	}
	::placeholder {
		font-size: 24px;
		line-height: 24px;
	}
	/*** email verification co 768px end*******/
}

@media screen and (max-width: 600px) {
	/*****************************************************************default css start max-width 600px******************************************************************/

	.allRightReserveText {
		position: static;
	}
	.signInFormBox {
		height: auto;
		margin-top: 40px;
	}

	.minScreenHeight {
		min-height: 100vh;
		height: auto;
	}
	.marginNone600 {
		margin: 0px;
	}
	.width50 {
		width: 100%;
	}
	.title {
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		color: #1c1d1f;
	}
	.title1 {
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		color: #1c1d1f;
	}
	.responsive6000LeftRight {
		padding-left: 0px;
		padding-right: 0px;
	}
	.buttonDisable {
		height: 40px;
		border-radius: 4px;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
	}
	.button {
		height: 40px;
		border-radius: 4px;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
	}
	.signColCenter {
		background: #ffffff;
		box-shadow: 6px 27px 48px 4px rgba(0, 0, 0, 0.01);
		border-radius: 8px;
	}
	.checkboxCustom input[type="checkbox"],
	.checkboxCustom input[type="checkbox"]:checked + label {
		height: 22px;
		width: 22px;
	}
	.checkboxCustom input[type="checkbox"]:checked + label span {
		height: 13px;
		width: 7px;
		left: 8px;
		top: 3px;
	}
	.inputColum {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.4px;
	}
	.passwordMatchBox ul li {
		width: 100%;
		align-items: flex-start;
	}
	.passwordMatchBox ul li .span.rightIocn {
		height: 14px;
	}
	li.passowrdListOfCondition:nth-child(2),
	li.passowrdListOfCondition:nth-child(4) {
		width: 100%;
		float: left;
	}
	.termAndPrivacyPolicyText br {
		display: none;
	}
	.responsive600LineHeight37 {
		line-height: 37px !important;
	}
	::placeholder {
		font-size: 20px;
		line-height: 24px;
	}
	.rightPanelWidhtSidebarContainer {
		padding-left: 15px;
		padding-right: 15px;
		margin-top: 0px;
	}
	.titleWithSidebar {
		font-size: 16px;
		line-height: 24px;
	}

	/**Tab start ****/
	.tabRow ul li {
		font-size: 14px;
		line-height: 24px;
	}
	.tabRow ul li.active {
		font-size: 14px;
		line-height: 24px;
	}
	.tabRow li span {
		white-space: nowrap;
		width: 83px;
		overflow: hidden;
		display: inline-block;
	}
	.tabRow li.active span {
		width: 92px;
	}
	.tabRow ul li {
		padding: 7px 0px;
	}

	/**Tab end ****/
	::placeholder {
		font-size: 14px;
	}
	.passwordMatchBox ul li {
		margin: 3px 0px;
	}

	.popTop{
		padding:0px 15px;
	}
	.areYouText{
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
	}
	.useInfo{
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color:#565C65;
		letter-spacing: 0.4px;
	}
	.grayButtonColorBorder{
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		-khtml-border-radius: 2px;
		border-radius: 2px;
		min-width: 80px;
		height: 30px;
		line-height: 28px;
		font-weight: 600;
		font-size: 12px;
	}
	.popUpYesButton {
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		-khtml-border-radius: 2px;
		border-radius: 2px;
		min-width: 80px;
		height: 30px;
		line-height: 28px;
		font-weight: 600;
		font-size: 12px;
		border:1px solid #0466c8;
	}
	.popUpNoButton {
		border-radius: 2px;
		min-width: 80px;
		height: 30px;
		line-height: 28px;
		font-weight: 600;
		font-size: 12px;
	}
	.inviteUserPopUpButtonYes{
		height: 30px;
		line-height: 28px;
		font-weight: 600;
		font-size: 12px;
		width: 90px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		-khtml-border-radius: 2px;
		border-radius: 2px;
	}

	/*****************************************************************default css end  max-width 600px******************************************************************/
	body .displayNone600{
		display: none;
	}

	.errorMessage {
		font-size: 12px;
		line-height: 12px;
	}

	body .signColCenter {
		max-width: 400px;
		padding: 40px 20px;
	}
	.sameUserLoggedInRow {
		display: flex;
		flex-direction: column;
		padding-left: 10px;
		padding-right: 10px;
		justify-content: center;
	}
	.userImg {
		position: static;
		margin: 0px auto;
	}
	.userName {
		text-align: center;
	}
	.userEmail {
		text-align: center;
		display: block;
	}
	.notYou {
		position: static;
		text-align: center;
		width: 100%;
		margin-top: 15px;
	}
	.signInFormBox .button {
		width: 100%;
	}
	.signInFormBox {
		padding: 0px 15px;
	}
	.signInFormBox {
		margin-top: 20px;
	}
	.top {
		height: auto;
		min-height: calc(100vh - 88px);
	}
	.errorMessageWithBackground {
		padding: 8px 12px;
	}
	.errorTextContent {
		font-size: 10px;
		line-height: 12px;
	}
	/****Sign up start****/
	.titleWelcomeToAccount {
		font-size: 20px;
		margin-bottom: 0px;
	}
	.firstLastNameSpace .width50.paddingLeft1 {
		margin-top: 40px;
	}
	.logoCol {
		padding-left: 20px;
	}
	.firstLastNameSpaceSignUp {
		margin-top: 10px;
		margin-bottom: 8px;
	}
	.signInFormBox .marginTop40 {
		margin-top: 25px;
	}
	.signInFormBox .marginTop40.newFormUI {
		margin-top: 40px;
	}
	.signUpPasswordResponsive {
		margin-bottom: 28px;
	}
	.passwordMatchBox ul li .passowrdListOfConditionText {
		font-size: 12px;
	}
	.signUpFirstName {
		margin-bottom: 32px;
	}
	.termAndPrivacyPolicyRowSignUp {
		margin-top: 20px;
	}
	.termAndPrivacyPolicyText {
		margin-bottom: 0px;
	}
	/***Sign up end*****/

	/***Signup email verification start**/
	.emailVerificationCenterBox {
		max-width: 343px;
		padding: 40px 20px;
		margin-top: 20px;
	}
	.mainChildBoxCenter {
		align-items: flex-start;
	}
	.emailVerificationIcon {
		margin-bottom: 20px;
	}
	.emailVerificationMessage {
		font-size: 14px;
		line-height: 20px;
		/* or 143% */
		text-align: center;
		letter-spacing: 0.2px;
		color: #3d4551;
	}
	body .forgotPassdependentInput768 {
		margin-top: 50px;
	}

	/***Signup email verification end**/

	/******** my account  - personal info 600px start *******************/
	/******** my account  - passsword change 600px start *******************/
	.personalInfoNameRow,
	.formRow {
		padding-left: 105px;
	}

	.personalInfoNameRow, .formRow{
		padding-left: 20px;
	}
	.myAccountPersonalInfo label,
	.formRow label {
		font-size: 12px;
		padding-left: 15px;
		top: 14px;
		width: 80px;
		display: flex;
	}
	.inputColum24size {
		font-size: 16px;
		line-height: 20px;
	}
	.myProfile_personal_FirstName600 {
		width: 100%;
		padding-right: 0px;
		margin-bottom: 18px;
	}
	.selectBoxParend {
		padding-left: 0px;
	}
	.phoneDropDownResponsiveCol {
		max-width: 100%;
		position: relative;
		width: 100%;
	}
	.phoneTypeCol .phoneType1__single-value {
		font-size: 16px;
	}
	.myAccountPersonalInfo .react-tel-input .form-control {
		font-size: 14px;
	}
	.phoneTypeCol .phoneType1__placeholder {
		font-size: 14px;
	}
	.phoneTypeCol .phoneType1__control{
		min-height: 24px;
		height: 24px;
		margin-top:0px;
	}
	.myProfileAccount ::placeholder {
		font-size: 14px;
	}
	.stateCol .phoneType1__placeholder {
		font-size: 14px;
	}
	.myProfile_personal_city {
		width: 100%;
	}
	.myProfileZipCode {
		width: 100%;
	}
	.firstLastNameSpace {
		margin:0px;
	}
	.myAccountPersonalInfo .positionTop {
		top: 5px;
		position: static;
	}
	.myProfilePhoneNumber600 {
		float: left;
		width: 100%;
		margin-top:15px;
	}
	.myProfilePhoneCol1_600 {
		margin-top:0px;
	}

	.generalInfoProfileImg .broseRow{
		margin-top: 10px;
	}
	.dependentTabContainerCol .generalInfoProfileImg {
		height: 61px;
		width: 61px;
	}
	.phoneTypeCol .phoneType1__menu{
		width: 100% !important;
		max-width: 100% !important;
	}

	.myAccountPersonalInfo .react-tel-input .form-control{
		padding-left:2px;
		height: 25px;
	}

	h2.titleWithSidebar.headerFixed {
		padding-top: 60px;
		margin-bottom: 0px;
		background: #FAFCFD;
		padding-bottom: 10px;
	}



	.myAccountPersonalInfo label.myProfle_phone_label600 {
		top: 28px;
	}
	.myAccountPersonalInfo .myProfilePhoneCol2_600 {
		margin-top: 14px;
	}
	.myAccountPersonalInfo .positionTop768_25 {
		top: 16px;
	}
	.myProfileAddres2 {
		margin-top: 20px;
	}
	.myAccountPersonalInfo .phoneType1__value-container {
		padding-left: 0px;
	}
	.myProfile_personal_city {
		margin-bottom: 10px;
		margin-top: 15px;
	}
	.myProfile_personal_state.phoneDropDownResponsiveCol {
		margin-bottom: 20px;
	}
	.buttonRightMargin {
		margin-right: 15px;
	}
	.cancelButton1 {
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		letter-spacing: 0.4px;
		color: #8D9297;
	}

	.saveButton {
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		letter-spacing: 0.4px;
		color: #ffffff;
		/*background: #34a0a4;*/
		border-radius: 2px;
	}
	.phoneTypeCol .phoneType1__menu {
		max-width: 234px !important;
	}
	body .loginEmailVeriedCol {
		margin-top: 50px;
	}
	.phoneTypeCol .phoneType1__menu-list .phoneType1__option {
		font-size: 14px;
	}
	.phoneTypeCol .phoneType1__menu-list .phoneType1__option {
		padding: 0px 20px;
	}
	.phoneTypeCol .phoneType1__menu-list div:before {
		top: 5px;
		position: relative;
	}
	.emailVerficationMaxWidth .forgotPasswordLinkText {
		margin-top: 10px;
	}
	.stateCol .phoneType1__option,
	.stateCol .phoneType1__single-value {
		font-size: 14px;
		line-height: 12px;
	}
	.loaderSpinner {
		border-radius: 4px;
	}
	.passwordButtonRow.displayFlexColJC-start {
		align-items: flex-start;
	}
	.cancelButton1 {
		width: 80px;
		height: 30px;
	}
	.saveButton {
		height: 30px;
		min-width: 70px;
	}
	.changePasswordButton {
		margin-top: 5px;
	}
	/******** my account  - personal info 600px end *******************/

	.firstLastNameSpace {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.25px;
		color: #3d4551;
	}
	.firstLastNameSpace.passwordTabUsername {
		word-break: break-all;
	}
	.myAccountPersonalInfo.myAccountPassordScreen label {
		top: 12px;
		position: static;
	}
	.myAccountPersonalInfo label, .formRow label {
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 24px;
		letter-spacing: 0.15px;
		color:#0466C8;
		padding-left: 0px;
	}
	.myProfile_password_email600 {
		margin-bottom: 10px;
	}
	.myProfile_password_oldPassword600 {
		margin-bottom: 18px;
	}
	.myAccountPersonalInfo label.nameLable {
		top: 16px;
		position: static;
	}

	.myAccountPersonalInfo label.myProfle_phone_label600{
		position: static;
		margin-top: 20px;
	}

	.myAccountPersonalInfo label.addressLable{
		top:17px;
		position: static;
	}

	.myProfile_password_Newpassword600 {
		margin-bottom: 10px;
	}
	.passwordButtonRow {
		display: flex;
		flex-direction: row;
		margin-top: 15px;
	}
	.changePasswordButton {
		margin-top: 10px;
		margin-right: 15px;
	}

	.saveButton{
		border-radius:2px;
	}

	.changePasswordButton .cancelButton1{
		border-radius:2px;
	}

	.changePasswordButton .passwordTextShow {
		top: 3px;
	}
	.passwordTextShow svg {
		width: 15px;
	}
	/******** my account  - passsword change 600px end *******************/

	.logoutMainCol {
		margin-top: 20px;
	}
	h2.titleWithSidebar:before {
		content: '';
		position: fixed;
		background: #fff;
		width: 100%;
		height: 80px;
		left: 0px;
		top: 0px;
		z-index: -1;
	}
	/***************************************dependent profile screen 600 start**************************************************************************************************************************************************************************************************************/
	.titleNameAndTabHeader{
		width: 100%;
		margin: 0px;
		padding-left: 15px;
		padding-right: 15px;
		left:0px;
		top:	0px;
		border-radius: 0px;
	}
	.dependentTabRow{
		margin-top: 40px;
	}
	.dependentTab{
		font-size: 14px;
		line-height: 18px;
	}
	.geneInfo_value{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		float: left;
		width: 100%;
	}
	.sideBarNav {
		background: transparent;
		width: 100%;
		max-width: 100%;
		position: fixed;
		z-index: 90;
		height: 0%;
	}


	.titleNameAndTabHeader:before {
		/*display: none;*/
		top:79px;
		z-index: -1;
		background: #FAFCFD;
	}
	.dependentTab:nth-child(1) span{
		white-space: nowrap;
		overflow: hidden;
		width: 77px;
		display: inline-block;
	}
	.dependentProfileMainViewBox{
		padding: 15px;
	}

	.dependentTabContainer .generalInfo_buttonEditCol span{
		display: none;
	}
	.dependentTabContainer .generalInfo_buttonEditCol{
		padding-left: 1px;
		padding-right: 1px;
		height: 26px;
	}
	.geneInfo_value span{
		font-size: 14px;
	}
	#General_Information {
		min-height: inherit;
	}
	.dependentAddressTitle{
		font-size: 18px;
		line-height: 24px;
	}
	.dependentColradioButton [type="radio"]:checked{
		min-width: 20px;
		width: 20px;
		height: 20px;
	}
	.dependentColAddress{
		font-weight: 500;
		font-size: 16px;
		line-height: 23px;
	}
	.dependentAddressRow{
		padding-right: 20px;
		padding-bottom: 35px;
	}
	.dependentColActionBUtton{
		top:inherit;
		bottom:15px;
		width: 56px;
	}
	.dependentColActionBUtton span{
		margin-right:10px;
	}
	.dependentAdvocateActionBUtton span{
		margin-left: 10px;
	}
	.dependentAdvocateTeamRow{
		padding-right: 20px;
		padding-left: 54px;
		display:block;
	}
	.dependentAdvocateTeamIcon{
		position: absolute;
		left: 16px;
	}
	.dependentAdvocateTeamIcon svg{
		width: 26px;
		height: 26px;
	}
	.dependentAdvocateTeamUserName{
		font-size: 16px;
		line-height: 24px;
		color:#2D2E2F;
	}
	.dependentAdvocateTeamUserEmailId {
		font-size: 14px;
		line-height: 24px;
	}
	.dependentPendingStatus, .dependentActiveStatus{
		margin-top: 5px;
		font-weight: 600;
		font-size: 16px;
		height: 34px;
		line-height: 34px;
	}
	.dependentAdvocateTeamUserEmailId{
		width: 100%;
	}
	.dependentAdvocateActionBUtton{
		right:12px;
	}
	.positionRelative:hover span.addressTooltip{
		display: none;
	}
	#General_Information .dependentProfileMainViewBox{
		padding-top:0px;
		margin-top: 40px;
	}
	.dependentTabContainer{
		padding-top:10px;
	}
	.dependentNoAddressData svg{
		max-width: 40px;
	}
	.noAddressText{
		font-size: 20px;
		line-height: 24px;
	}
	div#Advocate_Team {
		border-bottom-width: 0px;
	}
	.dependentColradioButton [type="radio"]{
		background-size: 25px;
	}
	.dependentEditProfileMain .dependentBorderBox{
		background:#fff;
		padding:20px 15px;
	}
	.dependentEditProfileMain h2.titleWithSidebar.marginBottom30{
		margin-bottom: 15px;
	}
	.dependentEditProfileMain .dependentStepFormRow{
		padding-left: 0px;
		margin-bottom:20px;
	}
	label.custom-file-upload:before {
		opacity: 1;
	}
	.dependentBorderBox .labelCol {
		position: static;
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 24px;
		letter-spacing: 0.15px;
		color:#0466C8;
	}
	.dependentEditProfileMain .generalInfoTitle{
		padding-right: 0px;
	}
	.dependentEditProfileMain .generalInfoProfileImg.dependentPro .custom-file-upload{
		width: 66px;
		height: 66px;
	}

	.dependentEditProfileMain .generalInfoProfileImg{
		width: 66px;
		height: 66px;
		margin-right: 16px;
	}
	.dependentEditProfileMain  .dependentEditProfile {
		margin-top: 30px;
	}
	.dependentEditProfileMain .generalInfoProfileImg.dependentPro label.custom-file-upload:before{
		width: 66px;
		height: 66px;
		width: 22px;
		height: 22px;
		line-height: 36px;
		content:'';
		background-size: 12px;
		background-repeat: no-repeat, no-repeat;
		background-position: center center;
		background-image: url("../images/cameraUploadImgSmall.svg");
		margin-left: 40px;
		margin-top: 40px;

	}
	.dependentEditProfileMain .generalInfoProfileImg.dependentPro .custom-file-upload svg {
		width: 66px;
		height: 66px;
	}
	.dependentStepFormRow .width37{
		padding-right:0px;
		width: 100%;
		margin-bottom: 20px;
	}
	.dependentStepFormRow .width63{
		padding-left:0px;
		width: 100%;
	}
	.dependentGenderCol .dependentGender__single-value {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.25px
	}

	.dependentGenderCol .dependentGender__value-container, .dependentGenderCol .dependentGender__control{margin-top:0px;align-items: flex-end;min-height: inherit;}
	.dependentGender__indicators{display: flex;align-items: flex-end;}
	.dependentGenderCol span{width: auto;}
	.dependentGender__control{min-height: inherit;}
	.dependentGender__indicator{padding:0px;}
	.dependentGenderCol .dependentGender__control--is-focused:focus {border-color: #c4c4c4 !important;}
	.dependentGender__indicator.dependentGender__dropdown-indicator{padding: 0px;}
	.dependentGender__control, .dependentGender__control{min-height: inherit;}
	.dependentGenderCol .dependentGender__input-container{padding: 0px;margin: 0px;}

	.dependentGenderCol .dependentGender__value-container, .dependentGenderCol .dependentGender__control{margin-top:0px;align-items: flex-end;min-height: inherit;}
	.dependentGender__indicators{display: flex;align-items: flex-end;}
	.dependentGenderCol span{width: auto;}
	.dependentGender__control{min-height: inherit;}
	.dependentGender__indicator{padding:0px;}
	.dependentGenderCol .dependentGender__control--is-focused:focus {border-color: #c4c4c4 !important;}
	.dependentGender__indicator.dependentGender__dropdown-indicator{padding: 0px;}
	.dependentGender__control, .dependentGender__control{min-height: inherit;}
	.dependentGenderCol .dependentGender__input-container{padding: 0px;margin: 0px;}

	.dependentStepFormRow .react-tel-input{float: left; margin-bottom:7px;}
	.width48{width: 100%}
	.width45{width: 100%}
	.dependentEditProfileMain .myProfilePhoneCol1_600{padding-right: 0px;}
	.dependentEditProfileMain .dependentStepFormRow .phoneDropDownResponsiveCol{margin-bottom:20px;}
	.dependentEditProfileMain .phoneType1__indicator.phoneType1__dropdown-indicator{padding:0px;}

	.dependentGenderCol .phoneType1__value-container, .phoneType1Col .phoneType1__control{margin-top:0px;align-items: flex-end;min-height: inherit;}
	.phoneType1__indicators{display: flex;align-items: flex-end;}
	.phoneType1Col span{width: auto;}
	.phoneType1__control{min-height: inherit;}
	.phoneType1__indicator{padding:0px;}
	.phoneType1Col .phoneType1__control--is-focused:focus {border-color: #c4c4c4 !important;}
	.phoneType1__indicator.phoneType1__dropdown-indicator{padding: 0px;}
	.phoneType1__control, .phoneType1__control{min-height: inherit;}
	.phoneType1__control .phoneType1__input-container{padding: 0px !important; margin: 0px !important;}
	.dependentBorderBox .phoneTypeCol .phoneType1__control{display: flex;align-items: flex-end;min-height: inherit !important;height: inherit !important;}
	.dependentEditProfileMain .myProfilePhoneCol1_600{margin-top:0px;}
	.dependentEditProfileMain .labelCol.phoneEditProfile {position: static;width: 100%;max-width: 200%;float: left; line-height: 14px;}
	.phoneTypeCol .phoneType1__value-container{padding:0px !important;}
	.dependentEditProfileMain .paddingBottom100{padding-bottom: 60px;}
	.dependentBorderBox .react-tel-input .form-control{
		font-size: 16px; line-height: 20px; padding-left: 0px; height: 27px;
	}

	.dependentAddressEditMain .dependentStepEditFormRow {
		padding-left: 0px;
	}
	.dependentAddressEditMain .dependentBorderBox {
		background:#fff;
		padding: 15px;
	}

	.dependentAddressEditMain h2.titleWithSidebar.marginBottom30 {
		margin-bottom: 15px;
	}

	.dependentAddressEditMain .dependentRowSpace{
		margin: 16px 0px 0px 0px;
	}

	.dependentResidence__indicators{display: flex;align-items: flex-end;}
	.dependentResidencCol span{width: auto;}
	.dependentResidence__control{min-height: inherit;}
	.dependentResidence__indicator{padding:0px;}
	.dependentResidencCol .dependentResidence__control--is-focused:focus {border-color: #c4c4c4 !important;}
	.dependentResidence__indicator.dependentResidence__dropdown-indicator{padding: 0px;}
	.dependentResidence__control, .dependentResidence__control{min-height: inherit;}
	.dependentResidencCol .dependentResidence__input-container{padding: 0px;margin: 0px;}

	.dependentResidencCol .dependentResidence__value-container, .dependentResidencCol .dependentResidence__control{margin-top:0px;align-items: flex-end;min-height: inherit;}
	.dependentResidence__indicators{display: flex;align-items: flex-end;}
	.dependentResidencCol span{width: auto;}
	.dependentResidence__control{min-height: inherit;}
	.dependentResidence__indicator{padding:0px;}
	.dependentResidencCol .dependentResidence__control--is-focused:focus {border-color: #c4c4c4 !important;}
	.dependentResidence__indicator.dependentResidence__dropdown-indicator{padding: 0px;}
	.dependentResidence__control, .dependentResidence__control{min-height: inherit;}
	.dependentResidencCol .dependentResidence__input-container{padding: 0px;margin: 0px;}
	.dependentResidencCol .dependentResidence__placeholder{font-size: 16px; line-height: 16px;}
	.dependentResidencCol .dependentResidence__single-value{
		font-size: 16px;
		line-height: 20px;
		margin: 0px;
	}
	.dependentResidencCol .dependentResidence__option{
		font-size:16px;
		color:#71767A;
		line-height:20px;
		background: transparent !important;
	}
	.dependentResidencCol .dependentResidence__option--is-focused{
		font-size:16px;
		color:#71767A;
		line-height:20px;
		background: transparent;
	}

	.dependentAddressEditMain .address2,.dependentAddressEditMain .city{
		margin:16px 0px 0px 0px;
	}
	.dependentAddressEditMain .address1 {
		margin:0px 0px 0px 0px;
	}
	.dependentAddressEditMain .city{
		padding-right: 0px;
	}
	.dependentAddressEditMain .countryState {
		padding-left: 0px;
		margin-top: 15px;
	}
	.dependentAddressEditMain .zip {
		padding-right: 0px;
		margin-top: 6px;
	}
	.dependentAddressEditMain .country {
		padding-left: 0px;
		margin-top: 15px;
	}
	.dependentResidencCol .dependentResidence__control{
		height: inherit;
	}

	.dependentWizard h2.titleWithSidebar.marginBottom30{
		margin-bottom: 15px;
	}
	.dependentWizard .dependentBorderBox{
		background:#fff;
		padding:15px;
	}
	.dependentWizard .dependentDefaultCenterBox svg{
		max-width: 183px;
		height: 154px;
	}
	.thisDependentText{
		margin-top: 0px;
	}
	.buttonGreenColor{
		font-size: 14px;
		line-height: 24px;
		height: 40px;
		margin-top: 20px;
	}
	.thisDependentText p {
		font-size: 16px;
		line-height: 24px;
	}

	.dependentSubTitle{
		font-size: 18px;
		line-height: 24px;
	}

	.dependentStep3FormCol .dependentButtonRowBottom {
		bottom:40px;
	}
	.dependentStep3FormCol .dependentOnboardLaterText {
		top:-25px;
	}
	.dependentStepFormRow{
		padding-left: 0px;
	}
	.dependentRowSpace {
		margin: 5px 0px 11px 0px	;
	}
	.dependentGenderCol input[type="radio"]{
		width: 40px;
		height: 34px;
	}
	.dependentGenderCol span {
		width: 40px;
		height: 34px;
		font-size: 16px;
		line-height: 20px;
	}
	.dependentGenderCol label{
		width: 40px;
		margin-right:40px;
	}
	.dependentGenderCol{
		margin-top: 8px;
	}
	.dependentButtonRowBottom {
		padding:0px 15px;
	}
	.saveButtonDisable{
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		letter-spacing: 0.4px;
		border-radius: 2px;
		height:30px;
		min-width: 70px;
	}
	.DependentStep2FormCol {
		min-height: calc(100vh - 235px);
		margin-top: -10px;
	}
	.dependentOnboardLaterText{
		margin-top:0px;
		font-size: 14px;
		line-height: 17px;
		top:-35px;
	}
	.bottomButtonWizard .loaderSpinner{
		margin-top:0px;
		margin-bottom:0px;
	}

	.custom-file-upload{
		width: 108px;
		height: 108px;
	}
	.custom-file-upload svg{
		width: 96px;
		height: 96px;
	}
	.dependentImgageCol{
		width: 96px;
		height: 96px;
		border:6px solid #E2EDF8;
		margin-bottom: 14px;
	}
	label.custom-file-upload:before{
		width: 36px;
		height: 36px;
		line-height: 36px;
		background-size: 20px 18px;
		background-repeat: no-repeat;
		text-align: center;
		background-position: center;
		content:'';
		background-image: url("../images/cameraUploadImg.svg");
		margin-top: 60px;
		margin-left: 60px;
	}
	label.custom-file-uploadImage:before{
		width: 36px;
		height: 36px;
		line-height: 36px;
		background-size: 20px 18px;
		background-repeat: no-repeat;
		text-align: center;
		background-position: center;
		content:'';
		background-image: url("../images/cameraUploadImg.svg");
		margin-top: 69px;
		margin-left: 66px;
	}
	.imageCropBoxMain{
		padding-left:0px;
		padding:10px 20px;
	}
	.imgCropBoxInner{
		padding: 20px;
	}
	.imageCropButtonRow{
		margin-top:20px;
	}

	.imgCropBox:before {
		background: rgba(0, 0, 0, 0.2);
	}

	.imageCropButtonRow .doneButton{
		font-size:12px;
		line-height: 14px;
		min-width: 86px;
		height: 32px;
	}
	.imageCropButtonRow .doneButtonDisable{
		font-size:12px;
		line-height: 14px;
		min-width: 86px;
		height: 32px;
	}
	.dependentWizard .myProfilePhoneCol1_600{
		padding-right: 0px;
	}
	.myProfilePhoneCol1_600 .phoneDropDownResponsiveCol, .myProfilePhoneCol2_600 .phoneDropDownResponsiveCol{
		margin-bottom:0px;
	}
	.avtarTextCol{font-size: 20px;line-height: 29px; padding: 0px 25px; margin-top: 10px;}
	.dependentAfterUploadImgCol .positionAbsoute.width300Center{
		max-width: 200px;
		margin-top: 6px;
	}
	.imageOptionList li{
		padding-top:12px;
		padding-bottom:12px;
		font-size: 16px;
		line-height: 20px;
	}
	.imageOptionList li:nth-child(1){
		border-radius:5px 5px 0px 0px;
	}
	.imageOptionList li:nth-child(2){
		border-radius:0px 0px 5px 5px;
	}
	.dependentStep3FormRow{
		padding-left: 0px;
	}
	.dependentBorderBox{
		min-height: calc(100vh - 145px);
	}
	.generalInfoProfileImg.dependentPro .imageOptionList li{
		font-size: 14px;
	}
	.generalInfoProfileImg .imageOptionList{
		width: 135px;
	}

	.dependentAfterUploadImgCol .positionAbsoute{
		z-index: 100;
	}
	body .react-datepicker-popper {
		z-index: 6;
	}
	/*.dependentAfterUploadImgCol .positionAbsoute:before {*/
	/*	position: fixed;*/
	/*	content: '';*/
	/*	width: 100%;*/
	/*	height: 100%;*/
	/*	z-index: -1;*/
	/*	background:rgba(0, 0, 0, 0.2);*/
	/*	left: 0px;*/
	/*	top: 0px;*/
	/*	display: none;*/
	/*}*/
	.imageOptionList li {
		padding-left: 20px;
	}
	.generalInfoProfileImg.dependentPro .imageOptionList ul:before{
		left:39%;
	}
	.generalInfoProfileImg .dependentImgageCol{
		overflow: visible;
		width:66px;
		height: 66px;
		margin-top: 10px;
	}
	.generalInfoProfileImg.dependentPro label.custom-file-uploadImage:before {
		width: 22px;
		height: 22px;
		content: '';
		margin-top: 50px;
		margin-left: 50px;
	}
	label.custom-file-uploadImage:before{
		opacity: 1;
		background-image: url("../images/cameraUploadImgSmall.svg");
	}
	.dependentBorderBox{
		background:#fff;
		padding: 15px;
	}
	.dependentInviteuserFormRow{
		padding-left: 0px;
	}
	.infoTextInviteUser{
		font-size: 14px;
		line-height: 20px;
	}
	span.infoIconInviteUser svg{
		height: 30px;
		width: 30px;
	}
	.inviteUserTitle{
		margin-bottom: 20px;
		position: relative;
		text-align: center;
	}
	h2.inviteUserTitle span{
		top:20px;
		display: inline-block;
		cursor: pointer;
		position: absolute;
		left: 15px;
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		letter-spacing: 0.4px;
		color:#0096C7;
	}
	.generalInfoProfileImg{
		margin-right: 15px;
	}
	.titleNameAndTabHeader .dependentTabRow{
		margin-top: 0px;
	}
	/***************************************dependent profile screen 600 end**************************************************************************************************************************************************************************************************************/
	/***************************************Income and expense screen 600 start**************************************************************************************************************************************************************************************************************/
	/*******************************************Table responsive code start***************************************************************************************/

	.responsiveTalbe table, .responsiveTalbe thead, .responsiveTalbe tbody, .responsiveTalbe th, .responsiveTalbe td, .responsiveTalbe tr {
		display: block;
	}
	.responsiveTalbe thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.responsiveTalbe tr {border-radius: 5px;padding-top:10px; padding-bottom: 10px; position: relative; padding-right:75px}
	.tableBody.responsiveTalbe td {
		border: none;
		position: relative;
		padding-left:130px;

	}
	.responsiveTalbe td:before {
		position: absolute;
		top: 5px;
		left: 20px;
		width: 120px;
		padding-right: 10px;
		white-space: nowrap;
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 24px;
		letter-spacing: 0.15px;
		text-transform: uppercase;
		color: #71737A;
	}

	.responsiveTalbe td:nth-of-type(1):before { content: "DATE"; }
	.responsiveTalbe td:nth-of-type(2):before { content: "EXPENSE TYPE"; visibility: visible }
	.responsiveTalbe td:nth-of-type(3):before { content: "PAID TO"; }
	.responsiveTalbe td:nth-of-type(4):before { content: "AMOUNT"; }

	.responsiveTalbe.tableBody tr:nth-child(odd){background:#fff;}
	.responsiveTalbe.tableBody tr:nth-child(even){background:transparent;}

	.responsiveTalbe .ellipsis span {width: 130px;position: static}

	/*******************************************Table responsive code start***************************************************************************************/
	.tableBody.responsiveTalbe td{
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.15px;
		color: #3D4551;
		padding-top:4px;
		padding-bottom: 4px;
		min-height: 32px;
		word-break: break-word;
	}
	.tableTopLeft.df {
		width: 29px;
		overflow: hidden;}
	.lightBackgroundBlueColor {
		background:#FAFCFD;
	}
	td.tac.tdAction {
		position: absolute;
		right: 0px;
		top: 15px;
	}
	.expenseBoxCol{
		padding:5px;
		float: left;
		width: 100%;
	}
	.expenseBoxCol .a12-icon{
		display: none;
	}
	.expenseBoxCol .lblTotal {
		background: transparent;
	}
	.plusIcon{
		padding:0px;
		width: 34px;
		height: 34px;
		border-radius: 50%;
		min-width: inherit;
	}
	.plusIcon span {
		font-size: 33px;
		font-weight: normal;
	}
	.expenseTitleMargin{
		margin:15px 15px 15px 10px;
	}
	.amoutAddIconTopCol{
	max-width: 215px;
	position: absolute;
	top: 11px;
	right: 20px;
}
	.expenseListScreenMain .lblTotal {
		padding-top:5px;
		padding-bottom:5px;
		color: #71767A;
	}
	.expenseBorderBox600{
		padding:15px 18px;
		background:#fff;
	}
	.expenseTableTitle .tdAction .marginRight10{ margin-right: 5px;}
	body .addExpensesWrap h3, body .addIncomesWrap h3,  body .addActivityWrap h3{
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.15px;
	}
	.addExpenseScreenMain .formRow {
		position: relative;
		padding-left: 0px;

	}
	.addExpenseScreenMain .formRow label {
		position: static;
		width: 100%;
		float: left;
		text-align: left;
		padding-left: 0px;
		color:#0466C8;
		line-height: 24px;
	}
	.addExpenseScreenMain .amountWrap .currency {
		top: 1px;
		font-size: 16px;
		line-height: 18px;
	}
	.addExpenseScreenMain .payRow .width50 {
		padding-left: 0px;
		margin: 0px 0px 12px 0px;
		padding-right: 0px;
	}
	.addExpenseScreenMain .formRow.fullWidth.formRowGap, .addExpenseScreenMain .formRow.width50.formRowGap {
		margin: 0px 0px 12px 0px;
	}
	.addExpenseScreenMain .payRow .paidTo{
		margin-bottom: 0px;
	}
	.addExpenseScreenMain .formRow .paddingRight16{
		padding-right: 0px;
	}
	.addExpenseScreenMain .txtNote{
		height: 60px;
	}
	.formRow .txtCapture {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 0.2px;
		color: #71767A;
		margin-top: 5px;
	}
	body .fileType {
		width: 40px;
		margin-right: 10px;
	}
	.attachedImgList.fileList .fileInner{
		width: 100%;
		padding-left:0px;
		margin-right: 0px;
	}

	.attachedImgList.fileList .fileInner:nth-child(even) {
		margin-left: 0px;
	}

	body .addExpensesWrap .formRow.fullWidth.formRowGap.uploadWrap{
		margin-bottom:0px;
	}
	body .fileType img{
		width: 100%;
		height: auto;
	}

	.formRow .expenseType__input{
		opacity:0 !important;
		height: 20px;
	}
	.expenseType__placeholder {
		margin-left: 0px;
	}
	.formRow .expenseType__indicator.expenseType__dropdown-indicator{
		padding:0px;
	}
	.formRow .expenseType__input-container{
		padding-top:0px;
		margin-bottom:0px;
		padding-bottom:0px;
	}
	.formRow .expenseType__value-container{
		align-items: flex-end;
	}
	.formRow .expenseType__control {
		align-items: flex-end;
		min-height: 26px;
	}
	.formRow .expenseType__single-value{
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 18px;
		letter-spacing: 0.25px;
	}
	.formRow .inputColum24size::placeholder {
		font-size: 16px;
	}
	h2.titleWithSidebar{
		position: static;
		z-index: 100;
	}
	/*h2.titleWithSidebar span {*/
	/*	position: fixed;*/
	/*	left: 20px;*/
	/*	top: 28px;*/
	/*}*/
	h2.titleWithSidebar > span > svg{
		/*display: none;*/
		width: 10px !important;
		height: 12px !important;
	}
	.addExpenseScreenMain .formRow label sup{
		display: none;
	}
	/*.noExpenseMainBox{*/
	/*	margin-top:15px;*/
	/*}*/
	.noExpenseTitle{margin:10px 0px !important;}
	.noExpenseMainBox .dependentDefaultCenterBox svg {
		max-width: 178px;
		max-height: 122px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.noExpenseMainBox .dependentBorderBox{
		border-width:0px;
		padding:0px;
	}
	.noExpenseMainBox h2.titleWithSidebar span{
		top:-94px;
	}
	.stayOnTop_Text600{
		margin-top:0px;
	}
	.expenseListScreenMain h2.titleWithSidebar span {
		position: absolute;
	}
	.addExpensesWrap .expenseBorderBox600 .marginBottom10{
		margin-bottom: 0px;
	}
	.deleteIcon{
		display: none;
	}
	.ViewIcon{
		display: inline-block;
	}
	.ViewIcon img{
		max-width: 29px;
	}
	.expenseBoxCol .expenseTableTitle{
		margin-top:80px;
	}
	.expenseBoxCol .dateWrap {
		position: absolute;
		left: 0px;
		top: 83px;
		z-index: 10;
		background: rgba(52, 160, 164, 0.04);
		padding: 8px 5px;
		width: 100%;
	}
	.noExpenseMainBox .expensesWrap h3 {
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.15px;
		margin-bottom: 10px;
	}
	.noExpenseMainBox .bulletUl {
		margin: 0px auto;
	}

	.AddFirstExpenseButton {
		max-width: 306px;
	}
	.addExpensesWrap .titleWithSidebar{
		margin-bottom: 0px;
	}
	.noExpenseMainBox .bulletUl li{
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.4px;
		color: #71767A;
		position: relative;
		padding-left: 55px;
		padding-right: 40px;
	}
	.noExpenseMainBox .bulletUl li svg {
		position: absolute;
		left: 20px;
		top: 15px;
	}

	.ViewExpenseMain .dependentBorderBox{
		padding:20px 15px;
		background:#fff;
		border:0px;
	}
	.ViewExpenseMain .expenseDetails label{
		width: 100%;
		display: block;
		font-weight: 600;
		font-size: 12px;
		line-height: 24px;
		letter-spacing: 0.15px;
		color: #A9AEB1;
	}

	.ViewExpenseMain  .expenseDetails > div{
		flex-direction: column;
	}
	.ViewExpenseMain .expenseDetails > div > div{
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.0025em;
		color: #3D4551;
	}
	.ViewExpenseMain .expenseDetails > div{
		padding: 7px 0px;
	}

	body .uploadFileName {
		font-size: 16px;
		line-height: 1;
	}

	.progressWrap .progress{
		height:6px;
	}

	.ViewExpenseMain .dependentSubTitle {
		font-size: 18px;
		line-height: 24px;
	}

	.progressWrap .df span {
		font-size: 10px;
		margin: 0px 4px;
	}
	body .fileType {
		width: 36px;
	}

	.fileList.df.fcol.fullWidth.p0 {
		padding-top: 15px !important;
	}
	body .fileInfo h5 {
		font-size: 16px;
		line-height: 20px;
		color:#3D4551;
	}
	body .fileInfo .df span {
		color:#565C65;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.25px;
	}
	body .file + .file {
		margin-top: 5px;
		padding-top: 5px;
	}
	body .fileList > div + div{
		border:0px;
	}
	.ViewExpenseMain .expenseDetails label.df {
		margin-bottom: 10px;
	}
	.desktopCol{
		display: none;
	}
	.mobileCol{
		display: inline-block;
	}
	.actionButton span{
		margin-left: 17px;
		cursor: pointer;
	}

	.noIncomeMain svg{
			max-width: 180px;
			height: 120px;
			margin-top:30px;
	}
	.noIncomeMain .expensesWrap h3 {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	}

	.noIncomeMain .bulletUl {
		margin-left: 0px;
		max-width: 262px;
		margin: 0px auto;
	}
	.noIncomeMain .bulletUl li{
		padding-left: 15px;
		font-size: 16px;
		line-height: 20px;
		position: relative;
	}
	.noIncomeMain .bulletUl svg{
		position: absolute;
		left: -17px;
		top:0px;
		margin-top:15px
	}
	.noIncomeTitle{
		margin: 10px 0px;
	}

	.addIncomesWrap .formRow label{
		position: static;
		width: 100%;
	}
	.addIncomesWrap .formRow {
		padding-left: 0px;
	}
	.addIncomesWrap .payRow .width50{
		padding-left: 0px;
	}

	.addIncomesWrap .paidTo label {
		padding-left: 0px;
	}

	.addIncomesWrap .firstLastNameSpace, .formRowGap {
		margin-top: 0px;
		margin-bottom: 20px;
	}

	.addIncomesWrap .payRow .width50 {
		padding: 0px;
	}

	.addIncomesWrap .payRow .paidTo {
		padding-right: 0px;
		margin-top: 20px;
	}

	.addIncomesWrap .amountWrap .currency {
		font-size:16px;
		top: -2px;
	}

	h2.titleWithSidebar.addIncomeTitle {
		margin: 15px 0px 15px 0px;
	}
	h2.titleWithSidebar.viewIncomeTitle {
		margin: 15px 0px 15px 10px;
	}
	 h2.titleWithSidebar.viewIncomeTitle span{
		 position: absolute;
	 }
	.tableTopMain {
		margin-bottom: 12px;
		margin-top: -8px;
	}

	/*.incomeBoxcol .IncomeTableTopLeft{*/
	/*	!*width: auto;*!*/
	/*	!*overflow: auto;*!*/
	/*	position: relative;*/
	/*}*/
	.tableTopMain {
		position: relative;
	}
	.incomeBoxcol .incomeTableTopLeft .dateWrap{
		position: absolute;
		left: 0px;
		top: 83px;
		z-index: 10;
		background: rgba(52, 160, 164, 0.04);
		padding:8px 5px;
		width: 100%;
	}

	.tableTopMain .totalIncome{
		background: transparent;
	}
	.incomeBoxcol .incomeTableTopLeft span.a12-icon {
		display: none;
	}

	.incomeTableMargin {
		margin-top: 50px;
	}

	.tableTopRight .lblTotal {padding: 6px 10px;}

	.incomeTypeCol .incomeType__value-container, .phoneType1Col .incomeType__control{margin-top:0px;align-items: flex-end;min-height: inherit;}
	.incomeType__indicators{display: flex;align-items: flex-end;}
	.phoneType1Col span{width: auto;}
	.incomeType__control{min-height: inherit;}
	.incomeType__indicator{padding:0px;}
	.phoneType1Col .incomeType__control--is-focused:focus {border-color: #c4c4c4 !important;}
	.incomeType__indicator.incomeType__dropdown-indicator{padding: 0px;}
	.incomeType__control, .incomeType__control{min-height: inherit;}
	.incomeType__control .incomeType__input-container{padding: 0px !important; margin: 0px !important;}
	.incomeTypeCol .incomeType__control{display: flex;align-items: flex-end;min-height: inherit !important;height: inherit !important;}
	.incomeTypeCol .incomeType__value-container{padding:0px !important;}


	.responsiveTalbe.incomeTableTitle td:nth-of-type(1):before { content: "DATE"; }
	.responsiveTalbe.incomeTableTitle td:nth-of-type(2):before { content: "INCOME TYPE"; visibility: visible }
	.responsiveTalbe.incomeTableTitle td:nth-of-type(3):before { content: "AMOUNT"; }
	.responsiveTalbe.incomeTableTitle td:nth-of-type(4):before { content: ""; }

	.responsiveTalbe.incomeTableTitle td.tdAction {
		position: absolute;
		right: 0px;
		top: 10px;
	}

	.responsiveTalbe.activityTableTitle td.tac.tdAction {
		top:inherit;
		bottom: 15px;
	}
	/*.responsiveTalbe.incomeTableTitle td.tdAction > div{ display: flex; flex-direction:column; }*/
	/*.responsiveTalbe.incomeTableTitle td.tdAction span.ViewIcon {display: block; order: -1; margin-bottom:15px;}*/
	/*.responsiveTalbe.incomeTableTitle td.tdAction span.deleteIcon {display: none;}*/
	/*.responsiveTalbe.incomeTableTitle td.tdAction > div > span {margin-right: 5px;}*/
	/*.responsiveTalbe.incomeTableTitle td.tdAction > div > span.ViewIcon {margin-right:0px;}*/
	.responsiveTalbe.expenseTableTitle td.tac.tdAction {bottom:inherit; top:10px;}
	.responsiveTalbe.expenseTableTitle td.tac.tdAction .ViewIcon{margin-left: 15px}

	.incomeBoxcol{
		padding: 5px;
		float: left;
		width:100%;
	}

	.incomeBoxcol .incomeTableTitle {
		margin-top:0px;
	}

	.viewIncomeMain .dependentBorderBox{
		padding:20px 15px;
		background:#fff;
		border:0px;
	}
	.viewIncomeMain .expenseDetails label{
		width: 100%;
		display: block;
		font-weight: 600;
		font-size: 12px;
		line-height: 24px;
		letter-spacing: 0.15px;
		color: #A9AEB1;
	}

	.viewIncomeMain  .expenseDetails > div{
		flex-direction: column;
	}
	.viewIncomeMain .expenseDetails > div > div{
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.0025em;
		color: #3D4551;
	}
	.viewIncomeMain .expenseDetails > div{
		padding: 7px 0px;
	}
	.viewIncomeMain .dependentSubTitle {
		font-size: 18px;
		line-height: 24px;
	}

	.tableTopMain {
		justify-content: flex-end;
	}
	.tableTopMain .mla {
		margin-left: 5px;
	}

	.responsiveOverlay{
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.2);
		z-index: 100;
	}
	.overlayFull {
		z-index: 110;
	}
	.mainOverlay{
		z-index: 110
	}

	.mainOverlay h2.titleWithSidebar:before{
		background: transparent;
	}
	.tableTopMain .plusIcon span {
		font-size: 25px;
	}

	.tableTopMain .plusIcon{
		width: 27px;
		height: 27px;
	}
	.tableTopMain .tableTopRight {
		align-items: center;
	}
	.tableTopMain .totalIncome{
		margin-right: 10px;
	}
	.dateWrap input{
		font-size: 18px;
	}

	.txtTo{
		font-size: 18px;
	}

	.filterA{
		font-size: 18px;
	}

	.rightPanelWidhtSidebar .noData  {
		margin-top: 100px;
	}
	.rightPanelWidhtSidebar .noData strong {
		font-size: 20px;
	}
	.rightPanelWidhtSidebar .noData p {
		font-size: 16px;
		text-align: center;
	}

	.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
		width: 12% !important;
		font-size: 17px !important;
	}

	.react-datepicker__month-container {
		width: 100%;
	}

	.react-datepicker-popper{
		padding-top: 10px;
		width: 100%;
		transform: translate3d(0px, 32px, 0px) !important;
	}

	.react-datepicker {
		width: 100%;
	}

	.responsiveTalbe.expenseTableTitle td.tac.tdAction .ViewIcon {
		margin-left: 0px;
	}

	.incomeListError .positionAbsoute {
		left: 0px;
		top: 35px;
	}
	.ViewExpenseMain .dependentBorderBox {
		margin-top: 40px;
	}
	.expenseListError .positionAbsoute {
		left: 0px;
		top: 35px;
	}

	.incomeBoxcol .incomeTableTitle{
		margin-top: 50px;
	}

	.viewIncomeMain .dependentBorderBox {
		margin-top: 35px;
	}
	.addIncomeTitleError .positionAbsoute {
		left: 0px;
		top: 30px;
	}

	.addExpenseTitleError .positionAbsoute {
		left: 0px;
		top: 25px;
	}

	.activityListError .positionAbsoute {
		left: 0px;
		top: 35px;
	}

	/***************************************Income and expense screen 600 end**************************************************************************************************************************************************************************************************************/

	/***************************************activity screen 600 start **************************************************************************************************************************************************************************************************************/

	.noActivityMain svg{
		width:145px;
		height:118px;
		margin-top: 30px;
	}

	.noActivityMain .expensesWrap h3 {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	}

	.noActivityMain .bulletUl {
		margin-left: 0px;
		max-width: 278px;
		margin: 0px auto;
	}
	.noActivityMain .bulletUl li{
		padding-left: 15px;
		font-size: 16px;
		line-height: 20px;
		position: relative;
	}
	.noActivityMain .bulletUl svg{
		position: absolute;
		left: -17px;
		top:0px;
		margin-top:15px
	}
	.noActivityMainTitle{
		margin: 10px 0px;
	}

	.tagList .tag:before, .tagList .tag input + span{
		width: 22px;
		height: 22px;
	}
	.responsiveTalbe.activityTableTitle td:nth-of-type(1):before { content: ""; display: none }
	.responsiveTalbe.activityTableTitle td:nth-of-type(2):before { content: "DATE"; }
	.responsiveTalbe.activityTableTitle td:nth-of-type(3):before { content: "TYPE"; visibility: visible }
	.responsiveTalbe.activityTableTitle td:nth-of-type(4):before { content: "DESCRIPTION"; visibility: visible }
	.responsiveTalbe.activityTableTitle td:nth-of-type(5):before { content: "DURATION"; }

	.responsiveTalbe.activityTableTitle td:nth-child(1){
		position: absolute;
		left: 0px;
		top:0px;
		width: 3px;
		height: 100%;
		padding: 0px;
	}
	.responsiveTalbe.activityTableTitle td:nth-child(1) i {
		width: 3px !important;
		height: 100% !important;
		margin:0px !important;
		border-radius: 0px !important;
	}
	.activityBoxCol{
		padding: 5px;
		float: left;
		width: 100%;
	}

	h2.titleWithSidebar.activityTitleMargin {
		margin: 15px 0px 15px 10px;
	}
	h2.titleWithSidebar.activityTitleMargin span{
		position: absolute;
	}
	.activityBoxCol .lblTotal{
		font-size: 16px;
		line-height: 24px;
	}

	.activityBoxCol .dateWrap {
		position: absolute;
		left: 0px;
		top: 83px;
		z-index: 10;
		background: rgba(52, 160, 164, 0.04);
		padding: 8px 5px;
		width: 100%;
	}

	.activityBoxCol span.a12-icon {
		display: none;
	}

	.activityTableTitle{
		margin-top: 95px;
	}

	.activityListScreenMain .activityBoxCol .lblTotal{
		background-color: transparent;
	}

	.activityBoxCol .lblTotal {
		margin-right: 10px;
	}

	.activityBorderBox600 .tagList .tag:before, .activityBorderBox600 .tagList .tag input + span {
		width: 28px;
		height: 28px;
		top:-3px;
		left:-3px;
	}

	.activityBorderBox600 .tag {
		width: 22px !important;
		height: 22px !important;
		margin-right: 13px !important;
	}

	.activityBorderBox600 .addExpWrap {
		padding: 5px;
	}
	.activityBorderBox600 .addExpWrap span {
		font-size: 16px;
	}

	.activityBorderBox600 .expMid {
		max-width: 215px;
	}

	.activityBorderBox600 .expMid h5 {
		font-size: 14px;
		line-height: 20px;
	}

	.activityBorderBox600 .expList .expIco svg{
		width: 15px !important;
		height: 15px !important;
	}

	.activityBorderBox600 .expAction span + span > svg {
		width: 11px !important;
		height: 11px !important;
	}

	.activityBorderBox600 .expAction.mla.df.aic {
		align-items: flex-start;
	}

	.activityBorderBox600 .expInfo span {
		font-size: 12px;
		line-height: 24px;
	}
	.activityBorderBox600 .exp h5 {
		margin-bottom: 3px;
	}
	.expAction span svg {
		width: 13px !important;
		height: 13px !important;
	}

	.expAction span + span {
		margin-left: 15px;
	}


	.activityButonAction{
		display: flex;
		flex-direction: column;
	}

	.ViewIcon {
		order: -1;
		margin:0px 0px 12px 0px;
	}

	.responsiveTalbe.activityTableTitle td.tac.tdAction {
		top:10px;
	}

	.expenseDetails label {
		width: 100%;
	}

	.activityDetails.expenseDetails > div {
		flex-direction: column;
	}
	.activityDetails.expenseDetails label {
		font-size: 12px;
		line-height: 24px;
	}

	.activityDetails.expenseDetails > div > div {
		font-size: 16px;
		line-height: 24px;
	}

	.activityDetails.expenseDetails .tag {
		width:19px !important;
		height:19px !important;
	}

	.activityButonAction span {
		margin-right: 0px;
	}

	.activityDetails.expenseDetails > div {
		padding: 7px 0px;
	}

	.dateWrap input {
		width: 108px;
	}

	.titleWithSidebar span svg {
		display: none;
	}

	.dateWrap>div.filterA {
		padding-left: 3px;
	}

	.activityDetails.expenseDetails .addExpWrap span {
		font-size: 16px;
	}

	.activityDetails.expenseDetails .expMid {
		max-width: 215px;
	}

	.activityDetails.expenseDetails .expMid h5 {
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 5px;
	}
	.activityDetails.expenseDetails .expInfo span {
		font-size: 12px;
		line-height: 24px;
	}

	.activityDetails.expenseDetails .expList .exp svg{
		width: 15px !important;
		height:  15px !important;
	}

	.titleMarginBottomViewScreen {
		margin-bottom: 15px;
	}
	/*h4.dependentSubTitle {*/
	/*	width: 240px;*/
	/*	float: left;*/
	/*}*/
	.activityDetails.expenseDetails .expIco {margin: 2px 10px 0 0;}



	.formRow .activityType__input{
		opacity:0 !important;
		height: 20px;
	}
	.activityType__placeholder {
		margin-left: 0px;
	}
	.formRow .activityType__indicator.activityType__dropdown-indicator{
		padding:0px;
	}
	.formRow .activityType__input-container{
		padding-top:0px;
		margin-bottom:0px;
		padding-bottom:0px;
	}
	.formRow .activityType__value-container{
		align-items: flex-end;
	}
	.formRow .activityType__control {
		align-items: flex-end;
		min-height: 26px;
	}
	.formRow .activityType__single-value{
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 18px;
		letter-spacing: 0.25px;
	}
	.viewActivityTitleError .positionAbsoute {
		top:27px;
	}
	.activityViewMain {
		margin-top: 35px;
	}
	/***************************************activity screen 600 end **************************************************************************************************************************************************************************************************************/


	/*******/


	.noExpenseTitle{margin:10px 0px !important;}
	.noExpenseMainBox .dependentDefaultCenterBox svg {
		max-width: 178px;
		max-height: 122px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.noExpenseMainBox .dependentBorderBox{
		border-width:0px;
		padding:0px;
	}
	.noExpenseMainBox h2.titleWithSidebar span{
		top:-94px;
	}
	.stayOnTop_Text600{
		margin-top:0px;
	}
	.expenseListScreenMain h2.titleWithSidebar span {
		position: absolute;
	}
	.addExpensesWrap .expenseBorderBox600 .marginBottom10{
		margin-bottom: 0px;
	}
	.deleteIcon{
		display: none;
	}
	.ViewIcon{
		display: inline-block;
	}
	.ViewIcon img{
		max-width: 29px;
	}

	.noDocumentMain .expensesWrap h3 {
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.15px;
		margin-bottom: 10px;
	}
	.noDocumentMain .bulletUl {
		margin: 0px auto;
	}

	.noDocumentMain .bulletUl li{
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.4px;
		color: #71767A;
		position: relative;
		padding-left: 55px;
		padding-right: 40px;
	}
	.noDocumentMain .bulletUl li svg {
		position: absolute;
		left: 20px;
		top: 15px;
	}

	.noDocumentMain .docSVG_icon svg {
		width: 152px;
		height: 133px;
		margin-top: 35px;
	}

	.noDocumentTitle, .documentTitle {
		margin: 10px 0px !important;
	}

	.documentTitleName {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.15px;
		margin-top: 8px;
	}
	.addIconDocument{
		width: 34px;
		height: 34px;
		position: absolute;
		right: 15px;
		top: 15px;
		cursor: pointer;
		display: inline-block;
	}
	.addIconDocument .plusIcon{
		border-radius:50% !important;
	}
	.documentTopRow {
		width: 230px;
		position: absolute;
		background: #fff;
		right: 11px;
		z-index: 100;
		border-radius: 10px;
	}
	.documentTopRow {
		display: none;
	}
	.documentTopRow.documentTopRowShow{
		display: block;
	}
	.documentTopRow ul li:nth-child(2) label{
		display: block;
		position: relative;
		z-index: 40;
	}

	.addIconDocument .btn-primary.saveButton:hover {
		background:#0096c7fc;
	}
	.documentTopRow ul li {
		display: block;
		width:100%;
		float: left;
		position: relative;
		margin:0px;
		padding-left: 15px;
	}
	.documentTopRow ul li:nth-child(2) span {
		margin-left: 4px;
	}
	.documentTopRow ul li:before {
		content: 'a';
		position: absolute;
		left: 65px;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #565C65;
	}

	.documentTopRow ul li:nth-child(1):before {
		content: 'Create Folder';
	}

	.documentTopRow ul li:nth-child(2):before {
		content: 'Upload File';
	}

	.documentListCol {
		width: 100%;
		display: flex;
		flex-direction: row;
		height:auto;
		margin:0px;
		position: relative;
		align-items: center;
	}
	.documentPopUp .cancelIcon{
		display: none;
	}
	.docNavIcon {
		display: inline-block;
	}

	.documentPopUp .popUpMainBg{
		padding: 0px;
	}
	.documentPopUp .areYouText {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.15px;
		color: #3D4551;
		text-align: left;
		padding: 13px 20px;
		border-bottom: 1px solid #DFE1E2;
		margin-bottom: 50px;
	}

	.titleWithSidebar .documentTitle span svg {
		display: inline-block;
		margin: 3px 5px 0px 0px;
		float: left;
	}

	.documentListRow .documentListCol.selectedFileFolder {
		background: transparent;
		border-radius: 0px;
		border-color: transparent;
	}

	.documentPopUp .documentFolder {
		max-width: 290px;
		margin: 0px auto;
	}

	.documentPopUp .documentFolder .inputColum{
		border: 1px solid #A9AEB1;
		border-radius: 4px;
		height: 41px;
		padding: 0px 10px;
	}

	.documentPopUp .cancelButtonCol, .documentPopUp .createButtonCol {
		width: 50%;
		margin: 0px;
		text-align: center;
	}

	.documentPopUp .cancelButtonCol {
		border-right:1px solid #E3E3E3;
	}

	.documentPopUp .cancelButtonCol input, .documentPopUp .createButtonCol input {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.4px;
		color: #8D9297;
		border: 0px;
		background: transparent;
		box-shadow: none;
	}

	.documentPopUpButtonRow {
		margin-bottom: 30px;
	}

	.documentPopUp .popUpTitle {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.15px;
		color: #3D4551;
		text-align: left;
		padding: 13px 20px;
		border-bottom: 1px solid #DFE1E2;
		margin-bottom: 10px;
		position: relative;
		padding-right: 50px;
		text-overflow: ellipsis;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
	}

	.documentListRow {
		overflow-x: inherit;
	}
	.documentPopUpActionList ul {
		padding-bottom: 10px;
	}

	.documentPopUpActionList ul li{
		padding: 13px 30px;
		position: relative;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #565C65;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.popUpCloseIcon {
		position: absolute;
		right: 15px;
	}

	.documentPopUpActionList ul li svg {
			margin-right: 30px;
	}

	.documentPopUpActionList ul li:last-child:before {
		display: none;
	}
	.documentPopUpActionList ul li:before {
		content: '';
		width: 82%;
		height: 1px;
		position: absolute;
		background: #F7F9FA;
		right: 0px;
		bottom: 0px;
	}

	.documentPopUp .createButtonCol input {
		color:#34A0A4;
	}

	.documentListCol.imgCol img {
		height: auto;
		width: 38px;
	}

	.docNavIcon {
		position: absolute;
		right: 10px;
		cursor: pointer;
		padding: 10px;
	}

	.documentbreadcrum {
		text-overflow: ellipsis;
		width: 88px;
		white-space: nowrap;
		overflow: hidden;
		display: inline-block;
	}

	.documentListCol .dateTime {
		text-align: left;
	}

	.documentListRow {
		height:inherit;
		margin-top: 43px;
	}
	.documentListRow .documentListCol:hover{
		background: transparent;
	}

	body .documentBottomRow {
		display: none;
	}
	.documentTitle .txtEllipsis {
		max-width: 113px;
	}
	.overLayLayer {
		content: '';
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0px;
		top: 0px;
		z-index: 100;
		background: rgba(0,0,0,0.2);
	}

	.folderImgIcon {
		margin-right: 15px;
	}

	.inputfolderName textarea {
		display: none;
	}

	.inputfolderName p {
		display: block;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #3D4551;
		text-overflow: ellipsis;
		width: 205px;
		white-space: nowrap;
		overflow: hidden;
		text-align: left;
	}

	.documentListRow {
		background:#fff;
	}
	.documentTopRow ul li:nth-child(1) {
		border-bottom: 1px solid #F7F9FA;
	}

	.documentTopRow li:nth-child(3), .documentTopRow li:nth-child(4){
		display: none;
	}
	/*******/





}

	/************/
@media screen and (max-width: 480px) {
}

@media screen and (max-width: 360px) {
}

/***************************************************************************************************************************************vv*****************************************/

.dropZoneWrap {
	border: 1px dashed #C6CACE;
	border-radius: 8px;
	background-color: #FFFFFF;
}
.dropZoneWrap:hover, .dropZoneWrap:focus{
	border: 1px dashed rgba(45, 108, 178, 0.99);
}
.dropZoneWrap.hovered{
	border: 2px solid #81cbe3;
	background-color: #fafdfe;
}
.dropZoneWrap.dragAactive{
	background: rgba(45, 108, 178, 0.02);
	border: 1px solid rgba(45, 108, 178, 0.99);
}
.btn-primary.saveButton:hover{
	background: rgba(0, 150, 199, 0.67);
}
.btn-outline.saveButton:hover{
	background: #fff;
}
.myAcHeight .btn-outline.saveButton{
	border: 1px solid #058DBA;
	color: #058DBA;
}
.uploadFileName {
	font-weight: 600;
	font-size: 18px;
	line-height: 1.1;
	color: #3D4551;
}
.deleteIconColor{
	color: #F2938C;
}
/*-------------- FlexBox --------------*/
.dn {
	display: none !important;
}
.df {
	display: flex;
}
.cb{
	clear: both;
}
.fcol {
	flex-direction: column;
}
.mla {
	margin-left: auto;
}
.aic {
	align-items: center;
}
.jcc {
	justify-content: center;
}
.jc-around {
	justify-content: space-around;
}
.jc-btw {
	justify-content: space-between;
}
.jc-even {
	justify-content: space-evenly;
}
.ai-fe{
	align-items: flex-end;
}
.tac {
	text-align: center !important;
}
.p0 {
	padding: 0 !important;
}
.m0 {
	margin: 0 !important;
}
.fl{
	float: left;
}
.fr{
	float: right;
}
/*-------------- /FlexBox --------------*/

/*-------------------Progressbar-----------------*/
.dropZoneActive{
	background: rgba(45, 108, 178, 0.02);
	border: 1px solid rgba(45, 108, 178, 0.99);
}

.dropzone {
	padding: 10px;
	border-radius: 8px;
	min-height: 85px;
	color: #71767a;
	cursor: pointer;
}
/*.dropzone:hover {*/
	/*background-color: #fafafa;*/
/*}*/
.selected-file {
	padding: 3px ​8p;
}
	/*-------------------Progressbar-----------------*/
	.dropzone {
		padding: 10px;
		border-radius: 8px;
		min-height: 85px;
		color: #71767a;
		cursor: pointer;
	}
	/*.dropzone:hover {*/
	/*background-color: #fafafa;*/
	/*}*/
	.selected-file {
		padding: 3px ​8p;
	}
	.fileList {
		padding: 20px;
	}

.fileList > div + div {
	margin-top: 10px;
	padding-top: 10px;
	border-top: 1px dashed #eee;
}
.fileProgress {
	padding: 10px 20px;
}
.progressWrap{
	width: calc(100% - 75px);
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.progress {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	height: 4px;
	font-size: 0.75rem;
	background-color: #dfe1e2;
	border-radius: 0.25rem;
	margin-top: 8px;
    width: 90%;
}
.progress-bar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 0.25rem;
	overflow: hidden;
	color: #fff;
	text-align: center;
	background-color: #2D6CB2;
	transition: width 0.6s ease;
	margin-right: -10px;
}
.progressAmt{
	position: absolute;
	top: -18px;
	right: -10px;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
}
.bg-info {
	background-color: #17a2b8 !important;
}
.alert {
	position: relative;
	padding: 0.5rem 0.75em;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	line-height: 1.5;
}
.alert-danger {
	color: #0356a0;
	background-color: #e6f1fb;
	border-color: #93c7f5;
}
/*-------------------/Progressbar-----------------*/
/*-------------- Expenses --------------*/

.expensesWrap h3,
.addExpensesWrap h3,
.incomesWrap h3,
.addIncomesWrap h3,
.addActivityWrap h3 {
	font-family: "Public Sans", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 44px;
	color: #0466c8;
}
.incomesWrap .button {
	max-width: 388px;
}
.bulletUl {
	margin-left: 95px;
}

.bulletUl li {
	font-family: "Public Sans", sans-serif;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.4px;
	padding: 10px 0;
	color: #71767a;
}

.bulletUl svg {
	width: 19px;
	height: 14px;
	display: inline-block;
	margin-right: 10px;
}

.formRow {
	padding-right: 0;
}

.formRow label {
	padding-left: 0;
	top: 5px;
}
.paidTo label {
	padding-left:90px;
}
.amountWrap {
	position: relative;
}
.amountWrap .currency {
	position: absolute;
	top: 2px;
	left: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 19px;
	color: #c6cace;
}
.css-b62m3t-container {
	padding-bottom: 0 !important;
}
.txtAmount {
	padding-left: 16px;
}
.payRow {
	padding-left: 0 !important;
}

.dependentBorderBox .css-1s2u09g-control {
	background-color: transparent;
}
.borderBottom1{
	border:1px solid white !important;
	border-bottom-color:#c4c4c4 !important;
}
.borderBottom1 .css-1s2u09g-control{
	border-radius: 0 !important;
	border: 0 !important;
}
.addExpensesWrap h3,
.addIncomesWrap h3 {
	font-size: 24px;
	line-height: 24px;
}
.txtNote {
	border-radius: 4px;
	padding: 10px;
}
.uploadWrap {
	padding-left: 0;
}
.uploadWrap label {
	position: relative !important;
}
.css-1pahdxg-control {
	border: 0 none !important;
	box-shadow: none !important;
	min-height: unset;
}
label.lblExpense {
	padding: 0 0 10px;
	display: block;
}
.attachTxt {
	font-size: 20px;
	color: #0096c7fc;
	text-transform: none;
}

/*.attachTxt span {*/
/*	display: inline-block;*/
/*	vertical-align: top;*/
/*}*/

.dragTxt {
	font-size: 14px;
	color: #71767a;
	text-transform: none;
}
div.css-319lph-ValueContainer {
	padding: 2px 0 !important;
}
div.css-14el2xx-placeholder {
	color: hsl(0, 0%, 80%);
}
.inputDate {
	width: calc(50% - 105px) !important;
}
.txtCapture {
	padding-top: 5px;
	font-size: 16px;
}
label.lblExpType {
	top: -3px;
}
.dateWrap input {
	border: 0 none;
	background-color: transparent;
	color: #b2b4b7;
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	width: 100px;
}
.incExpType {
	cursor: pointer;
}
.dateWrap > div {
	padding-left: 10px;
}
.txtTo {
	font-size: 20px;
	line-height: 20px;
	color: #3d4551;
}
.filterA {
	color: #34a0a4;
	cursor: pointer;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	border: 1px solid rgba(0, 150, 199, 0.99);
	box-sizing: border-box;
	border-radius: 4px;
}
.btn-md{
	height: 33px;
	font-size: 20px;
	min-width: auto;
	padding: 0 10px;
}
.btn-primary.saveButton {
	background-color: #0096c7fc;
}
.btn-outline.saveButton {
	background: white !important;
	border: 1px solid #0096c7fc;
	color: #0096c7fc;
}
.lblTotal {
	padding: 10px;
	background: rgba(216, 57, 51, 0.04);
	border-radius: 4px;
	color: #757477;
	font-size: 20px;
}
.totalIncome {
	background-color: rgba(52, 160, 164, 0.04);
}
.totalExpense {
	background-color: rgba(216, 57, 51, 0.04);
}
.redText {
	color: #d83933 !important;
}
.greenText {
	color: #00a91c !important;
}
.blueText {
	color: #0096C7FC !important;
}
.tableBody th,
.tableBody td {
	padding: 12px;
}
.tableBody th {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;
	background-color: rgba(0, 80, 157, 0.05);
	text-align: left;
	color:#71737A;
}
.tableBody th:hover span.h100 {
	background-color: #eee;
}

.ellipsis {
	position: relative;
}
.ellipsis:before {
	content: '&nbsp;';
	visibility: hidden;
}
.ellipsis span {
	position: absolute;
	left: 10px;
	right: 0;
	top: 12px;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.dateCol{
	width: 165px;
}
.typeCol{
	width: 36%;
}
.activityTableTitle .typeCol{
	width: 30%;
}
.incTypeCol{
	width: 55%;
}
.tagCol{
	width: 60px;
}
.amtCol{
	width: 120px;
}
.actCol{
	width: 89px;
}
.durCol{
	width: 90px;
}

.transferTable .statusCol{
	width: 70px;
}
.transferTable .statusCol i{
	margin: -7px -19px 0 10px;
}

.assetTable .actCol{
	width: 60px;
}
.assetTable span.blueText.cursorPointer{
	margin: 3px auto -5px;
}

.expensseTypeCol{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	max-width: 100%;
}
.tableBody td {
	font-size: 24px;
	line-height: 24px;
	color: #3d4551;
}
.tableBody tr:nth-child(even) {
	background: rgba(0, 80, 157, 0.02);
}
.tdAction span {
	cursor: pointer;
}
.expenseDetails > div {
	padding: 10px 0;
}
.expenseDetails label {
	width: 222px;
	font-weight: 600;
	font-size: 16px;
	line-height: 36px;
	color: #a9aeb1;
	flex-shrink: 0;
}
.expenseDetails > div > div {
	font-size: 24px;
	line-height: 36px;
	color: #2d2e2f;
}

.expenseDetails .tag span{
	background-size: auto !important;
}

.fileType {
	width: 65px;
}
.fileInfo {
	width: 340px;
	margin: 0 10px 0 0;
	display: flex;
    flex-direction: column;
    justify-content: center;
}
	.expensseTypeCol{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		max-width: 100%;
	}
	.tableBody td {
		font-size: 24px;
		line-height: 24px;
		color: #3d4551;
	}
	.tableBody tr:nth-child(even) {
		background: rgba(0, 80, 157, 0.02);
	}
	.tdAction span {
		cursor: pointer;
	}
	.expenseDetails > div {
		padding: 10px 0;
	}
	.expenseDetails label {
		width: 222px;
		font-weight: 600;
		font-size: 16px;
		line-height: 36px;
		color: #a9aeb1;
		flex-shrink: 0;
	}
	.expenseDetails > div > div {
		font-size: 24px;
		line-height: 36px;
		color: #2d2e2f;
	}

	.fileInfo span {
		font-size: 14px;
		line-height: 1.5;
		color: #565C65;
	}
	.fileAction {
		width: 40px;
	}
	.fileDot {
		line-height: 15px !important;
	}
	.file + .file {
		margin-top: 20px;
		padding-top: 20px;
	}
	.removeFile {
		font-weight: bold;
	}
	.arrow {
		border: solid #777;
		border-width: 0 3px 3px 0;
		display: inline-block;
		padding: 3px;
	}
	.down {
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}
	.filterBy,
	.h100 {
		height: 100%;
	}
	.filterBy i {
		opacity:0;
		margin: -7px -15px 0 10px;
	}
.fileInfo h5 {
	font-weight: 600;
	font-size: 18px;
	line-height: 1;
	color: #3d4551;
	margin-bottom: 0;
}
.fileInfo span {
	font-size: 14px;
	line-height: 1.5;
	color: #565C65;
}
.fileAction {
	width: 40px;
}
.fileDot {
	line-height: 15px !important;
}
.file + .file {
	margin-top: 20px;
	padding-top: 20px;
}
.removeFile {
	font-weight: bold;
}
.arrow {
	border: solid #777;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
}
.down {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}
.filterBy,
.h100 {
	height: 100%;
}
.filterBy i {
	display: none;
	margin-left: 10px;
	margin-top: -7px;
}

.filterBy:hover i, .filterBy.active i, .descending i, .ascending i {
	opacity: 1
}
.filterBy.active i, .descending i, .ascending i {
	border-color: #34a0a4;
}
.filterBy.active, .filterBy.descending, .filterBy.ascending {
	color: #34a0a4;
}
.dec .down,  .descending i{
	transform: rotate(225deg);
	-webkit-transform: rotate(225deg);
	margin-top: 0;
}
.filterBy:hover i, .filterBy.active i, .descending i, .ascending i {
	display: inline-block;
}
.filterBy.active i {
	border-color: #34a0a4;
}
.filterBy.active {
	color: #34a0a4;
}

.css-qc6sy-singleValue{
	overflow: visible !important;
}

.totalActivity {
	color: #333;
}

.activityBoxCol .lblTotal{
	background-color: rgba(52, 160, 164, 0.04);
}

.tag, .tag span{
	width: 30px !important;
	height: 30px !important;
	border-radius: 4px;
	margin-right: 3px;
	display: inline-block !important;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: transparent;
}

/* untagged
.tag-0{
	background: #EEE;
}*/
.tag-1 span{ /* blue */
	background-image: url("../images/activityCircle.svg");
}
.tag-2 span{ /* green */
	background-image: url("../images/tag2.svg");
}
.tag-3 span{ /* yellow */
	background-image: url("../images/tag3.svg");
}
.tag-4 span{ /* red */
	background-image: url("../images/tag4.svg");
}
.tagList .tag{
	position: relative;
}
.tagList .tag span{
	display: inline-block;
	position: absolute;
	left: 0;
	top: 0;
	border: 1px solid transparent;
	-web-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
.tagList .tag input{
	visibility: hidden;
}

.tagList .tag:focus span{
	border: 1px dotted rgb(85 154 177);
}
.tagList .tag span:hover{
	background-color: #FAF8F4;
	border: 1px dotted rgb(85 154 177);
}
.tagList .tag input:checked + span{
	background-color: #FAF8F4;
	border: 1px solid #2D6CB2 !important;
}

/* .tagList .tag input:checked + .tag:after{
	border-color: red;
} */
.tableBody i.tag{
	width: 25px !important;
	height: 25px !important;
}

.exp{
	padding: 10px 17px;
	border: 1px solid rgba(4, 102, 200, 0.32);
	border-radius: 8px;
}
.exp + .exp{
	margin-top: 15px;
}
.exp h5 {
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	color: #0466C8;
	margin: 0 0 10px;
}
.expInfo span {
	font-size: 18px;
	line-height: 1;
	color: #71767A;
}

span.dot {
	margin: 0 10px;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #71767A;
}

.expIco{
	margin: 5px 15px 0 0;
}

.addExpWrap{
	padding: 20px;
}
.addExpWrap span{
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #0096C7FC;
}
.addActivityWrap h3{
	font-size: 24px;
	line-height: 24px;
}

.durationInput{
	width: 49px;
}
.colon{
	width: 10px;
    font-size: 26px;
    position: relative;
    top: -2px;
}

/* Duration */
/* .durationWrap{
	position: absolute;
	top: 100%;
	z-index: 1;
	margin-top: 2px;
	background-color: #FFF;
	width: 324px;
	height: 240px;
	box-shadow: 8px 22px 53px 14px rgba(0, 0, 0, 0.04);
	border-radius: 8px;
	padding: 40px 0 10px;
}

div.durAct{
	padding: 20px;
	margin-top: 15px;
}
div.durAct div{
	text-align: center;
}
div.durAct div:first-child{
	border-right: 1px solid #EDEFF0;
	width: 55%;
}
div.durAct div:nth-child(2){
	width: 40%;
}

div.durAct button{
	background-color: transparent;
	border: 0 none;
	font-weight: 500;
	font-size: 20px;
}
.btn-cancel{
	color: #8D9297;
}
.btn-ok{
	color: #34A0A4;
} */
.noData{
	width: 94%;
    height: calc(100% - 250px);
    position: absolute;
    /* padding-top: 52px; */
}

.noData strong{
	font-weight: 600;
	font-size: 32px;
	line-height: 44px;
	color: #0466C8;
}
.noData p{
	font-size: 24px;
	line-height: 27px;
	letter-spacing: 0.4px;
	color: #71767A;
}

tr > .noData{
	padding-top: 0;
    height: calc(100% - 350px);
}

.durationWrap .rdp-picker .rdp-column-container:nth-child(2) .rdp-cell{
	display: none !important;
}
.durationWrap .rdp-picker .rdp-column-container:nth-child(2) .rdp-cell:nth-child(15n + 1){
	display: flex !important;
}

.amountWrap .currency.currencyColorChnage {
	color:#2d2e2f;
}

.navDisableText {
	color: #8D9297;
}
.txtEllipsis{
	text-overflow: ellipsis;
    max-width: 15em;
    white-space: nowrap;
    overflow: hidden;
}
.vam{
	display: inline-block;
    vertical-align: middle;
}
.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown{
	display: none !important;
}
.react-datepicker__triangle{
	transform: translate(40px, 0px) !important;
}
.picLoader{
	font-size: 22px;
	width: 100%;
	height: 105%;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dependentLoader {
	border-radius: 8px;
	display: flex;
	width: 100%;
	height: auto;
	position: absolute;
	left: 0px;
	top: 0;
	text-align: center;
	align-items: center;
	justify-content: center;
	z-index: 50;
}

@media screen and (min-width:769px) {
	.inventoryBox .dependentLoader{
		top: 10px;
	}
}

.dependentLoaderCenterCol {
	width: 142px;
	height: 102px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

/* Inventory */

.invNav{
	margin-top: -4px;
	margin-bottom: -5px;
}
.MuiTabPanel-root{
	padding: 0 !important;
}
.MuiTabs-root{
	border: 1px solid #F1F3F6;
	background-color: #FCFDFE;
}
button.MuiTab-root{
	text-transform: none !important;
}
.MuiTabs-flexContainer button{
	max-width: unset;
	width: 33.33%;
}
.MuiTabs-flexContainer button span{
	font-size: 20px;
	color: #8D9297;
}

.MuiTabs-flexContainer button.Mui-selected span{
	color: #0096C7FC
}
.MuiTabs-scroller .MuiTabs-indicator{
	background-color: #0096C7 !important;
	height: 3px;
}
.assetTable th{
	vertical-align: middle;
}
.assetTable .actCol{
	width: 50px;
}
.transfers .formRowGap{
	margin: 10px 0px;
}
.transfers .areYouText{
	color: #0466C8;
}
div.assetType__control{
	border-radius: 0;
	border: 0 none;
}

.MuiSwitch-colorPrimary.Mui-checked{
	color: white !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
	background-color: #2D6CB2 !important;
	opacity: 1 !important;
}
span.PrivateSwitchBase-root-5{
	padding: 0;
}
span.MuiSwitch-root{
	width: 36px;
	height: 20px;
	border-radius: 20px;
	padding: 0;
	margin: 0 7px 0 10px;
}
span.MuiSwitch-switchBase{
	top: 1px;
	left: 1px;
}
span.MuiSwitch-switchBase.Mui-checked{
	transform: translateX(16px);
}
span.MuiSwitch-thumb{
	width: 16px;
    height: 16px;
}
.dtCoRow{
	margin-bottom: 20px !important;
}
.lblDtCO {
	max-width: 170px;
}
span.MuiIconButton-root {
	padding: 1px !important;
}
.marginTop5.df.aic{
	margin-top: 0 !important;
}
.right35{
	right: 35px;
}
.static{
	position: static !important;
}
.radioAsset{
	padding-top: 5px;
}
.radioAsset label{
	text-transform: none !important;
	color: #3D4551;
	font-size: 24px;
	font-weight: normal;
}
.head36{
	font-weight: 600;
	font-size: 36px;
	line-height: 48px;
	letter-spacing: 0.4px;
	color: #3D4551;
}
.accountType__control{
	border:1px solid transparent !important;
}
div.isSetUp{
	padding-left: 340px;
}
div.hideNote{
	padding-left: 260px;
}
.btnAddEditWrap{
	width: 48px;
    position: absolute;
	right: 35px;
    top: 27px;
	z-index: 1;
    cursor: pointer;
}
.height0{
	display: none !important;
	overflow: hidden;
	margin-top: -5px;
	float: left;
	width: 100%;
	/* max-height: 0;
	opacity: 0;
	-webkit-transition: max-height 1s ease-in-out;
	-moz-transition: max-height 1s ease-in-out;
	-ms-transition: max-height 1s ease-in-out;
	-o-transition: max-height 1s ease-in-out;
	transition: max-height 1s ease-in-out; */
}
.heightAuto{
	display: block !important;
	padding: 3px 30px 3px 35px;
}

.polLoading{
	z-index: 2;
}
.personList{
	width: calc(100% - 20px);
	background: #FAF8F4;
	border-radius: 4px;
}
.personList + .personList::before{
	content: '';
	height: 1px;
	width: calc(100% - 70px);
	left: 70px;
	background: #EDEFF0;
	position: absolute;
	top: 0;
}

.person .positionAbsouteOnly{
	left: 0;
}
.person {
	padding: 20px 30px 15px 70px;
}
.pName{
	width: 300px;
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;
	color: #3D4551;
}

.pEmail{
	width: 350px;
	color: #2D6CB2;
}
.text24{
	font-size: 24px;
}
.text20{
	font-size: 20px !important;
}

.pAddress{
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 0.4px;
	color: #3D4551;
}

.personInfo + .personInfo{
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid #EDEFF0;
}
#Policy.polLoading{
	min-height: 350px;
}

.loaderPol{
	left: 48%;
}

#Provider .loaderPol{
	left: 0;
	z-index: 1;
}

.accessRowlg{
	padding-left: 530px;
}
.yesNo{
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #1C1D1F;
}
.queWrap{
	padding-top: 60px;
}
.title24{
	color: #3D4551;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
}
.lblInsType{
	width: 170px;
	display: inline-block;
}
.insStep3 div.formRowGap{
	margin-top: 27px;
	margin-bottom: 18px;
}

.accountType__control{
	padding: 0 !important;
	min-height: 28px !important;
}
div.accountType__value-container{
	padding: 0 !important;
}
.accountType__indicator{
	padding: 4px !important;
}
.accountType__input-container{
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	margin: 0 2px !important;
}
.assetOwn label{
	color: #333;
	text-transform: none;
}
.coOwn label{
	white-space: nowrap;
}
.accountType__placeholder {
	margin-left: 0;
}
.accountSecureTxt {
	-webkit-text-security: disc;
}
.billingBox {
	padding: 40px;
}
.billingBox .borderBottom::before{
	display: none;
}
.billingBox label{
	top: 0;
}
iframe body .ElementsApp, .ElementsApp .InputElement{
	font-size: 24px;
}
.chkBox{
	position: relative;
}

.chkBox input {
	visibility: hidden;
	width: 20px;
	height: 20px;
}

.chkBox span:before, .chkBox span:after {
	content: "";
	display: inline-block;
	box-sizing: border-box;
	border-radius: 2px;
	position: absolute;
}

.chkBox span:before{
	width: 22px;
	height: 22px;
	left: 0;
	top: 0;
	border: 1px solid #71767A;
	border-radius: 2px;
}
.chkBox span:hover:before{
	border: 1px solid #0096C7;
}

.chkBox [type=checkbox]:checked+span:after {
	height: 12px;
	width: 7px;
	border-bottom: 3px solid #FFF;
	border-right: 3px solid #FFF;
	left: 4px;
    top: 48%;
	transform: rotate(45deg) translate(-35%, -65%);
}

.chkBox input[type="checkbox"]:checked+span:before{
	background: #0096C7;
	border: 1px solid #0096C7;
}

.chkBox span{
	font-size: 20px;
}
.billCol1 .formRow {
	padding-left: 300px;
}
.billingCancelWrap{
	right: 35px;
}
.phoneType1__single-value{
	text-transform: capitalize;
}
.outOfRangeError{
	top: -20px;
}
.react-datepicker__navigation{
	top: 5px !important;
}
.width48WithPhone{
	width: calc(50% - 32px);
}
.inventoryDetialBox{
	clear: both;
	padding: 0 35px 35px;
}
@media screen and (min-width:769px) {
	.inventoryDetialBox{
		padding: 20px 30px 28px;
		width: calc(100% - 85px);
		background: #FAF8F4;
		margin-left: 35px;	
	}
}

.inventoryDetialBox > .inventoryDetialBox{
	padding: 0;
}
.transfers .loaderSpinner {
	margin: 0 auto !important;
}
.transfers .popUpInner{
	border: 0 none;
}
.formInner{
	max-height: 550px;
	overflow-y: auto;
	padding: 5px 20px;
}
.popUpInner{
	border: 1px solid #EBF2F9;
	border-radius: 8px;
	height: calc(100% - 36px);
}
.transfers .fullWidth.padding20{
	padding: 20px 30px 28px;
}
.transfers.popMaxWidth{
	max-width: 983px;
	border-radius: 8px;
	max-height: calc(100% - 100px);
	height: 100%;
	position: relative;
}
.multiElipsis{
	display: block;
	display: -webkit-box;
	max-width: 100%;
	max-height: 30px;
	line-height: 1;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.inputfolderName textarea{
	display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
/* .assetTransferView{
	background-color: #FAFDF9;
} */
.rejectMsg {
	background-color: lightpink;
	color: rgb(216, 57, 51);
	font-size: 24px;
	font-weight: bold;
	border-color: red !important;
}
.rejected {
	color: rgb(216, 57, 51);
	border: 1px solid red !important;
	line-height: 1.5;
	border-radius: 8px;
	padding: 10px 15px;
}
.rejected *{
	color: rgb(216, 57, 51);
}
.uc {
	text-transform: uppercase;
}
.rejected h5{
	font-size: 16px;
	font-weight: bold;
}
.rejected p, .rejected h5 .mla{
	font-size: 12px;
}
.fileDtl {
	font-size: 14px;
}
.onlyForPrint{
	display: none !important;
}

/*.bgCol {*/
/*	background: url("../images/mainBgNew.png") right top no-repeat #112E53;*/
/*	background-size: auto 100%;*/
/*	width: 100%;*/
/*	height:100vh;*/
/*}*/
/*.top.topRelativeCol:before, .top.topRelativeCol:after {*/
/*	display: none;*/
/*}*/

.inventoryBox .inventoryScreenTitle span.cursorPointer{
	display: none !important;
	vertical-align: middle;
	margin-left: 20px;
	height: 18px;
	line-height: 1;
}
.inventoryBox.opened .inventoryScreenTitle span.cursorPointer{
	display: inline-block !important;
}
.pAction svg, .pAction span, .pAction > a{
	height: 20px;
}

[class*="MenuList"] > div:hover, [class*="MenuList"] > [class*="_option"]:hover, .dependentResidencCol .dependentResidence__option:hover, .phoneTypeCol .phoneType1__menu-list div.phoneType1__option:hover {
	background: rgba(45, 108, 178, 0.08) !important;
}
[class*="MenuList"] [class*="option--is-selected"]{
	background: rgba(45, 108, 178, 0.08) !important;
}
.noDataInventory{
	background-color: #FAF8F4;
	padding: 41px;
}
@media screen and (min-width:769px) {
	.noDataInventory{
		width: calc(100% - 20px);
	}	
}

.doctorPrescribingCol .doctorPrescribing__option:last-child:hover {
	background: #eff3f9 !important;
}
.percentOwned{
	width: 67px !important;
	text-align: center !important;
}
.lblPercent{
	position: static !important;
	font-size: 22px !important;
	padding-left: 5px !important;
	color: #c6cace !important;
	font-weight: 400 !important;
}
.lblPercent.activeVal{
	color: #112E53 !important;
}
.yesNo.paddingleft16{
	padding-left: 0 !important;
}
.invNavIco svg{
	position: relative;
	left: -3px;
}
.coveragePeriod .errorMessage{
	text-align: left;
}
.medLongText::before{
	display: none;
}
.medLongText{
	height: 20px;
}

.medLongText span{
	top: 0 !important;
}
.medical table td{
	vertical-align: top;
}
.maxWidth250{
	max-width: 250px;
}

/*.coveragePeriod .errorMessage{
	text-align: left;
}*/

.documentPopUp .loaderSpinner .popUpNoButton, .documentPopUp .loaderSpinner .saveButtonDisable{width: 133px;line-height: 44px;}
.popUpAssetButtonRow .countinueButton{text-transform: uppercase;}
.nameColSpan{width: 90px;float: left;}
.medicalTableTitle .ellipsis.medLongText span{left: 0;}
.transferTable .ellipsis span{top: 27px;}
.transfers span.MuiSwitch-root{margin: 0;}
#Transfer{
	background-color: #FAFDF9;
}