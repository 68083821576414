/*body {*/
  /*margin: 0;*/
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
    /*monospace;*/
/*}*/

.login-form
{
  justify-content: center;
  min-height: 90vh;
  min-width: 200px;
}

.registration-form{
  justify-content: center;
  min-height: 90vh;
  min-width: 500px;
}

.code-form{
  justify-content: center;
  min-height: 10vh;
}

.button-block{
  width: 100%;
}
.login-background
{
  justify-content: center;
  min-height: 30vh;
  padding: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}