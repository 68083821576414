body .displayBlock {
  display: block;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
.page-title .title,
h2,
h3,
.blog-grid .post .title,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
.draw-buttons .svg,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
.order-table th,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
canvas,
main {
  display: block;
  font-family: "Work Sans", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans", sans-serif !important;
  color: #000;
}
body,
button,
input,
select,
table,
textarea,
p {
  font-family: "Work Sans", sans-serif !important;
  color: #636363;
  font-size: 14px;
}
:-moz-placeholder {
  color: #c6cace;
  opacity: 1;
  font-family: "Work Sans", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.addActivityWrap h3 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #0466c8;
}
.bulletUl li {
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding: 10px 0;
  color: #71767a;
  line-height: 27px;
}
.sideBarLeftCol {
  background: #112e53;
}
.displayNone {
  display: none;
}
.titleNameAndTabHeader:before {
  background: #112e53;
}
.rightPanelWidhtSidebarContainer,
.titleNameAndTabHeader,
.titleNameAndTabHeader:after {
  background: #f4efe7;
  border-radius: 0;
}
.dependentTabRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.activeDependentTab {
  background: #f2a950;
  color: #112e53;
  border-bottom: 0;
  position: relative;
}
.slick-track .slick-slide:nth-child(2) {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.slick-list {
  z-index: 4;
}
.slick-track .slick-slide:nth-child(3) {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.dependentTabRow .dependentTab:nth-child(3) {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
body .dependentTab {
  border-bottom: 0px;
}
.slick-next {
  right: -20px;
  display: block;
  background: #f4efe7;
  height: 60px;
}
.slick-prev {
  left: -20px;
  display: block;
  background: #f4efe7;
  height: 60px;
}
.dependentTabRow {
  border-radius: 0px;
}
.slick-next:before {
  background: url("../images/tabNextArrow.svg") 4px 0px no-repeat;
  content: "";
  width: 20px;
  height: 15px;
  display: inline-block;
}
.slick-prev:before {
  background: url("../images/tabPrevArrow.svg") 2px -3px no-repeat;
  content: "";
  width: 20px;
  height: 15px;
  display: inline-block;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  display: none;
}
.slick-prev,
.slick-next {
  font-size: 0px;
  z-index: 10;
}
.activeDependentTab:before {
  content: "";
  width: 0;
  height: 0;
  border-color: #f2a950 transparent transparent;
  bottom: -20px;
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  position: absolute;
  left: 44%;
  z-index: 1;
}
.dependentProfileMainViewBox {
  padding: 20px 30px 0;
}
.dependentProfileMainViewBox h3 {
  margin-bottom: 15px;
}
.dependentTab {
  color: #112e53;
}
.textStyle {
  color: #122e52;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
}
.geneInfo_label {
  color: #112e53;
  font-size: 14px;
  font-weight: 600;
}
.geneInfo_value {
  font-size: 22px;
  color: #3d4551;
  word-break: break-all;
}
.geneInfo_value span {
  color: #2d6cb2;
}
.titleWithSidebar {
  font-family: "DM Serif Text", serif !important;
  color: #112e53;
  font-weight: 400;
  font-size: 34px;
  line-height: 32px;
}
.dependentAddressRow {
  background: rgba(26, 65, 115, 0.02);
}
.dependentColradioButton [type="radio"] {
  border: 0;
}
.dependentAdvocateTeamUserEmailId a {
  color: #2d6cb2;
}
.dependentPendingStatus {
  background: #eeb433;
  color: #fff;
}
.dependentActiveStatus {
  color: #fff;
  background: #51ab60;
}
.sideBarNav ul li .activeNave {
  background: transparent;
}
.sideBarNav ul li .activeNave .sideBarNavLabel {
  color: #f2a950;
}
.sideBarNav ul li a.activeNave:hover,
.sideBarNav ul li a:hover {
  background: transparent;
}
.navIcon {
  height: 27px;
}
.sideBarIconOnly .navIcon svg {
  margin-top: -3px;
}
.sidebarLogoNav {
  margin-bottom: 10px;
}
body h2.leftTitle {
  font-family: "DM Serif Text", serif !important;
  color: #f2e2c4;
}
.leftDescriptions {
  font-size: 18px;
  line-height: 23px;
  color: #f2e2c4;
}
.passwordTextShow {
  top: inherit;
}
.allRightReserveText,
.allRightReserveText .signBottomLink {
  color: #f2e2c4;
  font-size: 14px;
  line-height: 20px;
}
.allRightReserveText {
  max-width: 512px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  bottom: 20px;
}
.forgotPasswordLinkText {
  color: #2d6cb2;
}
.imgLeftSignCol {
  display: flex;
  align-items: center;
  font-size: 65px;
  line-height: 64px;
}
.leftTextTitle {
  max-width: 480px;
}
.documentTopRow {
  border-top: 1px solid #dfe1e2;
  border-bottom: 1px solid #dfe1e2;
}
.documentPopUp .popUpMainBg {
  background: #f2ede5;
}
.inputColum {
  background: transparent;
  border-bottom: 1px solid #3d4551;
}
.signInFormBox {
  flex-direction: column;
}
.areYouText {
  font-size: 24px;
  line-height: 44px;
  color: #112e53;
}
.inputColum::placeholder {
  color: #c6cace !important;
  font-family: "Work Sans", sans-serif !important;
}
.inputColum24size::placeholder {
  color: #c6cace !important;
  font-family: "Work Sans", sans-serif !important;
  line-height: 30px;
}
.cancelButton1 {
  border: 1px solid #000;
  color: #000;
}
.popUpYesButton {
  text-transform: uppercase;
}
.inputfolderName textarea {
  color: #3d4551;
}
.documentListCol {
  width: 180px;
}
.documentListRow .documentListCol:hover,
.documentListRow .documentListCol.selectedFileFolder {
  background: #f2e2c4;
  border-radius: 8px;
  border-color: #f2e2c4;
}
body .expensesWrap h3,
.addExpensesWrap h3,
.incomesWrap h3,
.addIncomesWrap h3,
.addActivityWrap h3 {
  color: #204575;
  font-size: 52px;
  line-height: 50px;
  font-family: "DM Serif Text", serif !important;
}
.textTransferUppercase {
  text-transform: uppercase;
}
.noDataButton {
  border-radius: 0px;
}
.documentTitleName {
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
}
.cancelButton1:hover {
  color: #000000;
  border-color: #000000;
}
.bgCol {
  background: url("../images/mainBgNew.png") right top no-repeat #112e53;
  background-size: auto 100%;
  width: 100%;
  min-height: 100vh;
  float: left;
}
.top.topRelativeCol:before,
.top.topRelativeCol:after {
  display: none;
}
.button {
  text-transform: uppercase;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -khtml-border-radius: 0px;
  border-radius: 0px;
  background: #2d6cb2;
  border: 1px solid #2d6cb2;
}
body .button:hover,
body .saveButton:hover,
.popUpNoButton:hover,
.doneButton:hover {
  background: -mozlinear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #2d6cb2;
  background: -webkit-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    #2d6cb2;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #2d6cb2;
}
.dependentDefaultCenterBox {
  max-width: 690px;
}
.myAccountPersonalInfo label,
.formRow label,
.dependentBorderBox .labelCol {
  color: #112e53;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}
.payRow .width50 + .width50 label.repeatMonthlyLabel {
  left: 95px;
}
.payRow .width50 + .width50.repeatMonthlyCol {
  padding-left: 370px;
}

.payRow .width50 + .width50 label.repeatMonthlyLabelIncome {
  left: 0px;
}
.payRow .width50 + .width50.repeatMonthlyColIncome {
  padding-left: 270px;
}
.repeatMonthlyColIncome .marginTop5.df.aic {
  margin-top: 5px !important;
}

.personalInfoNameRow,
.formRow.attachAllFileCol {
  padding-left: 300px;
}
.formRow.attachAllFileCol .marginTop5.df.aic {
  margin-top: 5px !important;
}
.attachAllFileCol label {
  color: #112e53;
}

.repeatMonthlyCol .marginTop5.df.aic {
  margin-top: 5px !important;
}
.astrciBlue {
  color: #d96941;
}
.inputColum24size,
.inputColum,
.react-tel-input .form-control {
  background: transparent;
  color: #3d4551;
  font-size: 22px;
  line-height: 24px;
  font-weight: 400;
}
.inputColum24size:empty,
.inputColum:empty,
.dependentBorderBox .react-tel-input .form-control:empty {
  border-color: #c6cace;
}
.inputColum24size:not(:placeholder-shown),
.inputColum:not(:placeholder-shown),
.dependentBorderBox .react-tel-input .form-control:not(:placeholder-shown) {
  border-color: #3d4551;
}
.inputColum24size:placeholder-shown,
.inputColum:placeholder-shown,
.dependentBorderBox .react-tel-input .form-control:placeholder-shown,
.inputColum24size.noText {
  border-color: #c6cace;
}
textarea.txtNote {
  background: #faf8f4;
  color: #3d4551;
  font-size: 22px;
  line-height: 20px;
  border: 1px solid #3d4551;
}
textarea.txtNote::placeholder {
  color: #c6cace !important;
  font-size: 22px;
  line-height: 20px;
  opacity: 1;
  font-family: "Work Sans", sans-serif !important;
}
body .expensesWrap h3,
.addExpensesWrap h3,
.incomesWrap h3,
.addIncomesWrap h3,
.addActivityWrap h3 {
  color: #122e52;
  font-family: "Work Sans", sans-serif !important;
  font-size: 20px;
  line-height: 24px;
}
.addIncomesWrap h3.medH3 {
  margin-bottom: 0;
}
.addIncomesWrap h3.medH3 span.marginBottom10 {
  margin-bottom: 0;
}
.dropZoneWrap {
  border: 1px dashed #c6cace;
  background: transparent;
}
.addExpWrap span {
  color: #2d6cb2;
}
.dropZoneWrap:hover,
.dropZoneHover {
  border-color: #2d6cb2;
}
.doctorListTabelButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
}
.dependentResidencCol .dependentResidence__placeholder {
  color: #c6cace;
}
.dependentResidence__indicator svg {
  color: #3d4551;
}
body .dependentResidence__indicator {
  padding-right: 0px;
}
.dependentResidencCol .dependentResidence__menu {
  margin-top: 0px;
  border-radius: 0px;
}
.dependentResidence__menu-list {
  padding-top: 0px;
}
.dependentResidencCol .dependentResidence__option--is-selected,
.dependentResidencCol .dependentResidence__option--is-focused,
.dependentResidencCol .dependentResidence__option {
  background: transparent !important;
  color: #3c4450 !important;
}

.phoneTypeCol
  .phoneType1__control--is-focused.phoneType1__control--menu-is-open,
.phoneTypeCol
  .phoneType1__control--is-focused.phoneType1__control--menu-is-open:focus {
  border-bottom: 1px solid #2d6cb2 !important;
  background: transparent;
}
.phoneTypeCol .phoneType1__control {
  background: transparent;
}
.phoneTypeCol .phoneType1__placeholder {
  color: #c6cace;
}
.phoneType1__indicator svg {
  color: #3d4551;
}
body .phoneType1__indicator {
  padding-right: 0px;
}
.phoneTypeCol .phoneType1__menu {
  margin-top: 0px;
  border-radius: 0px;
}
.phoneType1__menu-list {
  padding-top: 0px;
}
.phoneTypeCol .phoneType1__option--is-selected,
.phoneTypeCol .phoneType1__option--is-focused,
.phoneTypeCol .phoneType1__option {
  background: transparent !important;
  color: #3c4450 !important;
}

.doctorPrescribingCol
  .doctorPrescribing__control--is-focused.doctorPrescribing__control--menu-is-open,
.doctorPrescribingCol
  .doctorPrescribing__control--is-focused.doctorPrescribing__control--menu-is-open:focus {
  border-bottom: 1px solid #2d6cb2 !important;
  background: transparent;
}
.doctorPrescribingCol .doctorPrescribing__control {
  border-bottom: 1px solid #3d4551 !important;
  background: transparent;
}
.doctorPrescribingCol .doctorPrescribing__placeholder {
  color: #c6cace;
}
input.InputElement.is-empty.Input.Input--empty::placeholder {
  color: red;
}
.doctorPrescribing__indicator svg {
  color: #3d4551;
}
body .doctorPrescribing__indicator {
  padding-right: 0px;
}
.doctorPrescribingCol .doctorPrescribing__menu {
  margin-top: 0px;
  border-radius: 0px;
}
.doctorPrescribing__menu-list {
  padding-top: 0px;
}
.doctorPrescribingCol .doctorPrescribing__option--is-selected,
.doctorPrescribingCol .doctorPrescribing__option--is-focused,
.doctorPrescribingCol .doctorPrescribing__option {
  background: transparent !important;
  color: #3c4450 !important;
}
.doctorPrescribingCol .doctorPrescribing__option:last-child {
  color: rgba(0, 150, 199, 0.99) !important;
}

.react-tel-input .form-control {
  background: transparent;
}
.dependentBorderBox {
  padding-top: 10px;
}
.dependentBorderBox {
  border: 1px solid transparent;
}
.attachTxt svg,
.attachTxt span {
  color: #2d6cb2;
}
.attachTxt .dropTxt {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #565c65;
  margin-top: 5px;
}
.saveButton {
  background: #2d6cb2;
  text-transform: uppercase;
  border-color: #2d6cb2;
}
.cancelButton1 {
  text-transform: uppercase;
}
.uploadFileName {
  font-size: 18px;
  color: #3d4551;
}
.tableBody th,
.tableBody td {
  padding: 22px 12px;
}
.tableBody th {
  background: #fff;
  color: #71767a;
}
.ellipsis span {
  top: 22px;
}
.tableBody tr:nth-child(even) {
  background: #faf8f4;
}
.dateWrap input {
  color: #6c7075;
}
.txtTo {
  color: #112e53;
}
body .filterButton.btn-outline.saveButton {
  color: #2d6cb2 !important;
  border: 0px !important;
  background: transparent !important;
  box-shadow: none;
  text-transform: none;
}
.activityBoxCol .lblTotal {
  background: #faf8f4;
}
.lblTotal {
  color: #6c7075;
}
.totalActivity {
  color: #112e53;
}
.btn-primary.saveButton {
  background: #2d6cb2;
  border: 1px solid #2d6cb2;
}
.btn-primary.saveButton:hover {
  background: -mozlinear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #2d6cb2;
  background: -webkit-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    #2d6cb2;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #2d6cb2;
}
/*.btn-primary.saveButton:hover{background:#2D6CB2;}*/
button.btn-outline.saveButton.printButton {
  border: 1px solid #000;
  color: #000;
}
.dependentSubTitle {
  color: #122e52;
  font-size: 20px;
}
.expenseDetails label,
.inventory_label,
.ViewMedicationMain .MedicationDetails label,
.ViewDoctorMain .docotorDetails label {
  color: #112e53;
  line-height: 24px;
}
.inventory_value,
.expenseDetails > div > div,
.ViewMedicationMain .MedicationDetails > div > div,
.ViewDoctorMain .docotorDetails > div > div {
  line-height: 24px;
  color: #3d4551;
  font-weight: 400;
}
.ViewMedicationMain .MedicationDetails .paddingLeft220 {
  margin-bottom: 25px;
}
.attachedImgList.fileList .fileInner {
  padding-top: 10px;
}
.bgWhiteBorderRadius {
  background: #fff;
  border-radius: 8px;
}
.exp {
  border: 1px solid #dfe1e2;
}
.viewActionButton .btn-primary.saveButton {
  background: transparent;
  background: transparent;
  min-width: inherit;
  border: 0px;
  box-shadow: none;
  padding-right: 0px;
}
.viewActionButton .cancelButton1 {
  background: transparent;
  background: transparent;
  min-width: inherit;
  border: 0px;
  box-shadow: none;
  padding-right: 0px;
  width: auto;
}
.viewActionButton .saveButton span,
.viewActionButton .cancelButton1 span {
  display: none;
}
/* .react-tel-input .form-control{border-bottom:1px solid #3D4551 !important} */

.filterBy.active {
  color: #0096c7;
}
.filterBy.active i {
  border-color: #34a0a4;
}
.popUpMainBg {
  background: #f2ede5;
}
.popTop {
  background: rgba(122, 122, 122, 0.62);
}
.deleteElement .areYouText {
  font-size: 38px;
  line-height: 42px;
  font-family: "DM Serif Text", serif;
  color: #112e53;
}
.popUpYesButton {
  background: #2d6cb2;
}
.grayButtonColorBorder {
  border: 1px solid #000000;
  background: #fff;
  color: #000000;
  text-transform: uppercase;
  font-size: 18px;
}
.popUpYesButton {
  font-size: 18px;
}
.areYouDmFont {
  font-family: "DM Serif Text", serif;
  color: #112e53;
  font-size: 38px;
  line-height: 42px;
}
.popUpYesButton {
  text-transform: uppercase;
  background: #fff;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 2px;
}
.popUpYesButton:hover {
  background: #fff;
}
.popUpNoButton {
  text-transform: uppercase;
  color: #fff;
  background: #2d6cb2;
  border: 1px solid #2d6cb2;
  border-radius: 2px;
}
.useInfo {
  color: #3d4551;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Work Sans", sans-serif;
}
.removeExpDescription {
  color: #3d4551;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.useInfo {
  max-width: 466px;
}
.maxWidth368 {
  max-width: 368px;
}
.popUpInviteUserMessage {
  font-size: 16px;
  line-height: 24px;
  color: #3d4551;
}
input:focus,
textarea:focus,
select:focus {
  outline: none !important;
  border-color: #2d6cb2;
}
input.doneButtonDisable,
input.doneButtonDisable:focus {
  outline: none;
  border-color: #c6cace !important;
}
.dateWrap input {
  width: 118px;
}
.btn-primary.saveButton.viewEditButton {
  background: #fff;
  color: #2d6cb2;
}
.viewDeleteButton {
  color: #d96941;
  border-color: #d96941;
  text-transform: capitalize;
}
.viewDeleteButton:hover {
  color: #d96941;
  border-color: #d96941;
}
.bgColor {
  background: #f4efe7;
}
.inventoryBox .bgColor {
  background: #faf8f4;
}
@media screen and (min-width: 769px) {
  .inventoryBox .bgColor {
    width: calc(100% - 20px);
  }
}
.billLeft {
  width: 100%;
  float: left;
  max-width: 740px;
}
.billRight {
  width: 290px;
  float: left;
}

.dependentResidencCol .phoneType1__control,
.tabContainer {
  background: transparent;
}
.myAccountTitle {
  color: #122e52;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}
.passwordTabUsername {
  border-bottom: 1px solid #71767a;
  color: #71767a;
}
.borderBotom {
  border-bottom: 1px solid #71767a;
}
.billingForm .StripeElement {
  border-bottom: 1px solid #c6cace;
}
.billingForm .StripeElement--focus {
  border-bottom: 1px solid #2d6cb2 !important;
}
.billingForm .StripeElement--complete {
  border-bottom: 1px solid #3d4551;
}
.billingForm .StripeElement--invalid {
  border-bottom: 1px solid #d96941;
}
/*.borderBottom {border-bottom:1px solid #71767A;}*/
.myAccountPersonalInfo label.passordLable2 {
  top: 38px;
}
.displayFlexColJC-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.generalInfoSubTitle {
  color: #122e52;
}
.saveButton,
.saveButtonDisable {
  min-width: 133px;
  border-radius: 2px;
  text-transform: uppercase;
}
.cancelButton1 {
  width: 133px;
}
.paddinBottom110 {
  padding-bottom: 110px;
}
.bottom {
  bottom: 0px;
}
.paddingTop {
  padding-top: 0px;
}
.fontWeight400 {
  font-weight: 400;
}
.btn-outline.saveButton {
  background: white !important;
  border: 1px solid #2d6cb2;
  color: #2d6cb2;
  min-width: inherit;
}
.desktopCol .saveButton {
  min-width: inherit;
  text-transform: capitalize;
  border-radius: 4px;
}
.cancelButton1:hover {
  background: #f7f9fa;
}
.imgBoxMedicalView svg {
  width: 100%;
  height: 100%;
}
.imgBoxMedicalView {
  background: #e1eaf4;
}
.doneButton {
  background: #2d6cb2;
}
button.btn-outline.saveButton.printButton:hover,
.btn-primary.saveButton.viewEditButton:hover {
  background: #f7f9fa !important;
}
.filterBy.active {
  color: #2d6cb2;
}
.filterBy.active i {
  border-color: #2d6cb2;
}
.signColCenter {
  background: #f2ede5;
  padding-top: 40px;
  padding-left: 51px;
  padding-right: 51px;
  margin-bottom: 15px;
}
.passwordMatchBox ul li .passowrdListOfConditionText {
  font-size: 14px;
}
.firstLastNameSpaceSignUp {
  margin-top: 10px;
}
.signUpFlow label {
  color: #3c5c85;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}
.marginBottomInputCol {
  margin-bottom: 30px;
  margin-top: 10px;
}
body .paddingLeftPaddingRight35 {
  padding-left: 55px;
  padding-right: 55px;
}
.passwordMatchBox ul li {
  width: 52%;
  padding-left: 0px;
}
li.passowrdListOfCondition:nth-child(2),
li.passowrdListOfCondition:nth-child(4) {
  width: 48%;
}
.loaderSpinner8radius {
  max-width: 388px;
  border-radius: 0px;
}
.buttonDisable {
  border-radius: 0px;
}
.joinNowText span,
.signUpTextLinkColor {
  color: #2d6cb2;
}
.termAndPrivacyPolicyRowSignUp {
  margin-top: 20px;
}
.joinNowText {
  margin-top: 10px;
}
.titleWelcomeToAccount span {
  color: #1c1d1f;
}
.codeInput {
  width: 237px;
  border: 1px solid #53575a;
  background: transparent;
  border-radius: 0px;
}
.errorInputAllBorderRed.codeInput {
  border: 1px solid rgba(242, 147, 140, 0.8);
}
.dontReceiveCodeText span {
  color: #2d6cb2;
}
.cancelButton {
  color: #2d6cb2;
  text-decoration: none;
}
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f2ede5 inset !important;
}
.buttonDisable {
  max-width: 388px;
}

.documentListCol {
  height: 205px;
}

.MuiTabs-flexContainer button {
  padding: 17px 12px;
  background: #fff;
  opacity: 1;
}
.MuiTabs-flexContainer button:nth-child(2) {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.medicalTableTitle .MuiTabs-flexContainer button:nth-child(2) {
  border-right: 0px solid rgba(0, 0, 0, 0.3);
}
.MuiTabs-scroller .MuiTabs-indicator {
  background-color: transparent !important;
}
.MuiTabs-flexContainer button span {
  color: #112e53;
  font-size: 20px;
  line-height: 24px;
}
.MuiTab-textColorInherit.Mui-selected {
  background: #f2a950;
}
.MuiTabs-flexContainer button.Mui-selected span {
  color: #112e53;
}
.MuiTabs-flexContainer button.Mui-selected {
  position: relative;
}
.MuiTabs-flexContainer button.Mui-selected:before {
  content: "";
  width: 0;
  height: 0;
  border-color: #f2a950 transparent transparent;
  bottom: -20px;
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  position: absolute;
  left: 44%;
  z-index: 1;
}
body .MuiTab-root,
.MuiTabs-root,
.MuiTabs-fixed {
  overflow: visible !important;
}
.MuiTabs-scroller {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.dependentDefaultCenterBox {
  max-width: 715px;
}
.sideBarNav ul li:hover .animationWidthOnly {
  color: #f2a950;
}
/*.sideBarNav {max-width: 235px;height: 100%;float: left;width: 235px;position: relative;padding-bottom: 58px;min-height: calc(100vh - 0px);}*/
/*.errorMessageWithBackground {background:#D96941;}*/
/*.errorTextContent{color:#fff}*/

.noValue .dependentResidence__control,
.noValue .doctorPrescribing__control,
body .null.react-tel-input .form-control,
.form-controlFax.noValue .form-control {
  border-color: #c6cace !important;
}
.dependentResidencCol
  .dependentResidence__control--is-focused.dependentResidence__control--menu-is-open,
.dependentResidencCol
  .dependentResidence__control--is-focused.dependentResidence__control--menu-is-open:focus,
body .react-tel-input .form-control:focus,
.form-controlFax.noValue .form-control:focus {
  border-bottom: 1px solid #2d6cb2 !important;
  background: transparent;
}
.dependentResidencCol .dependentResidence__control {
  border-bottom: 1px solid #c6cace !important;
  background: transparent;
}
.hasValue .dependentResidence__control,
.hasValue .doctorPrescribing__control,
.hasValue [class*="__control"],
body .phoneNumber_Active .form-control {
  border-color: #3d4551 !important;
}

.sideBarNav ul li a span svg {
  fill: #7aa4ce;
}
.sideBarNav ul li:hover a span svg {
  fill: #f2a950;
}

.sideBarNav ul li a span.disableIcon svg,
.navDisableText {
  fill: #b5cce4;
  opacity: 0.5;
  color: #b5cce4;
}

.dependentOnboardLaterText {
  color: #2d6cb2;
}

.welcomeBg {
  background: -moz-linear-gradient(#f8efde 0%, rgba(248, 239, 222, 0) 100%);
  background: -webkit-linear-gradient(#f8efde 0%, rgba(248, 239, 222, 0) 100%);
  background: linear-gradient(#f8efde 0%, rgba(248, 239, 222, 0) 100%);
  background-size: auto;
  position: relative;
}

.welcomeBg:before {
  background: url("../images/welcomeScreenBgPattern.png") right top no-repeat;
  background: url("../images/welcomeScreenBgPattern.png") right top no-repeat;
  background: url("../images/welcomeScreenBgPattern.png") right top no-repeat;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
}
.welcomeTitle {
  font-size: 89px;
  line-height: 93px;
  color: #112e53;
  font-family: "DM Serif Text", serif;
  font-weight: 400;
}
.maxWidth766 {
  max-width: 766px;
}
.maxWidth750 {
  max-width: 750px;
}
.welcomeDescriptionText {
  font-size: 16px;
  line-height: 23px;
  color: #000;
}
.descriptionrightBox h3 {
  font-size: 16px;
  line-height: 23px;
  color: #000;
  font-weight: 600;
}
.marginRight50 {
  margin-right: 50px;
}
.descriptionrightBox ul li {
  margin-top: 10px;
}
.descriptionrightBox ul li span.welcomeListText {
  font-size: 16px;
  line-height: 23px;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 15px;
  margin-left: 15px;
  display: inline-block;
  padding-right: 50px;
  min-width: 400px;
}
.descriptionrightBox ul li span.welcomeRightTick {
  display: inline-block;
  vertical-align: middle;
}
.welcomeScreenButton {
  height: 56px;
  width: 100%;
  max-width: 238px;
  background: #d96941;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  border: 0px;
  cursor: pointer;
}
.maxWidth600 {
  max-width: 600px;
}
.maxWidth300 {
  max-width: 300px;
}
.maxWidth200 {
  max-width: 200px;
}
.maxWidth315 {
  max-width: 315px;
}

.myProfileViewAccount .textStyle {
  font-size: 24px;
}
.inventoryLabelTextEqual {
  font-size: 18px;
  margin-top: 4px;
  display: inline-block;
  font-weight: 500;
}
.activityMainList .activityTableTitle .typeCol {
  width: 20%;
}
input.saveButtonDisable {
  border-color: transparent !important;
}
.right {
  right: 0px;
}
.lblDtCO.dateCourtOrderTop {
  top: -5px;
}

.marginLeftRight2Percent {
  margin-left: 2%;
  margin-right: 2%;
}
.stateLabel {
  margin-bottom: 14px !important;
  display: inline-block;
}
.marginTop17 {
  margin-top: 17px;
}
.maxWidth420 {
  max-width: 420px;
}
.myAccountPersonalInfo .react-tel-input.phoneNumber_Active .form-control,
.phoneTypeCol .phoneNumberActive.phoneType1__single-value,
.inputColum24size:not(:placeholder-shown),
.inputColum:not(:placeholder-shown),
.dependentBorderBox .react-tel-input .form-control:not(:placeholder-shown) {
  color: #3d4551;
  font-weight: normal;
}
.loaderSpinner {
  border-radius: 2px;
}
.paddingTop55 {
  padding-top: 55px;
}
.myAccountLeftCol {
  width: 50%;
}
.myAccountRightCol {
  width: 50%;
}
.tabRowMyAccount ul li {
  width: 33.33%;
  float: left;
}
.myAccountRightCol .myAccountTabFullWidth {
  width: 100%;
  float: left;
}
.tabRowMyAccount ul {
  background: #b2b5b9;
  height: 80px;
  margin-bottom: 0px;
}
.tabRowMyAccount ul li {
  position: relative;
  text-align: center;
  height: 100%;
  z-index: 3;
}
.tabRowMyAccount ul li:before {
  content: "";
  width: 0px;
  height: 0px;
  border-width: 40px;
  border-color: transparent transparent transparent #b2b5b9;
  border-style: solid;
  position: absolute;
  right: -60px;
  top: 0px;
  z-index: 2;
  display: inline-block;
}
.tabRowMyAccount ul li.active:before {
  border-color: transparent transparent transparent #f2a950;
}
.tabRowMyAccount ul li.active .tabTitle {
  background: #f2a950;
}
.tabRowMyAccount ul li.active:nth-child(2):after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: -23px;
  z-index: -1;
  background: #f2a950;
}
.tabRowMyAccount ul li.active:nth-child(3):after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: -7px;
  z-index: -1;
  background: #f2a950;
}

.tabRowMyAccount ul li:nth-child(2):before {
  right: -70px;
}
.tabRowMyAccount ul li:last-child:before {
  display: none;
  width: 230px;
}
.tabRowMyAccount ul li:first-child {
  width: 240px;
  padding-right: 20px;
  z-index: 6;
}
.tabRowMyAccount ul li:nth-child(2) {
  width: 225px;
  padding-left: 10px;
  z-index: 4;
  padding-right: 10px;
}
.tabRowMyAccount ul li:nth-child(3) {
  width: 255px;
  padding-left: 30px;
}

.readOnlyMyAccountTitle.tabRowMyAccount ul li:first-child {
  width: 100%;
  padding-right: 0px;
}
.readOnlyMyAccountTitle.tabRowMyAccount ul li:first-child .tabTitle:after {
  display: none;
}
.readOnlyMyAccountTitle.tabRowMyAccount ul li:first-child .tabTitle {
  justify-content: flex-start;
  padding-left: 30px;
}
.readOnlyMyAccountTitle.tabRowMyAccount ul li span.count {
  display: none;
}

.tabTitle {
  width: 100%;
  float: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.tabTitle span.count {
  width: 26px;
  height: 26px;
  background: #f2ede5;
  border-radius: 50%;
  display: inline-block;
  color: #d86941;
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
  line-height: 26px;
}
.tabTitle span.tabText {
  color: #616668;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}
.tabTitle:after {
  content: "";
  width: 58px;
  height: 55px;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  position: absolute;
  moz-transform: rotate(135deg);
  webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  right: -30px;
  z-index: 9;
}
.tabRowMyAccount ul li:nth-child(2) .tabTitle:after {
  right: -30px;
  z-index: 9;
}
.tabRowMyAccount ul li:nth-child(3) .tabTitle:after {
  display: none;
}
.tabRowMyAccount ul li.active .tabTitle span.tabText {
  color: #1c1d1f;
}

.tabContainerMyAccount.fullWidth {
  background: #f2ede5;
  position: relative;
  z-index: 8;
}
.zindex1 {
  z-index: 1;
}
body .zindex0 {
  z-index: 0;
}
.newFormUI .personalInfoNameRow,
.newFormUI .formRow {
  padding: 0px;
}
.forgotPassWidth {
  max-width: 100%;
  width: 388px;
}
.tabContainerMyAccount {
  padding: 0px 35px 120px 35px;
}
.newFormUI label,
.formRow .newFormUI label {
  position: static;
  padding-left: 0px;
  color: #3c5c85;
  margin-bottom: 10px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
}
.myAccountPersonalInfo .newFormUI .phoneTypeLabel {
  width: 100%;
  float: left;
}
.myAccountPersonalInfo .newFormUI .firstLastNameSpace,
.myAccountPersonalInfo .newFormUI .formRowGap {
  margin-top: 0px;
}
.errorMessageWithBackground {
  background: #d96941;
}
.errorTextContent {
  color: #ffffff;
}
.phoneTypeCol .phoneType1__single-value,
.head24,
.phoneTypeCol .phoneType1__value-container,
body .phoneNumberActive {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
body .phoneNumberActive.phoneType1__menu-list .phoneNumberActive {
  padding-left: 12px !important;
}
.phoneTypeCol .phoneType1__placeholder,
.phoneType1__input-container {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.rightalignBottomText {
  right: 0px;
  padding-right: 0px;
  max-width: 100%;
  text-align: right;
}
.allRightReserveText .signBottomLink.marginRightNone {
  margin-right: 0px;
}
.myAccountPersonalInfo .react-tel-input .form-control {
  padding-left: 0px;
}
.marginTop27 {
  margin-top: 27px;
}
.tabContainerMyAccount {
  min-height: 546px;
}
.dependentimageUploadCol:after {
  content: "\200b";
  position: absolute;
  visibility: hidden;
}
.tabContainerMyAccount .dependentStepFormRow {
  padding-left: 0px;
}
.dependentStep1.newFormUI label,
.dependentStep1 .formRow .newFormUI label {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}
.signUpFlowButtonRow {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 70px);
  padding-bottom: 05px;
}
.imageUploadMain {
  width: 100%;
  display: block;
}
.imageUploadMain .avtarTextCol span {
  font-size: 14px;
  color: #1c1d1f;
  line-height: 20px;
  text-align: left;
  font-weight: 600;
}
.signUpImageUploadCol {
  padding: 0px 20px 15px 0px;
  display: flex;
  align-items: center;
}
.newFormUI .signUpImageUploadCol label {
  margin-bottom: 0px;
  margin-right: 20px;
  line-height: inherit;
  display: inline-flex;
}
.imageUploadMain .profileImageCol {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 20px;
}
.imageUploadMain .profileImageBox {
  padding: 0px 20px 15px 0px;
  display: flex;
  align-items: center;
}
.imageUploadMain .profileImageCol img {
  width: 100%;
  height: auto;
}
.tabContainerMyAccount .dependentGenderCol input[type="radio"] {
  width: 22px;
  height: 22px;
  border: 2px solid #71767a;
  border-radius: 50%;
  background: transparent;
  float: left;
}
.tabContainerMyAccount .dependentGenderCol span {
  position: static;
  margin-left: 10px;
  font-size: 17px;
}
.dependentStep1.newFormUI .dependentGenderCol label span {
  background: transparent;
  color: #71767a;
  width: auto;
  border: none;
  float: left;
  position: relative;
  line-height: normal;
}
.dependentStep1.newFormUI .dependentGenderCol label {
  margin-right: 15px;
}
/*.dependentGenderCol [type="radio"]:checked + span, .dependentGenderCol span:hover, .dependentGenderCol input[type="radio"]:focus + span{border:0px;}*/
.dependentGenderCol [type="radio"]:focus + span {
  outline: 0px dotted #0096c7;
}
.dependentGenderCol [type="radio"][type="radio"]:checked {
  border: 2px solid #112e53;
}
.dependentGenderCol [type="radio"]:focus + span:before,
.dependentGenderCol [type="radio"][type="radio"]:checked + span:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #3c5c85;
  position: absolute;
  left: -26px;
  top: 6px;
}
.dependentText {
  color: #112e52;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.removeText {
  color: #2d6cb2;
  font-size: 14px;
  cursor: pointer;
  font-size: 18px;
  line-height: 24px;
}
.visibilityHidden {
  visibility: hidden;
  position: static;
  width: 1px;
  height: 1px;
  overflow: hidden;
  display: inline-block;
}
.backButton {
  background: #9faab6;
  width: 145px;
  height: 56px;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 2px;
  border: none;
  color: #f2ede5;
  font-size: 18px;
  font-weight: 600;
}
.buttonHeight56 {
  height: 56px;
  border: 0px;
}
.roleTypeList label {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.roleTypeList label input {
  margin-right: 17px;
}
.roleTypeList label span {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-top: 5px;
  float: left;
}
.roleTypeList li {
  width: 100%;
  float: left;
}
.marginTop2 {
  margin-top: 2px;
}
body .coOwn .coOwnerLabel {
  width: 140px;
  white-space: inherit;
  top: -10px;
}
.sideBarNav.sideBarIconOnly {
  max-width: 70px;
}
.sideBarVisible .mainRightSidebare {
  width: calc(100% - 52px);
}
.sideBarNav ul {
  padding: 0px;
}
.navIcon {
  margin-left: 15px;
}
.sideBarIconOnly .navIcon {
  margin-left: 15px;
}
.versionNameNavBottom {
  padding-left: 15px;
}
.sideBarNav.sideBarIconOnly .versionNameNavBottom {
  width: 35px;
  overflow: hidden;
  margin-left: 7px;
}
.navOverlay .invNav {
  margin-top: -4px;
  margin-bottom: -5px;
}
.sideBarNavIcon {
  height: inherit;
  display: inline-flex;
}
.sideBarNav.sideBarIconOnly span.sideBarNavIcon {
  margin-left: 15px;
}
.sideBarNavIcon,
.sideBarNav.sideBarIconOnly span.sideBarNavIcon {
  width: 30px;
}

body .roleTypeList input[type="radio"]:checked {
  border: 5px solid #0466c8;
}
.billingBgheader {
  background: #faf8f4;
}
.yearlyMemberShip {
  color: #6b7280;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
.planAmount {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: #0466c8;
}
.generalInfoSubTitle {
  color: #112e53;
  font-weight: 500;
}

.signUpImgUploadBox .SignUp-custom-file-upload {
  width: 66px;
  height: 66px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.signUpImgUploadBox label.SignUp-custom-file-upload:before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  line-height: 66px;
  background: url("../images/cameraUploadImg.svg") center center no-repeat
    rgba(0, 0, 0, 0.45);
  border-radius: 50%;
  margin: 3px;
  opacity: 0;
  display: inline-block;
  -web-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-size: 16px 13px;
}
.newFormUI .signUpImageUploadCol label svg {
  height: 66px;
}
.signUpImgUploadBox label.SignUp-custom-file-upload:hover:before {
  opacity: 1;
}
.signUpImgUploadBox .SignUp-custom-file-uploadEditComponent {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
}
.signUpImgUploadBox label.SignUp-custom-file-uploadEditComponent:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 66px;
  background: url("../images/cameraUploadImg.svg") center center no-repeat
    rgba(0, 0, 0, 0.45);
  border-radius: 50%;
  opacity: 0;
  display: inline-block;
  -web-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  left: 0px;
  background-size: 16px 13px;
}
.signUpImgUploadBox label.SignUp-custom-file-uploadEditComponent:hover:before {
  opacity: 1;
}
.signUpImgUploadBox .SignUp-custom-file-uploadEditComponent svg {
  width: 76px;
  height: 76px;
}
.signUpImgUploadBox .profileImageCol {
  border: 3px solid #edeff0;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  background: #aecbda;
  min-width: 60px;
}
.signUpImgUploadBox .width268Center {
  width: 268px;
  width: 268px;
  left: -285px;
  top: 40px;
}
.signUpImgUploadBox .width268Center .imageOptionList ul:before {
  left: 6%;
  border-width: 12px;
  top: -23px;
}
.signUpImgUploadBox .width268Center .imageOptionList li {
  padding-left: 30px;
}
.signUpImgUploadBox .imgCropBoxInner {
  background: #f2ede5;
}
.signUpImgUploadBox .imgCropBox:before {
  background: rgba(0, 0, 0, 0.49);
}
.signUpImgUploadBox .width268Center .imageOptionList li,
.width268Center .imageOptionList li label {
  font-size: 24px;
  text-transform: capitalize;
  color: #3d4551;
  font-weight: 400;
}
/*.signUpImgUploadBox .imgCropBoxInner {min-height: 595px;min-width: 816px;}*/
.popUpBoxCol {
  width: 100%;
  min-width: 816px;
  min-height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popUpImageTitle {
  color: #1c1d1f;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  text-align: left;
}
.doneButton,
.cancelButton1 {
  border-radius: 2px;
}
.doneButton {
  border-color: #2d6cb2;
}
.onBoardingFlow .signUpImgUploadBox .width268Center {
  left: -80px;
}

.bgCol .container {
  max-width: 1320px;
  padding-left: 10px;
  padding-right: 10px;
}
.myAccountLeftCol {
  padding-left: 0px;
  max-width: 410px;
}
.myAccountRightCol {
  min-width: 720px;
  float: right;
}
.sideBarNav ul li {
  height: 50px;
}
.sideBarNav ul li a {
  padding: 13px 0px;
}
.versionNameNavBottom {
  margin-top: 20px;
}
.versionNameNavBottom.poisitonChange {
  position: relative;
  margin-top: 20px;
  float: left;
}
.sideBarNav {
  overflow: auto;
  overflow-x: hidden;
}
.backArrow {
  display: flex;
  align-items: center;
}
.minWidth133 {
  min-width: 133px;
}

.dependentBorderBox .react-tel-input .form-control:not(:placeholder-shown) {
  color: #c6cace;
}
.dependentBorderBox
  .phoneNumber_Active.react-tel-input
  .form-control:not(:placeholder-shown) {
  color: #3d4551;
}
.marginLeft3 {
  margin-left: -1px;
}
.dependentEditProfileMain
  .dependentGenderCol
  [type="radio"]:focus
  + span:before,
.dependentEditProfileMain
  .dependentGenderCol
  [type="radio"][type="radio"]:checked
  + span:after {
  display: none;
}
.sucessPopUpTitle {
  color: #1c1d1f;
  font-size: 38px;
  line-height: 44px;
  font-family: "DM Serif Text", serif !important;
}
.yourSubscribe {
  font-size: 16px;
  line-height: 24px;
  color: #3d4551;
}
.wardMovingLabel {
  color: #112e53;
  font-size: 14px;
  font-weight: 600;
}
.phone_number_placeholder_color {
  color: #c6cace;
}
.sidebarLogo {
  cursor: default;
}
.fontSize15 {
  font-size: 15px;
}
.dependentStep1.newFormUI .dependentGenderCol label span::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: -29px;
  z-index: 2;
  background: transparent;
}
.imgPlusIconUpload {
  position: absolute;
  right: -1px;
  bottom: -21px;
}
.marginTop6 {
  margin-top: 6px;
}

.sidebarLogoNav span.sidebarLogo.animationall {
  display: none;
}
.medicalTableTitle .instructions {
  display: none;
}
.statusWithDate {
  display: none;
}
.medicalStatus_600 {
  display: none;
}
.hideDesktopShowMobile {
  display: none;
}
span.hideDesktopShowMobile.tagIcon {
  display: none;
}
.showDesktophideMobile {
  display: inline-block;
}
.dateIcon svg {
  fill: none;
  stroke-width: 3;
  stroke: #2d6cb2;
}

.expenseTableTitle tr td:nth-child(4) span,
.incomeTableTitle tr td:nth-child(4) span {
  text-align: right;
  display: block;
}
.expenseTableTitle tr th:nth-child(4) span,
.incomeTableTitle tr th:nth-child(4) span {
  text-align: right;
  display: block;
}

.transferTable .totalValue .filterBy,
.amtCol .filterBy,
.inventoryAmountCol .filterBy {
  position: relative;
}
.amtCol .filterBy .arrow {
  position: absolute;
  top: 11px;
}
.transferTable .totalValue .filterBy .arrow,
.inventoryAmountCol .filterBy .arrow {
  position: absolute;
  top: 11px;
  right: 1px;
}
.transferTable .totalValue .filterBy.dec .arrow,
.amtCol .filterBy.dec .arrow.down {
  position: absolute;
  top: 8px;
}
.statusCol .filterBy.dec .arrow.down {
  margin-top: 0;
}
.inventoryAmountCol .filterBy.dec .arrow.down {
  position: absolute;
  top: 10px;
  right: 1px;
}
.medicationinstruction {
  line-height: 30px;
}
.medicalTableTitle table tr td:nth-child(2) .medLongText span {
  line-height: 30px;
}

.selectedProfileImg {
  width: 31px;
  height: 31px;
  margin-right: 7px;
  border: 2px solid transparent;
  border-radius: 50%;
  background-image: linear-gradient(
    132.46deg,
    rgba(3, 82, 160, 0.24) 0%,
    rgba(1, 132, 189, 0.24) 51.04%,
    rgba(0, 180, 216, 0.24) 100%
  );
  border-image-slice: 1;
}
.nameImage:hover span.selectedProfileName {
  color: #f2a950;
}
.dependentArrow svg {
  fill: #7ba4cd;
}
/*.dependentArrow svg{fill:#F2A950;}*/
.dependentArrowActive svg {
  fill: #7ba4cd;
}

/*.navDependent_Profile:hover span svg{fill:#F2A950;}*/
.navDependent_Profile .circleBg {
  fill: #e2edf8;
}
.nameImage:hover .circleBg {
  fill: #f2a950;
}
.nameImage:hover .selectedProfileImg {
  border: 2.5px solid #f2a950;
}

.depedentArrowCircle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
}
.dependentListOpen,
.depedentArrowCircle:hover {
  background: #fff;
}
span.nameImage {
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectedProfileName {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 141px;
  margin-right: 5px;
  margin-left: 10px;
  color: #7ba4cd;
}
.activeNavName .selectedProfileName {
  color: #f2a950;
}
.clickDisable.activeNavName .selectedProfileName {
  color: #f2a950;
}
.activeNavName .selectedProfileImg {
  border: 2.5px solid #f2a950;
}
.selectedProfileRow {
  display: flex;
  align-items: center;
}
.navDependentList {
  background: #fff;
  position: absolute;
  top: 27px;
  border-radius: 8px;
  padding-top: 10px;
  width: 215px;
  float: left;
}
.navDependentList .dependentproBox span {
  color: #1c1d1f;
}
.navDependentList .dependentproBox span.dependentListName {
  color: #1c1d1f;
  width: 150px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.dependentproBox {
  display: flex;
  align-items: center;
}
.dependentNavDummyimg svg {
  width: 31px;
  height: 31px;
  margin-left: 15px;
}
span.dependentNavDummyimg,
.depNavDummySelectedimg {
  height: 31px;
  margin-right: 0px;
}
.navDependentList .dependentproBox span.dependentNavDummyimg {
  margin-right: 7px;
}
.depNavDummySelectedimg svg {
  width: 31px;
  height: 31px;
  margin-right: 7px;
}
.navDependent_Profile {
  position: relative;
  width: calc(100% - 50px);
  margin-left: 10px;
  z-index: 15;
}
.navDependent_Profile .navDependentList img {
  width: 31px;
  margin-left: 15px;
  margin-right: 7px;
  border: 2px solid #c9e8f1;
  border-radius: 50%;
  min-height: 31px;
  min-width: 31px;
}
.promoCodeMain {
  background: #faf8f4;
  padding: 20px 25px 22px 25px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.promoCodeMain label {
  text-transform: none;
  position: absolute;
  left: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #2d6cb2;
}
.promoCodeFormRow {
  position: relative;
  padding-left: 225px;
  padding-right: 112px;
}
.promoCodeButton {
  width: 91px;
  height: 32px;
  position: absolute;
  right: 0px;
  max-width: 91px;
  min-width: inherit;
  font-size: 16px;
  bottom: 0px;
}
.promoCodeFormRow .saveButtonDisable {
  background: #9faab6;
}
.referralSuccess {
  padding-left: 35px;
}
.doll_Icon {
  position: absolute;
  left: 0px;
  top: 3px;
}
.referralText span {
  display: block;
}
.referralText > span {
  color: #00a91c;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}
.referralText > span + span {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #565c65;
}
.promoCodeMain.greenBorder {
  border: 1px solid #00a91c;
  padding: 8px 25px 7px 25px;
}
.errorMessagePromocode {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #d96941;
}
.transfers .areYouText {
  color: #112e53;
}
.dependentDatePicker .react-datepicker-popper[data-placement^="bottom"] {
  top: -315px !important;
}
.topLogoRow .logoCol img {
  max-height: 39px;
}
.transparentLayer {
  width: calc(100% - 235px);
  left: inherit;
  right: 0px;
}

.medical table.doctorListTable td {
  vertical-align: middle;
}
.medical table.doctorListTable td.ellipsis span {
  top: 30px;
  height: calc(100% - 30px);
}

.tableBody .assetTable td {
  line-height: inherit;
}
span.titleRightContent.positionAbsoute {
  position: absolute;
  right: 0px;
  color: #2d6cb2;
  font-size: 20px;
  cursor: pointer;
  left: inherit;
}
span.titleRightContent2.positionAbsoute {
  position: absolute;
  right: 30px;
  top: 30px;
  color: #2d6cb2;
  font-size: 20px;
  cursor: pointer;
  left: inherit;
}
.imageOptionList li,
.imageOptionList li label {
  cursor: pointer;
}
.dependentAdvocateActionBUtton {
  display: flex;
}
.advocateTeamCall {
  width: auto;
  margin-left: auto;
  margin-right: 20px;
  width: 19px;
}
div.invBox {
  background: #faf8f4;
  padding: 18px;
}
.dependentAdvocateTeamIcon {
  margin-top: 5px;
}

.advocateTeamCall {
  display: flex;
  align-items: center;
}

.sideBarVisible .titleNameAndTabHeader {
  width: calc(100% - 72px);
}
/******dashboard screen start*********/
.dashboardColumMain {
  width: calc(33.33% - 16px);
  margin-left: 8px;
  margin-right: 8px;
  float: left;
}
.dashboardColum {
  width: 100%;
  background: #fff;
  border: 1px solid #d4d4d4;
  float: left;
  margin-bottom: 17px;
  display: flex;
}
.dashboardColum_L {
  display: flex;
  align-items: center;
  width: 126px;
  justify-content: center;
  border-right: 1px solid #d4d4d4;
}
.dashboardColum_R {
  width: calc(100% - 126px);
  height: 100%;
  background: #faf8f5;
}
.dashboardColum_R_T {
  background: #faf8f5;
  padding: 20px;
}
.dashboardColum_R_T h3 {
  color: #112e53;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.dashboardColum_R_T p {
  color: #53575a;
  font-size: 14px;
  line-height: 20px;
  min-height: 60px;
}
.viewLog {
  color: #f69e38;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 5px;
  display: inline-block;
}
.viewLog .arrowIcon {
  margin-left: 8px;
}
.cardBottomText {
  text-transform: uppercase;
}
.dashboardColum_R_B {
  display: flex;
  align-items: center;
  border-top: 1px solid #d4d4d4;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.dashboardColum_R_B .cardBottomText {
  color: #2d6cb2;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
}
.dashboardPlusIcon {
  display: flex;
  padding-right: 10px;
  cursor: pointer;
}
.titleWithSidebarDashboard {
  font-size: 48px;
  line-height: 32px;
  color: #112e53;
  font-family: "DM Serif Text", serif !important;
}
.quikLinkTitle,
.titleWithSidebarDashboard {
  padding-left: 8px;
}
/******dashboard screen end*********/

.marginTop5Imp {
  margin-top: 5px !important;
}
.dependentTabContainer .dependentNoAddressData {
  min-height: 90px;
}

.documentFixedHeader {
  position: fixed;
  float: left;
  width: calc(100% - 285px);
  background: #f4efe7;
  z-index: 25;
}
.documentFixedHeader:before {
  background: #f4efe7;
  content: "";
  position: absolute;
  left: 0px;
  top: -30px;
  width: calc(100% - 0px);
  height: 60px;
}
.documentFixedHeader:after {
  background: #112e53;
  content: "";
  position: absolute;
  left: -30px;
  top: -42px;
  width: calc(100% + 30px);
  height: 12px;
}
.marginTop140 {
  margin-top: 140px;
}

.pAddress,
.dependentColradioButton label {
  word-break: break-all;
}
.dependentColAddress {
  max-width: 90%;
}
.inventory_label20Padding {
  padding-left: 20px;
}

.clickDisable .selectedProfileName {
  color: #b5cce4;
  opacity: 0.5;
}
.maxHeight150px {
  max-height: 150px;
}
.documentTopMargin20 {
  margin-top: 20px;
}

.freeTrialCountdown {
  background: #d96941;
  left: -25px;
  position: absolute;
  top: -25px;
  padding: 10px;
  width: calc(100% + 50px);
  color: #fff;
  font-size: 18px;
  z-index: 99;
  display: flex;
  justify-content: space-between;
}
.textDecoration {
  text-decoration: underline;
}
.width25 {
  width: 25%;
  float: left;
}

body .billingPaddingBottomNone {
  padding-bottom: 0px;
}
.billingAmountNew {
  background-color: #fff;
  max-width: 300px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.billingAmountNew strong {
  color: #2d2e2f;
  font-size: 20px;
  font-weight: 600;
}
.anyQuestion {
  color: #000000;
  font-size: 18px;
}
.anyQuestion span {
  color: #0466c8;
  text-decoration: underline;
  cursor: pointer;
}
.dashboardColum_R_T p span {
  color: #0466c8;
  font-size: 14px;
  cursor: pointer;
}
body .fontSize16 {
  font-size: 16px;
}
.maxWidth340 {
  max-width: 340px;
}

.slick-list {
  overflow: inherit;
}
.orButton span {
  color: #53575a;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  background: #f2ede5;
  display: inline-block;
  position: relative;
  z-index: 2;
}
.orButton:before {
  content: "";
  width: 100%;
  border-bottom: 1px dashed #71767a;
  height: 1px;
  left: 0px;
  top: 10px;
  display: block;
  position: absolute;
}
.googleLoginBUtton {
  cursor: pointer;
  border: 1px solid #2d6cb2;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 13px 0px;
}
.googleLoginBUtton span {
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #3c5c85;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.popUpWidthTrialEnd {
  width: calc(100% - 235px);
  left: inherit;
  right: 0px;
}
.sideBarVisible .popUpWidthTrialEnd {
  width: calc(100% - 52px);
  left: inherit;
  right: 0px;
}

.promoCodeFormRow {
  padding-left: 0px;
}
.promoCodeMain {
  padding: 0px;
  background: transparent;
}
.paddingBottom100 {
  padding-bottom: 100px;
}

ul.freeAccountListMEssage li {
  color: #f2e2c4;
  font-size: 18px;
  line-height: 35px;
  padding-left: 25px;
  position: relative;
}
ul.freeAccountListMEssage li:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #f2e2c4;
  left: 0px;
  top: 15px;
}
ul.freeAccountListMEssage {
  margin-bottom: 20px;
  padding-bottom: 0px;
}
.letUsCreate {
  font-size: 18px;
  color: #1c1d1f;
}
.letUsCreate span {
  font-weight: 600;
  text-transform: uppercase;
}
.titleWelcomeToAccount {
  color: #1c1d1f;
  font-size: 32px;
}
.maxWidth480 {
  max-width: 480px;
}
.linkToWebsite {
  color: #2d6cb2;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}
.linkToWebsite:hover {
  color: #2d6cb2;
}
.priceUsCurrencyText {
  font-size: 8px;
  color: #2d2e2f;
}
.paddingLeft10 {
  padding-left: 10px;
}
.planPurchaseTitle {
  font-size: 38px;
  font-family: "DM Serif Text", serif !important;
}
.width30Percent {
  width: 30%;
}

/***filter css added start****/
.responsiveListTitle {
  float: left;
  width: 100%;
}
.expenseListScreenMain h2.titleWithSidebar.responsiveListTitle,
h2.titleWithSidebar.viewIncomeTitle,
h2.titleWithSidebar.activityTitleMargin {
  margin-left: 0px;
  margin-right: 0px;
}
.responsiveListTableTop .tableTopLeft.df {
  width: auto;
  overflow: inherit;
  height: 34px;
  position: relative;
}
.responsiveListTableTop.expenseBoxCol .expenseTableTitle,
.responsiveListTableTop.incomeBoxcol .incomeTableMargin,
.responsiveListTableTop .activityTableTitle {
  margin-top: 0px;
}
.responsiveListTableTop .tableTopMain {
  margin-bottom: 10px;
}
.responsiveListTableTop.expenseBoxCol .dateWrap,
.responsiveListTableTop.incomeBoxcol .dateWrap,
.responsiveListTableTop.activityBoxCol .dateWrap {
  background: #fff;
  top: 45px;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  float: left;
  align-items: flex-start;
  display: none;
}
.responsiveListTableTop.expenseBoxCol .calendarDisplayBlock,
.responsiveListTableTop.incomeBoxcol .calendarDisplayBlock,
.responsiveListTableTop.activityBoxCol .calendarDisplayBlock {
  display: block;
}
.responsiveListTableTop .tableTopLeft .dateStart,
.responsiveListTableTop .tableTopLeft .dateEnd {
  width: 46%;
  float: left;
  flex-direction: column;
  border-bottom: 1px solid #3d4551;
  padding-left: 0px;
  margin-bottom: 15px;
}
.responsiveListTableTop .fromText,
.responsiveListTableTop .txtTo {
  color: #0466c8;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  padding-left: 10px;
  text-transform: uppercase;
}
.responsiveListTableTop .dateWrap input {
  font-size: 22px;
  color: #3d4551;
  font-weight: normal;
}
.dateWrap input {
  width: 100%;
}
.responsiveListTableTop .filterByDate {
  font-size: 18px;
  color: #3d4551;
  line-height: 24px;
  font-weight: 600;
  padding-left: 10px;
  margin-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  border-bottom: 1px solid #dfe1e2;
  margin-bottom: 0px;
}
.filterCancelButton.btn-outline.saveButton {
  font-size: 20px;
  color: #71767a;
  line-height: 24px;
  font-weight: 500;
  border: 0px;
  box-shadow: none;
  text-transform: capitalize;
  pointer-events: none;
}
.filterCancelButton.btn-outline.saveButton.buttonActive {
  pointer-events: inherit;
  cursor: pointer;
  color: #e85555;
}
.filterButton {
  font-weight: 500;
  font-size: 16px;
}
.responsiveListTableTop .dateWrap.calendarDisplayBlock {
  position: absolute;
  left: 0px;
  top: 83px;
  z-index: 10;
  background: rgba(52, 160, 164, 0.04);
  width: 100%;
}
.responsiveListTableTop.expenseBoxCol .dateWrap,
.responsiveListTableTop.incomeBoxcol .dateWrap,
.responsiveListTableTop.activityBoxCol .dateWrap {
  background: #fff;
  width: 266px;
  top: 50px;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  float: left;
  align-items: flex-start;
  width: 455px;
  border-radius: 4px;
}
.responsiveListTableTop .fromText,
.responsiveListTableTop .txtTo {
  padding-left: 0px;
  color: #112e53;
  font-size: 14px;
  margin-bottom: 5px;
}
.calendarDisplayBlock.calendarDisplayBlock .dateStartEnd {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0px;
}
.filterButtonRow {
  margin-left: 0px;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}
body .responsiveListTableTop .filterButton.btn-outline.saveButton {
  text-transform: none;
  color: #71767a !important;
  pointer-events: none;
  font-size: 20px;
}
body
  .responsiveListTableTop
  .filterButton.btn-outline.saveButton.applybuttonActive {
  text-transform: none;
  color: #2d6cb2 !important;
  pointer-events: inherit;
}
.filterText {
  color: #112e53;
  font-size: 20px;
}
.filterTextActive {
  color: #d96941;
}
.filterTextIconColoum .dateIcon {
  background: #fff;
  border-radius: 4px;
  float: left;
  display: flex;
  align-items: center;
  padding: 10px 10px;
}
.filterTextIconColoum .dateIcon svg {
  margin-right: 5px;
}

.tagFilterColum label {
  margin-right: 12px;
}
.tagFilterColum label input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 43px;
  height: 43px;
  background-repeat: no-repeat;
  background-size: 30px;
  border: 0px solid #fff;
  border-radius: 4px;
  background-position: center center;
  background-repeat: no-repeat;
}
.tagFilterColum label input.checked,
.tagFilterColum label input:hover {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 43px;
  height: 43px;
  border: 1px solid #0466c8;
  background: #faf8f4;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px;
}

.tagFilterColum label:nth-of-type(1) input {
  /* blue */
  background-image: url("../images/activityCircle.svg");
}
.tagFilterColum label:nth-of-type(2) input {
  /* green */
  background-image: url("../images/tag2.svg");
}
.tagFilterColum label:nth-of-type(3) input {
  /* yellow */
  background-image: url("../images/tag3.svg");
}
.tagFilterColum label:nth-of-type(4) input {
  /* red */
  background-image: url("../images/tag4.svg");
}
.tagFilterColum label:nth-of-type(5) input {
  /* red */
  background-image: url("../images/noTag.svg");
}
.responsiveListTableTop .tagFilterColum {
  padding-left: 20px;
}
.orerlayFilter {
  width: 1000%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 8;
  top: 0px;
  left: 0px;
}
.filterTextIconColoum {
  cursor: pointer;
}
.calendarDateCol {
  margin-left: 18px;
}
.startEndDateReadOnly {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  color: #112e53;
  align-items: center;
  background: #fff;
  padding: 10px 10px;
  border-radius: 4px;
}
.startEndDateReadOnly div {
  color: #112e53;
}
.startEndDateReadOnly div span {
  color: #6c7075;
}
.rangeText {
  color: #6c7075;
  font-size: 20px;
  margin-right: 5px;
}
.startEndDateReadOnly svg {
  width: 20px;
  margin-right: 10px;
}
.responsiveListTableTop .tableTopLeft.df {
  width: auto;
}

.typeListFilter .searchWrapper {
  border: 0px;
  border-bottom: 1px solid #3d4551;
  border-radius: 0px;
  position: relative;
  margin-bottom: 20px;
}
.dateWrap .typeTitleFilter {
  padding: 30px 20px 10px 20px;
}
.searchWrapper input.searchBox::placeholder {
  color: #3d4551 !important;
  opacity: 1 !important;
  font-size: 22px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-family: "Work Sans", sans-serif !important;
}
.typeTitleFilter .txtTo {
  text-transform: uppercase;
}
.typeListFilter .searchWrapper {
  padding-left: 0px;
  margin-bottom: 0px;
}
.typeListFilter .highlightOption,
.typeListFilter .multiSelectContainer li:hover {
  background: #ebf9fd;
  color: #3c4450;
}
.typeListFilter .searchWrapper:after {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 12px;
  border-left: 2px solid #565c65;
  border-bottom: 2px solid #565c65;
  transform: rotate(-45deg);
}
.typeListFilter .multiSelectContainer li {
  color: #3c4450;
  font-size: 22px;
  padding-right: 40px;
  position: relative;
}
.typeListFilter .multiSelectContainer ul {
  margin-top: -4px;
  border: 1px solid #c6cace;
}
.multiSelectContainer input.checkbox {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  top: 13px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.typeListFilter .multiSelectContainer li:hover input.checkbox,
.multiSelectContainer input.checkbox[type="checkbox"]:checked {
  background: url("../images/checkboxTick.svg") 4px 0px no-repeat;
}
.typeListFilter .multiSelectContainer li {
  padding-top: 5px;
  padding-bottom: 5px;
}
.clearTextInFilterCol {
  float: right;
  margin-right: 15px;
  color: #dfe1e2;
  font-weight: 400;
  cursor: pointer;
}
body.onTagHover .ant-tooltip {
  z-index: 99 !important;
  top: 440px !important;
  width: 160px;
  display: block;
}
/*body .ant-tooltip{display: none;}*/
body.onTagHover .ant-tooltip {
  display: block;
}

.dateStart .react-datepicker__input-container,
.dateEnd .react-datepicker__input-container {
  background: url("../images/calendar_filter_icon.svg") right top no-repeat;
}
.typeListFilter .searchWrapper:after {
  bottom: 12px;
  top: inherit;
}
.typeListFilter .highlightOption {
  background: #fff;
}
.activeClearButton {
  color: #2d6cb2;
}
.clearTextFilterColTopRow {
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}

.tableTopLeft .dateStart .react-datepicker__triangle {
  right: 0px !important;
}
.tableTopLeft .dateStart .react-datepicker__triangle {
  right: 101px !important;
  left: inherit !important;
}
.tableTopLeft .dateEnd .react-datepicker__triangle {
  right: 101px !important;
  left: inherit !important;
}

.positionRelative span.tagTooltip {
  display: none;
}
.positionRelative:hover span.tagTooltip {
  border-radius: 8px;
  background: #112e53;
  position: absolute;
  height: 55px;
  padding: 0px 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  left: 58px;
  top: -30px;
  width: 160px;
}
span.tagTooltip:before {
  content: "";
  width: 0px;
  height: 0px;
  border-color: transparent #112e53 transparent transparent;
  border-width: 11px 14px 11px 14px;
  position: absolute;
  z-index: 10;
  border-style: solid;
  left: -28px;
  top: 13px;
}

.uploadDisable {
  pointer-events: none;
  cursor: none;
}

.tableTopRight .lblTotal {
  text-transform: uppercase;
}
/***filter css added end****/

/*** dependent view start***/
.view_invoice_text {
  cursor: pointer;
  color: #0466c8;
  font-size: 16px;
  font-weight: 500;
}
/*** dependent view end**/

/**survey popUpstart start**/
.surveyTitle {
  color: #1c1d1f;
  font-size: 38px;
  font-family: "DM Serif Text", serif !important;
  display: flex;
  justify-content: space-between;
}
.surveyMainList label {
  position: relative;
  display: flex;
}
.rolePopUpTitle {
  font-size: 38px;
  color: #1c1d1f;
}
/*.surveyMainList label:before {position: absolute;content: '';width: 11px;height: 7px;border-left: 2px solid #fff;border-bottom: 2px solid #fff;left: 5px;transform: rotate(-45deg);top:9px; z-index: 5;}*/
.surveyTitleList,
.roleList {
  text-align: left;
  display: inline-block;
}
.surveyTitleList span,
.roleList span {
  color: #030303;
  font-size: 18px;
  margin-left: 10px;
  line-height: 27px;
}
.roleList label {
  display: flex;
}
/*.surveyTitleList input[type="radio"]{-webkit-appearance: none;-moz-appearance: none;appearance: none; border:1px solid #71767A; border-radius:4px; background:#fff; display: inline-block; float: left; width: 22px; height: 22px; min-width:22px;}
.surveyTitleList input[type="radio"].checked{-webkit-appearance: none;-moz-appearance: none;appearance: none; border:1px solid transparent; border-radius:4px; background:rgba(0, 150, 199, 0.99); display: inline-block; float: left;}*/
.skipButton {
  color: #0466c8;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 25px;
}
.buttonActive {
  background: #2d6cb2;
  border: 1px solid #2d6cb2;
}
.buttonBg {
  background: #71767a;
}
.roleList .surveyTitleList {
  align-items: inherit;
}
.surveyPopUpButtonRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pageCount {
  color: #71767a;
  font-size: 18px;
}
.roleList.surveyMainList label:before {
  display: none;
}
/**survey popUpstart end**/

.advocordLearningCenter {
  color: #000;
  font-size: 16px;
}
.advocordLearningCenter a {
  color: #2d6cb2;
}
.closePopUpIcon {
  position: absolute;
  right: 0px;
}
.havePoromoCode {
  color: #0466c8;
  font-size: 16px;
  cursor: pointer;
  margin-left: 15px;
  font-weight: normal;
}

.promoInfo {
  color: #3d4551;
  font-size: 16px;
  color: #3d4551;
  padding-top: 10px;
  margin-bottom: 10px;
}
.onlyPromoScreen {
  background: #fff;
  max-width: 396px;
  margin-left: 50px;
}
.havePoromoCode .tagTooltip_promo {
  display: none;
}
.promocodeDisable:hover .tagTooltip_promo {
  border-radius: 8px;
  background: #112e53;
  position: absolute;
  height: 55px;
  padding: 0px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  left: 180px;
  top: -13px;
  width: 230px;
}
.promocodeDisable:hover .tagTooltip_promo:before {
  content: "";
  width: 0px;
  height: 0px;
  border-color: transparent #112e53 transparent transparent;
  border-width: 11px 14px 11px 14px;
  position: absolute;
  z-index: 10;
  border-style: solid;
  left: -28px;
  top: 13px;
}
.promocodeDisable {
  color: #71767a;
  position: relative;
  cursor: default;
}

.editBillingCol .tagTooltip_promo {
  display: none;
}
.editBillingCol.promocodeDisable:hover .tagTooltip_promo {
  border-radius: 8px;
  background: #112e53;
  position: absolute;
  height: 55px;
  padding: 0px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
  left: 32px;
  top: -13px;
  width: 200px;
  z-index: 10;
}
.editBillingCol.promocodeDisable:hover .tagTooltip_promo:before {
  content: "";
  width: 0px;
  height: 0px;
  border-color: transparent #112e53 transparent transparent;
  border-width: 11px 14px 11px 14px;
  position: absolute;
  z-index: 10;
  border-style: solid;
  left: -28px;
  top: 13px;
}
.editBillingCol.promocodeDisable {
  color: #71767a;
  position: relative;
  cursor: default;
}

.referralColPopUP {
  padding-left: 32px;
  margin-bottom: 20px;
  width: 80%;
  float: left;
}
.referralColPopUP input::placeholder,
.referralColPopUP .inputColum24size {
  font-size: 18px;
  line-height: 20px;
}

/**new notes css added**/
.listAddBoxNote {
  padding: 10px 20px;
}
.listIconColum {
  min-width: 100px;
  display: flex;
}
.listNoteBox {
  width: 435px;
  background: #ffffff;
  box-shadow: 8px 22px 53px rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0px;
  top: 30px;
  border-radius: 4px;
  z-index: 1;
}
.listNoteBox:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-color: transparent transparent #fff transparent;
  border-width: 14px;
  border-style: solid;
  top: -28px;
  right: 15px;
}
.noteBoxTitle {
  text-align: left;
  font-size: 20px;
  color: #3d4551;
  line-height: 32px;
  font-weight: 600;
  padding: 20px 18px;
  border-bottom: 1px solid #f1f3f6;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.listNote {
  background: #fbfcfd;
  font-size: 16px;
  padding: 5px;
  border: 1px solid #dfe1e2;
  border-radius: 4px;
}
.listNote::placeholder {
  font-size: 16px;
  color: #a9aeb1;
}
.listNoteCol {
  overflow: auto;
  max-height: 300px;
}
.listnoteMainCol {
  background: #fbfcfd;
  width: 100%;
  text-align: left;
  padding: 10px 20px;
}
.listNoteTitle {
  font-size: 16px;
  font-weight: 400;
  color: #3d4551;
  margin-bottom: 10px;
}
.listNoteDateTime {
  font-size: 14px;
  font-weight: 500;
  color: #71767a;
  padding-right: 60px;
}
.noteSaveButton {
  background: #0466c8;
  font-style: normal;
  font-weight: 500;
  min-width: 86px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #fff;
  display: inline-block;
  padding: 0px 16px;
  height: 32px;
  cursor: pointer;
}

.viewNoteBox .listnoteMainCol {
  background: transparent;
}
.viewNoteBox .listNoteTitle {
  font-size: 20px;
  font-weight: 400;
  color: #3d4551;
  margin-bottom: 10px;
  line-height: 24px;
}
.viewNoteBox .listNoteDateTime {
  justify-content: flex-start;
}
.viewNoteBox .listNoteDateTime .noteItemCreatedDate {
  font-size: 14px;
  font-weight: 500;
  color: #3d4551;
}
.viewNoteBox .listNoteDateTime .noteCreated {
  font-size: 22px;
  font-weight: 500;
  color: #3d4551;
  margin-right: 10px;
}
.viewNoteBox .addNoteCol .listNote {
  background: #f9f6f0;
  border: 1px solid #3d4551;
  padding: 15px;
  padding-right: 60px;
}
.viewNoteBox .listNote {
  font-size: 22px;
}
.viewNoteBox .listNote::placeholder {
  font-size: 22px !important;
  color: #3d4551 !important;
  font-weight: 400 !important;
  font-family: "Work Sans", sans-serif !important;
}
span.notesSaveButton {
  width: 103px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background: #2d6cb2;
  padding: 8px 12px;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
}
span.notesCancelButton {
  width: 103px;
  font-size: 18px;
  color: #2d6cb2;
  cursor: pointer;
  background: transparent;
  padding: 8px 12px;
  text-align: center;
  display: inline-block;
}
.seeOlderNotes {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #2d6cb2;
  display: flex;
  align-items: flex-start;
}
.seeOlderNotes span {
  display: inline-block;
  line-height: 16px;
  cursor: pointer;
}
.notsAddNoteRow {
  display: flex;
  justify-content: space-between;
}
span.viewScreenAddNote svg {
  cursor: pointer;
}
span.noteListCount {
  background: #d96941;
  color: #fff;
  width: 15px;
  height: 15px;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: -4px;
  top: -6px;
}

.commentExpenseTab {
  width: auto;
  float: left;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #112e53;
  padding: 7px 20px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-right: 0px;
  text-align: center;
  background: #fff;
}
.activecommentExpenseTab {
  background: #f2a950;
  color: #112e53;
  border-bottom: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.activecommentExpenseTab:before {
  content: "";
  width: 0px;
  height: 0;
  border-color: #f2a950 transparent transparent;
  bottom: -20px;
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  position: absolute;
  left: 44%;
  z-index: 1;
}
.expenseDetails .commentSetionViewScreen label {
  width: 75px;
  text-transform: uppercase;
}
.commentSetionViewScreen label {
  width: 75px;
  text-transform: uppercase;
}
.commentSetionViewScreen label {
  font-weight: 600;
  font-size: 16px;
  color: #112e53;
  line-height: 24px;
}
.showLabel {
  display: block;
  color: #112e53;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}
.clearBoth {
  clear: both;
}
.inventoryRow .linkToWebsite {
  margin-left: 0px;
}
.payRow .twoColumm {
  padding-left: 182px;
}
.noteEditDeleteCol {
  width: auto;
  position: absolute;
  right: 0px;
  top: 2px;
}
.noteEditDeleteCol span {
  margin-left: 12px;
  cursor: pointer;
}
.inventoryRow.commentSetionViewScreen {
  padding-left: 75px;
}
.viewNoteBox .listBgRow:nth-child(odd) .listnoteMainCol {
  background: #f4efe7;
}
.viewNoteBox .listBgRow .listnoteMainCol {
  padding-left: 15px;
  padding-right: 15px;
}
.rightPanelWidhtSidebar .inventoryNote {
  margin: 20px 0px;
  position: static;
  text-align: center;
}
.inventoryNote.noData strong {
  font-size: 22px;
  width: 100%;
}
.linkToWebsite {
  margin-left: 222px;
  padding-left: 0px;
}
.ViewMedicationMain .MedicationDetails .commentSetionViewScreen label {
  width: 78px;
}
.priceListedIs {
  color: #000000;
  font-size: 16px;
  padding-left: 5px;
  padding-bottom: 5px;
}
.inventoryBox .Beneficiary .pName,
.inventoryBox .CoOwnerMainBox .pName,
.personList .personInfo .pName {
  width: auto;
  word-break: break-all;
  padding-right: 10px;
}
.inventoryBox .Beneficiary .pEmail,
.inventoryBox .CoOwnerMainBox .pEmail {
  word-break: break-all;
}
.assetColTitle {
  max-width: 85%;
  word-break: break-all;
  float: left;
}
.titleWithSidebarDashboard span {
  word-break: break-all;
  line-height: 48px;
}
.noExpenseTitle span {
  word-break: break-all;
}
.dependentTabContainerCol .generalInfoProfileImg {
  min-width: 84px;
}
.generalInfoUserName {
  word-break: break-all;
}
.bottomRightPart {
  min-width: 90px;
}
.personInfo .pEmail {
  line-height: 24px;
}
.inventoryBox .pEmail {
  width: auto;
  max-width: 600px;
}
.paddingLeftNone {
  padding-left: 0px;
}

/********Sign in screen css 1440px end*******************/
@media screen and (max-width: 1550px) {
  .errorRelativeRow .errorMessage {
    line-height: 16px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1440px) {
  .generaInfoRow {
    padding-left: 260px;
  }
  .billLeft {
    width: 100%;
    max-width: 660px;
  }
}

@media screen and (max-width: 1415px) {
  .dashboardColum_L {
    width: 110px;
  }
  .dashboardColum_R {
    width: calc(100% - 110px);
  }
}

@media screen and (max-width: 1366px) {
  /*.myAccountFormFiled .firstLastNameSpace, .myAccountFormFiled, .formRowGap {margin: 0 0px 5px 0px;}*/
  .myAccountFormFiled .arressFirstRow {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1365px) {
  .generaInfoRow {
    padding-left: 220px;
  }
  .billLeft {
    width: 100%;
    max-width: 610px;
  }
  .personalInfoNameRow,
  .formRow {
    padding-right: 45px;
  }
}

@media screen and (max-width: 1280px) {
  .myAccountFormFiled .firstLastNameSpace,
  .myAccountFormFiled,
  .formRowGap {
    margin: 0 0px 5px 0px;
  }
}

.ant-tooltip-inner,
.ant-tooltip {
  border-radius: 8px !important;
}
.ant-tooltip {
  z-index: 99 !important;
}
body .fileType {
  width: 31px;
  margin-right: 15px;
  max-height: 35px;
}
body .fileType img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  /******default css responsive start*****/
  .transparentLayer {
    width: 100%;
  }
  body .marginNone600 {
    margin: 0px !important;
  }
  .marginBottom20_600 {
    margin-bottom: 20px;
  }
  .saveButton,
  .saveButtonDisable {
    min-width: inherit;
  }
  .cancelButton1 {
    padding: 0px 20px;
    width: inherit;
  }
  .displayNone_600 {
    display: none;
  }
  .hideDesktopShowMobile {
    display: inline-block;
  }
  .showDesktophideMobile {
    display: none;
  }
  .exclamationErrorIcon {
    line-height: 15px;
  }
  .rightPanelWidhtSidebarContainer {
    min-height: calc(100vh - 51px);
  }
  .dependentBorderBox {
    min-height: calc(100vh - 169px);
  }
  /******default css responsive end*****/
  /*********Navigation start*********/
  .sideBarNav {
    overflow: inherit;
  }
  .sidebarLogoNav {
    background: #112e53;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .navIcon {
    top: 20px;
  }
  .sideBarNav.sideBarIconOnly {
    max-width: 100%;
  }
  .sideBarNav ul {
    top: 49px;
    background: #112e53;
  }
  .mainRightSidebare {
    margin-top: 49px;
  }
  .navOverlay.overlayShow,
  .navOverlay {
    top: 49px;
  }
  .sideBarNav.sideBarIconOnly ul {
    min-width: 275px;
  }
  .sideBarNav.sideBarIconOnly ul {
    padding: 0px;
  }
  .sideBarNav ul li {
    padding-left: 10px;
  }
  .sideBarNavIcon,
  .sideBarNav.sideBarIconOnly span.sideBarNavIcon {
    width: 23px;
  }
  .sideBarVisible .mainRightSidebare {
    width: 100%;
  }
  .versionNameNavBottom {
    display: none;
  }
  .sidebarLogoNav span.sidebarLogo.animationall {
    display: block;
  }
  .sideBarIconOnly .navIcon {
    margin-left: 10px;
  }
  .sidebarLogoNav {
    margin-bottom: 0px;
  }
  h2.titleWithSidebar:before {
    height: 52px;
  }
  .navIcon {
    margin-left: 0px;
  }
  /*********Navigation end*********/

  /*****welcome screen start******/
  .rightPanelWidhtSidebar.bgWhite.fullWidth.marginTop12 {
    margin-top: 0px;
  }
  .welcomeTitle {
    margin-top: 0px;
  }
  .welcomeBg .titleWithSidebar {
    font-size: 24px;
    line-height: 32px;
  }
  .welcomeBg {
    background: #f3efe8;
  }
  .descriptionrightBox ul li {
    display: block;
    align-items: center;
    position: relative;
    padding-right: 0px;
    padding-left: 30px;
  }
  .descriptionrightBox ul li span.welcomeRightTick {
    position: absolute;
    left: 1px;
    top: 5px;
  }
  .descriptionrightBox ul li span.welcomeListText {
    margin-left: 0px;
    padding-left: 15px;
    padding-right: 5px;
    min-width: 315px;
  }
  .welcomeDescriptionText {
    line-height: 25px;
  }
  .maxWidth200.welcomeScreenButton {
    height: 48px;
    width: 100%;
    font-size: 16px;
    max-width: 158px;
  }
  .welcomeScreenButton {
    height: 48px;
    width: 100%;
    font-size: 16px;
  }
  .descriptionrightBox {
    margin-top: 20px;
  }
  .descriptionrightBox ul li span.welcomeListText {
    margin-left: 5px;
    padding-left: 0px;
  }
  .descriptionrightBox ul li span.welcomeListText {
    font-size: 14px;
    line-height: 22px;
    color: #42403e;
  }
  .welcomeDescriptionText1 {
    margin-bottom: 5px;
  }
  .welcomeDescriptionText2 {
    margin-top: 0px;
  }
  .welcomeButtonRow {
    margin-top: 20px;
  }
  /*.sidebarLogoNav{background:#112E53; padding-top: 6px; padding-bottom:10px;}*/
  /*.sidebarLogo img{max-width:134px;}*/
  /*.navIcon {top:14px;}*/
  /*.navOverlay, .sideBarNav ul, .navOverlay.overlayShow, .sideBarNav.sideBarIconOnly ul{top:44px;}*/
  /*.sideBarNav ul{background:#112E53;}*/
  /*.bgCol{background:#112E53; float: left; height: 100%;min-height: 100vh;}*/
  body .paddingLeftPaddingRight35 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .allRightReserveText,
  .allRightReserveText .signBottomLink,
  .allRightReserveText {
    font-size: 13px;
    padding-right: 0px;
  }
  .allRightReserveText {
    justify-content: center;
  }
  /*.imgLeftSignCol{display: none;}*/
  .logoCol {
    height: 44px;
    text-align: center;
    display: block;
  }
  .logoCol img {
    max-width: 134px;
    padding-left: 0px;
    margin: 12px 10px 0px;
  }
  .signColCenter {
    border-radius: 0px;
  }
  .marginBottom80 {
    margin-bottom: 30px;
  }
  .passwordMatchBox ul li,
  li.passowrdListOfCondition:nth-child(2),
  li.passowrdListOfCondition:nth-child(4) {
    width: 100%;
  }
  body .signColCenter {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .signInFormBox {
    min-height: calc(100vh - 64px);
  }
  .allRightReserveText {
    position: absolute;
    bottom: 5px;
  }
  .allRightReserveText.positionNspacing {
    position: static;
  }
  .joinNowText span {
    color: #0096c7;
    font-weight: 600;
  }
  .inputColum24size,
  .inputColum,
  .react-tel-input .form-control {
    font-size: 16px;
    line-height: 20px;
  }
  .welcomeBg:before {
    background-size: auto 222px;
  }
  .welcomeTitle {
    font-size: 42px;
    line-height: 48px;
    color: #112e53;
    max-width: 303px;
  }
  .welcomeTitle {
    margin: 0px 0px 20px 0px;
  }
  .knowMore {
    color: #0096c7;
    font-size: 16px;
    line-height: 25px;
    cursor: pointer;
  }
  body .rightPanelWidhtSidebarContainer.welcomeBg {
    min-height: calc(100vh - 44px);
  }
  .welcomeDescriptionText2 {
    display: none;
  }
  .medicationStatus .yesNo {
    width: 36px;
  }
  .maxWidth200.welcomeScreenButton {
    max-width: 150px;
  }
  /*****welcome screen end******/

  /******my account View start*******/
  .myAccountViewMain h2.titleWithSidebar {
    width: 100%;
    float: left;
    display: inline-block;
    margin-bottom: 17px;
  }
  .myAccountViewMain h2.titleWithSidebar span {
    position: static;
  }
  .myAccountViewMain .dependentProfileMainViewBox {
    padding: 10px 0px;
  }
  .myAccountViewMain .geneInfo_label {
    width: 90px;
  }
  .myAccountViewMain .generaInfoRow {
    padding-left: 115px;
    display: flex;
    align-items: flex-start;
  }
  .myAccountViewMain .billWrap .generaInfoRow {
    min-height: 34px;
  }
  .myAccountViewMain .geneInfo_label {
    font-size: 12px;
    line-height: 17px;
  }
  .myAccountViewMain .geneInfo_value {
    font-size: 16px;
    line-height: 17px;
    white-space: inherit;
    text-overflow: inherit;
    overflow: inherit;
    overflow-wrap: break-word;
  }
  body .myAccountViewMain h3 {
    font-size: 16px;
    margin: 0px;
  }
  body .myAccountViewMain .generaInfoRow > .downloadMargin.geneInfo_value {
    -margin-top: -5px;
  }
  /******my account View end*******/

  /*****signUP flow responsive start***************/
  .bgCol {
    background: url("../images/signUpBgResponsive.png") right top no-repeat
      #112e53;
    background-size: auto 333px;
    width: 100%;
    min-height: 100vh;
    float: left;
  }
  .singnUpDisplay_600 {
    display: none;
  }
  body .logoCol {
    padding-left: 0px;
  }
  .imgLeftSignCol {
    width: 308px;
    font-size: 56px;
    padding-left: 40px;
    margin-top: 60px;
  }
  .imgLeftSignCol {
    align-items: flex-start;
  }
  body .logoLeft {
    text-align: left;
  }
  .leftDescriptions {
    line-height: 26px;
  }
  .imgLeftSignCol {
    flex-direction: column;
    height: auto;
    min-height: calc(100vh - 88px);
  }
  .loginSignButton {
    background: #2d6cb2;
    width: 109px;
    height: 40px;
    font-size: 14px;
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    text-transform: uppercase;
  }
  .signUpLoginButtonRow {
    margin-bottom: 20px;
    display: inline-flex;
  }
  .forgotPassWidth {
    width: 100%;
  }
  .loginMain .leftDescriptions {
    margin-bottom: 15px;
  }
  .errorTextContent {
    font-size: 16px;
    line-height: 16px;
  }
  /*****signUP flow responsive end***************/

  /*****Nav hide option start******/
  .navItemHide {
    display: none;
  }
  .sideBarNav {
    z-index: 111;
  }
  /*****Nav hide option end******/

  /********add medical create screen start**********/
  .dependentBorderBox {
    background: transparent;
  }
  .titleWithSidebar {
    font-size: 32px;
  }
  .medicalImageUploadCol {
    padding-left: 0px;
  }
  .medicationImg {
    position: static;
  }
  .addIncomesWrap .formRow {
    padding: 0px;
  }
  .medicationImg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
  }
  body
    .medicationImg
    .generalInfoProfileImg.dependentPro
    label.custom-file-upload:before {
    background-size: 25px 22px;
    width: 47px;
    height: 47px;
    content: "" !important;
    border-radius: 50%;
    left: inherit;
    right: -20px;
    bottom: -20px;
  }
  .payRow .width50 + .width50 label {
    padding-left: 0px;
  }
  .prescribeDoctorLable {
    max-width: 100%;
  }
  .doctorPrescribingCol .doctorPrescribing__placeholder {
    font-size: 16px;
    line-height: 16px;
  }
  .doctorPrescribingCol .doctorPrescribing__value-container {
    align-items: flex-end;
    display: flex;
  }
  .doctorPrescribingCol .doctorPrescribing__indicators {
    align-items: flex-end;
  }
  .doctorPrescribingCol .doctorPrescribing__option--is-selected,
  .doctorPrescribingCol .doctorPrescribing__option--is-focused,
  .doctorPrescribingCol .doctorPrescribing__option {
    color: #71767a;
    font-size: 16px;
    padding-left: 20px;
  }
  .doctorPrescribingCol .doctorPrescribing__option:last-child:before {
    font-size: 20px;
  }
  .doctorPrescribingCol .doctorPrescribing__single-value {
    font-size: 16px;
    line-height: 20px;
  }
  textarea.txtNote {
    background: transparent;
    font-size: 16px;
  }
  .attachTxt {
    font-size: 16px;
  }
  .dropZoneWrap {
    border: 1px dashed #818488;
  }
  .dependentBorderBox {
    padding: 5px;
  }
  .generalInfoProfileImg .imageOptionList {
    width: 200px;
  }
  .DependentStep2FormCol {
    min-height: inherit;
  }
  .progressWrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .medicalForm {
    margin-top: 50px;
  }
  .medicationImg
    .generalInfoProfileImg.dependentPro
    label.custom-file-uploadImage:before {
    display: none;
  }
  .medicalForm .buttonRow .marginLeft20 {
    margin-left: 10px;
  }
  .medicalStatus_600 {
    display: inline-block;
  }
  .medicalStatus_600 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
  }
  .medicalStatus_600 .medicationStatus {
    width: 135px;
  }
  body .addExpensesWrap h3,
  body .addIncomesWrap h3,
  body .addActivityWrap h3 {
    font-weight: 500;
  }
  span.MuiSwitch-root {
    width: 30px;
    height: 16px;
  }
  .yesNo {
    font-size: 12px;
  }
  span.MuiSwitch-thumb {
    width: 12px;
    height: 12px;
  }
  .medicationStatus label {
    font-size: 12px;
  }

  .doctorAddForm600 {
    margin-top: 50px;
  }
  .marginBottom20_600 {
    margin-bottom: 20px !important;
  }
  .doctorTitleMargin_600 {
    margin-top: 20px;
  }
  .doctorRowMarginB_600 {
    margin-bottom: 20px;
  }
  .doctorAddForm600 .width40,
  .doctorAddForm600 .countryState.width60 {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 0px;
  }
  .unitMarginTop20 {
    margin-top: 20px;
  }
  body .doctorAddForm600 .addressRow,
  body .doctorAddForm600 .addressRow .countryState {
    padding-left: 0px;
  }
  .doctorAddForm600 .paddingRight16,
  .doctorAddForm600 .paddingLeft16 {
    padding: 0px;
  }
  .doctorAddForm600 .paddingRight16 {
    margin-bottom: 20px;
  }
  .myProfilePhoneCol1_600 .phoneDropDownResponsiveCol,
  .myProfilePhoneCol2_600 .phoneDropDownResponsiveCol {
    margin-bottom: 20px;
  }
  .doctorAddForm600 .dependentStepFormRow .react-tel-input {
    margin-bottom: 0px;
  }
  .doctorAddForm600 .buttonRow600.positionAbsouteBottom {
    position: static;
  }
  /******** add medical create screen end**********/

  /********list medical screen start**********/
  .MuiTabs-flexContainer button span {
    font-size: 16px;
  }
  .MuiTabs-flexContainer button {
    padding: 10px 12px;
  }
  .tableBody.responsiveTalbe td .medicationinstruction {
    display: none;
  }
  .medicalTableTitle .instructions {
    display: block;
  }
  .responsiveTalbe.medicalTableTitle td:nth-of-type(1),
  .responsiveTalbe.medicalTableTitle td:nth-of-type(4) {
    display: none;
  }
  .responsiveTalbe.medicalTableTitle td:nth-of-type(1):before {
    content: "DATE";
  }
  .responsiveTalbe.medicalTableTitle td:nth-of-type(2):before {
    content: "NAME";
    visibility: visible;
  }
  .responsiveTalbe.medicalTableTitle td:nth-of-type(3):before {
    content: "START DATE";
  }
  .responsiveTalbe.medicalTableTitle td:nth-of-type(5):before {
    content: "prescribing";
  }
  span.h100.df.aic.medicalDateCol_600.cursorPointer {
    display: inline-block;
  }
  .statusWithDate {
    margin-left: 5px;
    vertical-align: -4px;
    height: 18px;
  }
  .statusWithDate svg {
    max-width: 15px;
  }
  .medicalTableTitle tr {
    padding-right: 0px;
  }
  .medicalTableTitle.responsiveTalbe td.notToPrintMed {
    display: none;
  }
  .medicalTableTitle tr {
    display: flex;
    flex-direction: column;
  }
  .medicalTableTitle.responsiveTalbe td:nth-child(3) {
    order: -1;
  }
  .MuiTab-root {
    min-height: 44px;
  }
  /*.medicalListMain .plusIcon span {width: 27px; height:27px;font-size: 25px;border-radius: 50%;font-weight: normal;line-height: 16px;height: 17px;display: flex;align-items: center;justify-content: center;}*/
  .medicalListMain .plusIcon span {
    font-size: 30px;
    border-radius: 50%;
    line-height: 23px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .medicalListMain .plusIcon span.displayNone600 {
    display: none;
  }
  .medicalListMain .btn-primary.saveButton {
    border-radius: 50%;
  }
  .medicalTitleMargin {
    margin: 15px 0px 5px 0px;
  }
  .marginBottom45_600 {
    margin-bottom: 45px;
  }
  .titleWithSidebar {
    font-size: 24px;
    line-height: 24px;
  }
  .responsiveTalbe.tableBody tr {
    border-radius: 0px;
  }
  .responsiveTalbe.tableBody tr:nth-child(even) {
    background: #faf8f4;
  }
  .responsiveTalbe.medicalTableTitle
    tr
    td:nth-child(2)
    .ellipsis.medLongText.marginTop15 {
    display: none;
  }
  .responsiveTalbe.medicalTableTitle td:before {
    left: 10px;
  }
  .responsiveTalbe.medicalTableTitle tr td:nth-child(2) span {
    font-weight: 400 !important;
  }

  .responsiveTalbe.doctorTableTitle tr {
    display: block;
  }
  .responsiveTalbe.doctorTableTitle tfoot {
    display: none;
  }
  .responsiveTalbe.doctorTableTitle td:nth-of-type(1) {
    display: block;
    float: left;
  }
  .responsiveTalbe.doctorTableTitle td:nth-of-type(1):before {
    content: "SPECIALTY";
  }
  .responsiveTalbe.doctorTableTitle td:nth-of-type(2):before {
    content: "NAME";
    visibility: visible;
  }
  .responsiveTalbe.doctorTableTitle td:nth-of-type(3):before {
    content: "PRACTICE OR HOSPITAL GROUP";
    visibility: visible;
    line-height: 15px;
    top: 10px;
    white-space: break-spaces;
  }
  .responsiveTalbe.doctorTableTitle td:nth-of-type(4):before {
    display: none;
  }
  .responsiveTalbe.doctorTableTitle td:nth-of-type(3) {
    min-height: 40px;
  }
  .responsiveTalbe.doctorTableTitle tr td:nth-child(1) span {
    text-align: left;
  }
  .responsiveTalbe.doctorTableTitle tr td,
  .responsiveTalbe.medicalTableTitle tr td {
    width: 100%;
  }
  .responsiveTalbe.medicalTableTitle .statusWithDate {
    display: inline-block;
  }
  .medicalListMain .filterDorpDownMain {
    left: 0px;
  }
  .medicalListMain .filterDorpDownMain .ellipsisFilter {
    height: inherit;
    border-radius: 0px;
    width: 200px;
    background: transparent;
    margin: 0px;
    padding: 0px;
    display: inline-block;
    float: left;
    position: static;
  }
  .medicalListMain .filterDorpDownMain .ellipsis:before {
    display: none;
  }
  .doctorNameTdWidth {
    width: 30%;
  }
  /********list medical screen end**********/

  /******** medical view screen start**********/
  .ViewMedicationMain .padding3040,
  .ViewMedicationMain .dependentBorderBox,
  .ViewDoctorMain .dependentBorderBox,
  .ViewDoctorMain .padding3040 {
    padding: 0px;
  }
  .ViewMedicationMain .MedicationDetails .paddingLeft220,
  .ViewDoctorMain .docotorDetails .paddingLeft220 {
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .ViewMedicationMain .MedicationDetails .positionAbsoute,
  .ViewDoctorMain .docotorDetails .positionAbsoute {
    position: static;
    margin-bottom: 15px;
  }
  .ViewMedicationMain .imgBoxMedicalView {
    margin: 0px auto;
  }
  .ViewMedicationMain .titleWithSidebar span svg {
    display: inline-block;
  }
  .ViewMedicationMain .dependentSubTitle {
    margin-left: 5px;
  }
  .ViewMedicationMain .imgBoxMedicalView {
    width: 141px;
    height: 137px;
  }
  .incomeBoxcol {
    padding: 5px;
    float: left;
    width: 100%;
  }
  .incomeBoxcol .incomeTableTitle {
    margin-top: 0px;
  }
  .ViewMedicationMain .dependentBorderBox,
  .ViewDoctorMain .dependentBorderBox {
    padding: 20px 15px;
    border: 0px;
  }
  .ViewMedicationMain .MedicationDetails label,
  .ViewDoctorMain .docotorDetails label {
    width: 100%;
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.15px;
  }
  .ViewMedicationMain .MedicationDetails > div,
  .ViewDoctorMain .docotorDetails > div {
    flex-direction: column;
  }
  .ViewMedicationMain .MedicationDetails > div > div,
  .ViewDoctorMain .docotorDetails > div > div {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0025em;
    color: #3d4551;
    flex-direction: column;
  }
  .ViewMedicationMain .MedicationDetails > div,
  .ViewDoctorMain .docotorDetails > div {
    padding: 7px 0px;
  }
  .ViewMedicationMain .MedicationDetails,
  .ViewDoctorMain .docotorDetails {
    font-size: 18px;
    line-height: 24px;
  }
  .ViewMedicationMain .MedicationDetails > div,
  .ViewDoctorMain .docotorDetails > div {
    flex-direction: column;
  }
  .ViewMedicationMain .dependentBorderBox,
  .ViewDoctorMain .dependentBorderBox {
    padding: 5px 15px 20px 15px;
  }
  .ViewMedicationMain .dependentSubTitle,
  .ViewDoctorMain .dependentSubTitle {
    margin-bottom: 0px;
  }
  .ViewMedicationMain .MedicationDetails > div,
  .ViewDoctorMain .docotorDetails > div {
    padding: 0px;
    margin-bottom: 20px;
  }
  /*.ViewMedicationMain .MedicationDetails label{color:#0352A0;}*/
  .MedicationDetails > div > div,
  .docotorDetails > div > div {
    color: #3d4551;
    font-size: 16px;
  }
  .MedicationDetails .df.marginBottom50,
  .docotorDetails .df.marginBottom50 {
    margin-bottom: 20px;
  }
  .ViewMedicationMain .editButton,
  .ViewDoctorMain .editButton {
    margin-left: 0px;
    display: inline-block;
  }
  .ViewMedicationMain .editButton .btn-outline.saveButton,
  .ViewDoctorMain .editButton .btn-outline.saveButton {
    background: transparent !important;
    border: 0px;
    box-shadow: none;
  }
  .ViewMedicationMain .editButton .btn-outline.saveButton span,
  .ViewDoctorMain .editButton .btn-outline.saveButton span {
    display: none;
  }
  .ViewMedicationMain .editButton button.btn-outline.saveButton.marginRight20,
  .ViewDoctorMain .editButton button.btn-outline.saveButton.marginRight20 {
    padding: 9px 13px;
  }
  body .fileInfo h5 {
    margin-bottom: 0px;
  }
  .inventory_value {
    width: 100%;
    float: left;
  }
  .addMedicalTitle .positionAbsoute {
    left: 0px;
    top: 34px;
  }
  h2.titleWithSidebar.addIncomeTitle.addMedicalTitle {
    margin: 15px 0px;
  }
  .ViewDoctorMain h2.titleWithSidebar.addIncomeTitle {
    margin-bottom: 25px;
  }
  .ViewDoctorMain .addIncomeTitleError .positionAbsoute {
    top: 35px;
  }

  /******** medical view screen end**********/

  /** medical popUp start*****/
  .buttonRow_600ChildCenter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .popUpYesButton {
    font-size: 12px;
  }
  .areYouText.areYouDmFont {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #2d2e2f;
    font-family: "Work Sans", sans-serif;
  }
  .popUpInviteUserMessage {
    font-size: 16px;
    line-height: 24px;
    color: #565c65;
    font-weight: 400;
  }
  /** medical popUp end*****/

  /******Expense Add start**********/
  .addExpenseScreenMain .personalInfoNameRow,
  .formRow {
    padding-right: 0px;
  }
  .addExpenseScreenMain .formRow.fullWidth.formRowGap {
    margin-bottom: 20px;
  }
  .titleWithSidebar span svg {
    display: inline-block;
  }
  .addExpenseScreenMain .formRow label {
    color: #112e53;
  }

  .addIncomesWrap h3.addIncomeTitle span,
  .addIncomesWrap h3.addIncomeTitle {
    margin: 0px;
  }
  .addIncomesWrap .formRow.fullWidth.formRowGap {
    margin: 0px 0px 12px 0px;
  }
  .addIncomesWrap .payRow .width50 {
    margin-bottom: 12px;
  }
  .formRow.fullWidth.formRowGap.payRow > div + div {
    margin-bottom: 0px;
  }
  .addExpenseScreenMain .addActivityWrap .formRow label.tagLabel600 {
    position: relative;
  }
  .activityBorderBox600 .addExpWrap {
    padding: 10px 5px;
  }
  .dateActivitymargin {
    margin-bottom: 4px;
    display: inline-block;
  }
  .expesneFormTop {
    margin-top: 35px;
  }
  .dependentResidencCol .dependentResidence__option,
  .dependentResidencCol .dependentResidence__option--is-focused,
  .dependentResidencCol .dependentResidence__option--is-selected {
    color: #71767a !important;
  }
  .addIncomesWrap .amountWrap .currency {
    top: 0px;
  }
  .addIncomeTopMargin_600 {
    margin-top: 50px;
  }
  .expesneFormTop {
    margin-top: 50px;
  }
  /******Expense Add end**********/

  /******Expense View start**********/
  .ViewExpenseMain .dependentBorderBox,
  .viewIncomeMain .dependentBorderBox {
    background: transparent;
    margin-top: 20px;
  }
  .ViewExpenseMain .padding3040,
  .ViewExpenseMain .dependentBorderBox {
    padding: 0px;
    margin-left: 0px;
  }
  .ViewExpenseMain .titleWithSidebar span svg {
    display: inline-block;
  }
  .ViewExpenseMain .dependentSubTitle,
  .activityViewMain .dependentSubTitle,
  .viewIncomeMain .dependentSubTitle {
    color: #122e52;
    margin-left: 0px;
    margin-bottom: 0px;
    line-height: 23px;
    margin-top: -4px;
  }
  .ViewExpenseMain .dependentBorderBox {
    padding: 20px 15px;
    border: 0px;
  }
  .activityViewMain.dependentBorderBox {
    padding: 0px 15px;
    border: 0px;
  }
  .ViewExpenseMain .expenseDetails label,
  .viewIncomeMain .expenseDetails label,
  .activityViewMain .expenseDetails label {
    width: 100%;
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.15px;
    color: #a9aeb1;
  }
  .ViewExpenseMain .expenseDetails > div,
  .activityViewMain .expenseDetails > div {
    flex-direction: column;
  }
  .ViewExpenseMain .expenseDetails > div > div,
  .activityViewMain .expenseDetails > div > div {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0025em;
    color: #3d4551;
    flex-direction: column;
  }
  .ViewExpenseMain .expenseDetails > div,
  .activityViewMain .expenseDetails > div {
    padding: 7px 0px;
  }
  .ViewExpenseMain .expenseDetails,
  .activityViewMain .expenseDetails {
    font-size: 18px;
    line-height: 24px;
  }
  .ViewExpenseMain .expenseDetails > div,
  .activityViewMain .expenseDetails > div {
    flex-direction: column;
  }
  .ViewExpenseMain .dependentBorderBox,
  .activityViewMain .dependentBorderBox {
    padding: 5px 15px 20px 15px;
  }
  .ViewExpenseMain .expenseDetails > div,
  .activityViewMain .expenseDetails > div {
    padding: 0px;
    margin-bottom: 20px;
  }
  .ViewExpenseMain .expenseDetails label,
  .viewIncomeMain .expenseDetails label,
  .activityViewMain .expenseDetails label {
    color: #112e53;
  }
  .expenseDetails > div > div {
    color: #3d4551;
    font-size: 16px;
  }
  .expenseDetails .df.marginBottom50 {
    margin-bottom: 20px;
  }
  .ViewExpenseMain .editButton {
    margin-left: 0px;
    display: inline-block;
  }
  .ViewExpenseMain .editButton .btn-outline.saveButton {
    background: transparent !important;
    border: 0px;
    box-shadow: none;
  }
  .ViewExpenseMain .editButton .btn-outline.saveButton span {
    display: none;
  }
  .ViewExpenseMain .editButton button.btn-outline.saveButton.marginRight20 {
    padding: 9px 13px;
  }
  body .fileInfo h5 {
    margin-bottom: 0px;
  }
  .inventory_value {
    width: 100%;
    float: left;
  }
  .ViewExpenseMain .addExpenseTitleError .positionAbsoute {
    top: 35px;
  }
  h2.titleWithSidebar span {
    display: flex;
    align-items: center;
  }
  body .fileInfo h5 {
    line-height: 16px;
  }
  .fileInfo {
    margin-left: 2px;
  }
  .activityViewMain {
    margin-top: 20px;
  }

  .actionButton .mobileEditViewButton {
    background: #ffffff;
    color: #2d6cb2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 34px;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .actionButton .mobileEditViewButton span {
    font-size: 16px;
    margin-left: 0px;
    text-transform: capitalize;
    font-weight: normal;
  }
  .mobileDeleteViewButton button.cancelButton1.marginRight20.viewDeleteButton {
    width: 274px;
    display: block;
    margin: 20px auto 0px auto;
    font-size: 16px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -khtml-border-radius: 4px;
    border-radius: 4px;
  }
  .viewActivityTitleError .positionAbsoute {
    top: 40px;
  }
  .viewIncomeMain .dependentSubTitle {
    margin: 0px;
  }
  .viewIncomeMain .dependentBorderBox {
    padding-top: 0px;
  }
  .viewIncomeMain .expenseDetails > div {
    flex-direction: column;
  }
  .viewIncomeMain .dependentBorderBox {
    padding: 5px 15px 20px 15px;
  }
  .viewIncomeMain .expenseDetails > div {
    padding: 0px;
    margin-bottom: 20px;
  }
  /*.viewIncomeMain .expenseDetails label{color:#0352A0;}*/
  .expenseDetails > div > div {
    color: #3d4551;
    font-size: 16px;
  }
  .expenseDetails .df.marginBottom50 {
    margin-bottom: 20px;
  }
  .viewIncomeMain .dependentBorderBox {
    margin-top: 21px;
  }
  .viewIncomeMain .marginTopIncomeView {
    margin-top: 5px;
  }
  h2.titleWithSidebar.addIncomeTitle {
    margin: 10px 0px;
  }
  /******Expense View end**********/

  /******Expense List start**********/
  span.icoWrap.calendarDateCol.false {
    display: none;
  }
  span.icoWrap.calendarDateCol.thisActive {
    display: none;
  }
  .responsiveListTitle {
    float: left;
    width: 100%;
  }
  .multiSelectContainer input.checkbox {
    top: 12px;
  }
  .expenseListScreenMain h2.titleWithSidebar.responsiveListTitle,
  h2.titleWithSidebar.viewIncomeTitle,
  h2.titleWithSidebar.activityTitleMargin {
    margin-left: 0px;
    margin-right: 0px;
  }
  .expenseListScreenMain h2.titleWithSidebar.responsiveListTitle span,
  h2.titleWithSidebar.viewIncomeTitle span,
  h2.titleWithSidebar.activityTitleMargin span {
    position: static;
  }
  .responsiveListTableTop .tableTopLeft.df {
    width: 130px;
    overflow: inherit;
    height: 34px;
    position: relative;
  }
  .responsiveListTableTop.expenseBoxCol .expenseTableTitle,
  .responsiveListTableTop.incomeBoxcol .incomeTableMargin,
  .responsiveListTableTop .activityTableTitle {
    margin-top: 0px;
  }
  .responsiveListTableTop .tableTopMain {
    margin-bottom: 10px;
  }
  .responsiveListTableTop.expenseBoxCol .dateWrap,
  .responsiveListTableTop.incomeBoxcol .dateWrap,
  .responsiveListTableTop.activityBoxCol .dateWrap {
    background: #fff;
    width: 330px;
    top: 45px;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    float: left;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    display: none;
  }
  .dateWrap .typeTitleFilter {
    padding: 0px 10px 10px 10px;
  }
  .calendarDisplayBlock.calendarDisplayBlock .dateStartEnd {
    padding: 5px 0px 5px 0px;
  }
  .responsiveListTableTop.expenseBoxCol .calendarDisplayBlock,
  .responsiveListTableTop.incomeBoxcol .calendarDisplayBlock,
  .responsiveListTableTop.activityBoxCol .calendarDisplayBlock {
    display: block;
  }
  .responsiveListTableTop .tableTopLeft .dateStart,
  .responsiveListTableTop .tableTopLeft .dateEnd {
    width: 96%;
    float: left;
    flex-direction: column;
    border-bottom: 1px solid #3d4551;
    padding-left: 0px;
    margin-left: 10px;
    margin-bottom: 15px;
  }
  .searchWrapper input.searchBox::placeholder {
    font-size: 16px !important;
  }
  .responsiveListTableTop .fromText,
  .responsiveListTableTop .txtTo {
    color: #112e53;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    padding-left: 0px;
    text-transform: uppercase;
  }
  .responsiveListTableTop .tagFilterColum {
    padding-left: 10px;
  }
  .calendarDisplayBlock.calendarDisplayBlock .dateStartEnd {
    padding-right: 10px;
    flex-direction: column;
  }
  .tagFilterColum label input.checked,
  .tagFilterColum label input:hover {
    width: 37px;
    height: 37px;
  }
  .tagFilterColum label input.checked,
  .tagFilterColum label input:hover,
  .tagFilterColum label input.checked,
  .tagFilterColum label input {
    width: 37px;
    height: 37px;
    background-size: 27px;
  }
  .responsiveListTableTop .dateWrap input {
    font-size: 16px;
  }
  .responsiveListTableTop .filterByDate {
    font-size: 18px;
    color: #3d4551;
    line-height: 24px;
    font-weight: 600;
    padding-left: 10px;
    margin: 10px 0px;
  }
  .filterCancelButton.btn-outline.saveButton {
    font-size: 16px;
    color: #1f1f1f;
    line-height: 24px;
    font-weight: 500;
    border: 0px;
    box-shadow: none;
    text-transform: capitalize;
  }
  body .responsiveListTableTop .filterButton.btn-outline.saveButton {
    font-size: 16px;
  }
  .filterButton {
    font-weight: 500;
    font-size: 16px;
  }
  span.fromText.hideDesktopShowMobile {
    display: block;
  }
  .responsiveListTableTop .dateColButonRow {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-left: 0px;
    margin-left: 0px;
  }
  .responsiveListTableTop.expenseBoxCol .dateWrap:before,
  .responsiveListTableTop.incomeBoxcol .dateWrap:before,
  .responsiveListTableTop.activityBoxCol .dateWrap:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border-width: 8px;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    top: -16px;
    left: 53px;
  }
  .responsiveListTableTop.activityBoxCol .dateWrap:before {
    top: -16px;
    left: 53px;
  }
  .responsiveListTableTop.expenseBoxCol .a12-icon,
  .responsiveListTableTop.incomeBoxcol .a12-icon,
  .responsiveListTableTop.activityBoxCol .a12-icon {
    display: inline-block;
  }
  .responsiveListTableTop.medicalBoxCol .a12-icon {
    display: flex;
  }
  .medicalListMain .tableTopMain span > span,
  .responsiveListTableTop.expenseBoxCol .a12-icon,
  .responsiveListTableTop.incomeBoxcol .incomeTableTopLeft span.a12-icon,
  .responsiveListTableTop.activityBoxCol .tableTopLeft span.a12-icon {
    width: 34px;
    height: 34px;
    background: #d96941;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    line-height: 20px;
    min-width: 34px;
  }
  .dateIcon svg {
    stroke: #fff;
    height: 18px;
    cursor: pointer;
  }
  .responsiveListTableTop .tableTopMain .plusIcon {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }
  .medicalListMain .tableTopMain span > span.filterText,
  .filterText {
    display: none;
  }
  .responsiveListTableTop .tableTopRight .lblTotal {
    padding: 2px 10px;
    background: #faf8f4;
    border-radius: 4px;
    position: absolute;
    right: 0px;
    top: -77px;
    margin: 0px;
  }
  .responsiveListTableTop .tableTopMain {
    justify-content: space-between;
  }
  .responsiveListTableTop .tableTopRight .lblTotal {
    color: #6c7075;
    text-transform: uppercase;
  }
  .responsiveListTableTop .lblTotal {
    font-size: 14px;
  }
  .responsiveListTableTop.expenseBoxCol,
  .responsiveListTableTop.incomeBoxcol {
    padding: 0px;
  }
  .responsiveListTableTop .tableTopMain .plusIcon span {
    font-size: 30px;
    line-height: 22px;
  }
  .filterDorpDownMain .responsiveListTableTop.expenseBoxCol .dateWrap,
  .filterDorpDownMain .responsiveListTableTop.incomeBoxcol .dateWrap {
    background: #fff;
    width: 266px;
    top: 45px;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    float: left;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 20px;
  }
  .filterDorpDownMain {
    float: left;
    position: absolute;
    left: 0px;
    top: 44px;
    z-index: 9;
    background: #fff;
    padding: 0px;
    width: 266px;
    display: none;
  }
  .typeListFilter .multiSelectContainer li {
    font-size: 16px;
  }
  .filterTextIconColoum .dateIcon {
    padding-left: 0px;
    padding-right: 0px;
  }
  .filterTextIconColoum .dateIcon svg {
    margin-right: 0px;
  }
  .filterDorpDownMain:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border-width: 8px;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    top: -16px;
    left: 12px;
  }
  .filterDorpDownMain h3 {
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 0px;
  }
  .filterDorpDownMain ul {
    margin-bottom: 0px;
  }
  .filterDorpDownMain ul li {
    padding: 10px 15px;
    border-bottom: 1px solid #f5f5f5;
    text-transform: uppercase;
  }
  .filterDorpDownMain ul li:last-child {
    border-bottom: 0px;
  }
  .filterDorpDownMain ul li span {
    font-size: 16px;
    font-weight: 400;
  }
  .filterDorpDownMain ul li span.active,
  .filterDorpDownMain ul li span.active span {
    color: #2d6cb2;
    font-weight: 600;
  }
  .filterDorpDownMain ul li span.active.filterBy.asc i {
    margin-top: -2px;
  }
  .filterDorpDownMain ul li span.active.filterBy.dec i {
    margin-top: 5px;
  }
  .responsiveTalbe.activityTableTitle td:before,
  .responsiveTalbe.expenseTableTitle td:before,
  .responsiveTalbe.incomeTableTitle td:before {
    display: none;
  }
  .tableBody.responsiveTalbe.activityTableTitle td,
  .tableBody.responsiveTalbe.expenseTableTitle td,
  .tableBody.responsiveTalbe.incomeTableTitle td {
    padding-left: 10px;
  }
  .responsiveTalbe tfoot tr {
    display: none;
  }
  .responsiveTalbe.expenseTableTitle td.tac.tdAction {
    display: none;
  }
  .responsiveTalbe tr {
    flex-basis: 100%;
    flex-flow: row wrap;
    padding: 10px;
  }
  .responsiveTalbe tr td:nth-child(1) {
    float: right;
  }
  .responsiveTalbe tr td:nth-child(1) span {
    text-align: right;
    width: 100%;
    display: block;
  }
  .responsiveTalbe tr td:nth-child(2) {
    float: left;
  }
  .responsiveTalbe tr td:nth-child(2) span {
    font-weight: 600;
    font-size: 16px;
  }
  .responsiveTalbe tr td:nth-child(3) {
    display: inline-block;
  }
  .responsiveTalbe tr td:nth-child(4) {
    float: right;
  }
  .responsiveTalbe tr td:nth-child(4) span {
    text-align: right;
    width: 100%;
    display: block;
    color: #d96941;
  }
  .responsiveTalbe.medicalTableTitle tr td:nth-child(4) span {
    text-align: left;
    width: 100%;
    display: block;
    color: #3d4551;
  }
  .tableBody.responsiveTalbe.activityTableTitle td,
  .tableBody.responsiveTalbe.expenseTableTitle td,
  .tableBody.responsiveTalbe.incomeTableTitle td {
    width: 50%;
    padding: 0px 9px;
    min-height: 26px;
  }
  .responsiveListTableTop .tableTopMain {
    margin-top: 40px;
  }
  .expenseListRes_600 {
    min-height: calc(100vh - 24px);
  }
  .responsiveListTableTop.incomeBoxcol .fromText,
  .responsiveListTableTop.incomeBoxcol .filterByDate,
  .responsiveListTableTop.activityBoxCol .filterByDate,
  .responsiveListTableTop.activityBoxCol .fromText {
    padding-left: 0px;
  }

  .responsiveListTableTop.activityBoxCol .tableTopRight .lblTotal {
    top: -85px;
  }
  .responsiveTalbe.activityTableTitle td.tac.tdAction {
    display: none;
  }
  .tableBody.responsiveTalbe.activityTableTitle td {
    padding: 0px 5px;
  }
  .responsiveTalbe.activityTableTitle tr {
    flex-basis: 100%;
    flex-flow: row wrap;
    padding: 10px;
    float: left;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(1) {
    display: none;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(1) span {
    text-align: right;
    width: 100%;
    display: block;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(2) {
    float: right;
    width: 40%;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(2) span {
    font-weight: 400;
    font-size: 16px;
    float: right;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(3) {
    display: inline-block;
    width: 60%;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(3) span {
    font-weight: 600;
    width: 140px;
    margin-left: 3px;
  }
  .responsiveTalbe.activityTableTitle
    tr
    td:nth-child(3)
    span.hideDesktopShowMobile.tagIcon {
    font-weight: 600;
    width: auto;
    display: inline-block;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(3) .tagDescripton_600 {
    width: 100%;
    display: flex;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(4) span {
    text-overflow: inherit;
    text-align: left;
    width: 100%;
    display: block;
    color: #3d4551;
    white-space: inherit;
    position: static;
    font-size: 16px;
    line-height: 18px;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(4) {
    float: left;
    width: 100%;
    padding-right: 110px;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(5) {
    position: absolute;
    right: 10px;
    width: auto;
  }
  .responsiveTalbe.activityTableTitle tr td:nth-child(5) span:after {
    content: "hours";
    display: inline-block;
    margin-left: 3px;
    text-align: right;
  }
  .titleWithSidebar .responsiveTalbe.incomeTableTitle tr {
    flex-basis: 100%;
    flex-flow: row wrap;
    padding: 10px;
    float: left;
  }
  .responsiveTalbe.incomeTableTitle tr {
    float: left;
  }
  .responsiveTalbe tr {
    float: left;
    width: 100%;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(1) {
    float: right;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(1) span {
    text-align: right;
    width: 100%;
    display: block;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(2) {
    float: left;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(2) span {
    font-weight: 600;
    font-size: 16px;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(3) {
    display: inline-block;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(4) {
    float: right;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(4) span {
    text-align: right;
    width: 100%;
    display: block;
    color: #d96941;
  }
  .incomeTableTitle.tableBody.responsiveTalbe td {
    width: 50%;
    padding: 5px 10px;
  }
  .responsiveTalbe.incomeTableTitle td.tdAction {
    display: none;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(3) {
    display: none;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(4) {
    float: left;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(4) span {
    text-align: left;
  }
  .incomeTableTitle.tableBody.responsiveTalbe td {
    min-height: 26px;
    padding: 0px 9px;
  }
  .responsiveTalbe.incomeTableTitle tr td:nth-child(4) span {
    color: #6baa45;
  }

  .rightPanelWidhtSidebar .responsiveTalbe .noData {
    margin-top: 0px;
  }
  /******Expense List end**********/

  /*****popUp css start***/
  .deleteElement .areYouText {
    font-size: 24px;
    line-height: 34px;
  }
  /*****popUp css end***/

  /*****Dependent css start***/
  .titleNameAndTabHeader:before,
  h2.titleWithSidebar.headerFixed,
  .dependentTabContainer {
    background: #f4efe7;
  }
  .dependentTabRow {
    height: 34px;
  }
  .dependentTab:nth-child(1) span {
    width: 83px;
  }
  .dependentProfileMainViewBox {
    padding: 20px 0;
  }
  .dependentTab {
    padding: 7px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .geneInfo_value {
    font-size: 16px;
  }
  .dependentNoAddressData .geneInfo_value {
    font-size: 15px;
  }
  .dependentNoAddressData .geneInfo_label {
    font-size: 13px;
  }
  .generaInfoRow {
    padding-left: 175px;
  }
  /*.dependentTabContainerCol{background:#FAF8F4;}*/
  .dependentColradioButton [type="radio"] {
    margin-top: 0px;
  }

  .dependentAddressTitle.marginBottom30.textStyle.positionRelative {
    margin-bottom: 10px;
  }
  .dependentAddressTitle {
    padding-right: 15px;
  }
  .dependentAdvocateTeamRow,
  .dependentAddressRow {
    background: #faf8f4;
    border-bottom: 1px solid #e6dfd5;
    border-radius: 0px;
  }
  #Address {
    padding-bottom: 0px;
  }
  .myProfileViewAccount .textStyle {
    font-size: 20px;
  }
  textarea.txtNote::placeholder {
    font-size: 16px;
  }

  .dependentEditProfileMain .dependentBorderBox {
    background: transparent;
  }
  .dependentProile_Edit_resposnive .generalInfoProfileImg .dependentImgageCol {
    min-width: 66px;
  }
  .dependentProile_Edit_resposnive .dependentGenderCol span {
    line-height: 30px;
  }
  .dependentProile_Edit_resposnive .dependentSubTitle {
    color: #122e52;
    font-size: 20px;
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }
  .dependentEditProfileMain .guardianAppointmentRow600 {
    margin-top: 10px;
  }
  .dependentAddress.dependentProfileMainViewBox {
    padding-top: 0px;
  }

  .dependentAddressEditMain .dependentBorderBox {
    background: transparent;
  }
  .width37.city,
  .width37.zip {
    width: 100%;
  }
  body .addressRow.width63 {
    padding-left: 0px;
    width: 100%;
  }
  .dependentResidencCol .dependentResidence__value-container,
  .dependentResidencCol .dependentResidence__control {
    padding: 0px 0 2px 0 !important;
  }
  .dependentStepEditFormRow .labelColMargin3Bottom {
    margin-bottom: 3px;
    display: inline-block;
  }
  .dependentStepEditFormRow.addressMargin {
    margin: 0px;
  }
  .dependentAddressEditMain .address2,
  .dependentAddressEditMain .city {
    margin-top: 0px;
  }
  .addressLabelTop10 {
    margin-top: 10px;
  }
  .addressRow .countryState,
  .addressRow .country {
    margin: 0px;
  }
  h2.titleWithSidebar {
    text-align: left;
  }
  .addIncomesWrap .formRow label.prescribeDoctorLable {
    margin-top: 20px;
  }
  .addIncomesWrap .medicalForm .payRow .width50 {
    margin-bottom: 20px;
  }
  .addIncomesWrap
    .medicalForm
    .formRow.fullWidth.formRowGap.medicalMarBottom20 {
    margin-bottom: 15px;
    margin-top: 7px;
  }
  label.lblExpense.medicaAttachemnt600 {
    padding: 0px 0px 2px 0px;
  }
  .generalInfoProfileImg.dependentPro .width300Center {
    left: -50px;
  }
  .generalInfoProfileImg.dependentPro .imageOptionList ul:before {
    left: 24%;
  }
  /*****Dependent css end***/
  /*****My Account View start*********/
  body .addExpensesWrap .dependentProfileMainViewBox h3 {
    font-weight: 600;
  }
  span.geneInfo_value.downloadMargin {
    margin-top: -6px;
  }
  /*****My Account View end*********/

  /****datePicker css*****/
  .react-datepicker-popper[data-placement^="top"],
  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 22px !important;
    bottom: 60px !important;
  }
  .dateStart
    .react-datepicker-popper[data-placement^="bottom"][data-placement^="bottom"] {
    padding-top: 75px !important;
  }
  .dateEnd .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 140px !important;
  }
  /****datePicker css*****/

  /*****My Account Edit start*********/
  body .myAccountPersonalInfo {
    padding-left: 15px;
    padding-right: 15px;
  }
  .myAccountPersonalInfo .personalInfoNameRow,
  .myAccountPersonalInfo .formRow {
    padding-left: 0;
    padding-right: 0;
  }
  .myAccountPersonalInfo .myAccountTitle {
    margin: 0px 0 20px;
    padding: 0;
  }
  .myAccountPersonalInfo .paddingLeft35.myAccountTitle.marginTop50 {
    margin-top: 50px;
  }
  .myAccountPersonalInfo .width48.marginTop768_10 {
    margin-top: 0px;
  }
  .myAccountPersonalInfo .width40,
  .myAccountPersonalInfo .width60 {
    width: 100%;
    padding-right: 0;
  }
  .myAccountPersonalInfo .width60.countryState {
    padding-left: 0;
    margin-top: 0;
  }
  body .myAccountPersonalInfo .addressRow {
    padding-left: 0;
  }
  .myAccountPersonalInfo label {
    position: static;
  }
  .myAccountPersonalInfo label.myProfle_phone_label600 {
    margin-top: 10px;
  }
  .myAccountPersonalInfo .fullWidth.firstLastNameSpace {
    margin: 10px 0px;
  }
  .myAccountPersonalInfo .myAccountFormFiled.formMyPersonalInfo {
    margin: 0px 0px 5px 0px;
  }
  .myAccountPersonalInfo .myProfle_phone_label600,
  .myAccountPersonalInfo .addressLable {
    margin-top: 10px;
  }
  .myAccountPersonalInfo .personalInfoNameRow.marginTop768_20.myProfileAddres1 {
    margin-top: 0;
  }
  .myAccountPersonalInfo
    .personalInfoNameRow.marginTop768_20.myProfileAddres1.lblUnit {
    margin-top: 0;
  }
  .myAccountPersonalInfo .myProfilePhoneNumber600 {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .myAccountPersonalInfo .fullWidth.cityCountryState .addressRow label {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .myAccountPersonalInfo .positionTop768_25.lblZip {
    margin-top: 0;
  }
  .myAccountPersonalInfo .fullWidth.firstLastNameSpace.cityWrap {
    margin-top: 5px;
  }
  .myAccountPersonalInfo .fullWidth.firstLastNameSpace.zipWrap {
    margin: 5px 0px 20px 0px;
  }
  .myAccountPersonalInfo
    .fullWidth
    .textAlignRight.marginTop30.marginBottom30.displayFlexCol {
    padding-right: 15px;
  }
  .myAccountPersonalInfo.myAccountPassordScreen {
    padding-top: 0;
  }
  .myAccountPersonalInfo .myProfile_security {
    margin-top: 50px !important;
  }
  .myAccountPersonalInfo.myAccountPassordScreen label {
    width: 100%;
  }
  .myAccountPersonalInfo.myAccountPassordScreen .fullWidth.marginTop30 {
    margin-top: 0;
  }
  .myAccountPersonalInfo.myAccountPassordScreen
    .passwordLabel.positionAbsouteOnly.bottom40.paddingRight140 {
    padding-left: 0;
    padding-right: 30px;
  }
  .myAccountPersonalInfo.myAccountPassordScreen
    .widthAuto.changePasswordButton {
    margin-top: 0;
  }
  .billingFormWrap,
  .billingFormWrap .billingForm {
    margin-top: 7px;
  }
  .billingFormWrap,
  .billingFormWrap .billingForm label {
    position: static;
    width: 100%;
  }
  .billingForm .paddingLeft35.myAccountTitle {
    padding-left: 10px;
  }
  .billingForm .billingBox {
    padding: 0 10px;
  }
  .billingForm .billingBox .generalInfoSubTitle {
    margin-top: 35px;
  }
  .billingForm .billingBox .head24 {
    font-size: 16px;
  }
  .billingForm .billingBox .formRow {
    padding: 0;
    margin-top: 15px;
  }
  .billingForm .billingBox .widthAuto.marginButton.buttonRightMargin {
    margin-right: 20px;
  }
  .billingForm .billingBox .payRow .width50,
  .billingForm .billingBox .payRow .width50 + .width50 {
    padding-left: 0;
    width: 49%;
  }
  .billingBox .fullWidth.borderBotom.paddingBottom5 {
    padding-bottom: 0px;
  }
  /*****My Account Edit end*********/

  /******Inventory responsive start*******/
  .inventoryBoxCol .tableTopMain .tableTopRight .plusIcon {
    display: none;
  }
  .inventoryBoxCol.responsiveListTableTop .tableTopMain {
    margin-bottom: 0px;
    padding-bottom: 10px;
  }
  .inventoryListMain .activityTableTitle {
    margin-top: 0px;
  }
  .inventoryListMain .filterDorpDownMain {
    left: 0px;
  }
  .inventoryListMain h2.titleWithSidebar {
    margin-left: 0px;
    margin-right: 0px;
  }

  .inventoryListMain .assetTable tr td:nth-child(1),
  .inventoryListMain .liabilitiesInventory tr td:nth-child(1) {
    display: inline-block;
    float: right;
    position: static;
  }
  .inventoryListMain .assetTable tr td:nth-child(2),
  .inventoryListMain .liabilitiesInventory tr td:nth-child(2) {
    display: inline-block;
    float: left;
  }
  .inventoryListMain .assetTable tr td:nth-child(2) span,
  .inventoryListMain .liabilitiesInventory tr td:nth-child(2) span {
    font-weight: 600;
    float: left;
  }
  .inventoryListMain .assetTable tr td:nth-child(5),
  .inventoryListMain .assetTable tr td:nth-child(6),
  .inventoryListMain .liabilitiesInventory tr td:nth-child(5) {
    display: none;
  }
  .inventoryListMain .assetTable tr td:nth-child(3),
  .inventoryListMain .liabilitiesInventory tr td:nth-child(3) {
    width: 50%;
  }
  .inventoryListMain .assetTable tr td:nth-child(3) span,
  .inventoryListMain .liabilitiesInventory tr td:nth-child(3) span {
    margin-left: 0px;
    font-weight: 400;
  }
  .inventoryListMain .assetTable tr td:nth-child(4),
  .inventoryListMain .liabilitiesInventory tr td:nth-child(4) {
    padding-right: 0px;
    width: 48%;
    float: right;
  }
  .inventoryListMain .assetTable tr td:nth-child(4) span,
  .inventoryListMain .liabilitiesInventory tr td:nth-child(4) span {
    text-align: right;
    padding-right: 5px;
  }
  .inventoryListMain .assetTable.transferTable tr td:nth-child(5) {
    display: inline-block;
  }

  .inventoryListMain .assetTable.transferTable tr {
    flex-basis: 100%;
    flex-flow: row wrap;
    padding: 10px;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .inventoryListMain .assetTable.transferTable tr td:nth-child(2) {
    order: -1;
  }
  .inventoryListMain .assetTable.transferTable tr td:nth-child(4) {
    order: 5;
  }
  .inventoryListMain .assetTable.transferTable tr td:nth-child(4) span {
    text-align: left;
  }
  .inventoryListMain .assetTable.transferTable tr td:nth-child(5) {
    order: 0;
    position: static;
    text-align: left;
  }
  .inventoryListMain .assetTable.transferTable tr td:nth-child(5) span:after {
    display: none;
  }
  .inventoryListMain .assetTable.transferTable tfoot {
    display: none;
  }

  .inventoryResponsive h4 {
    margin-top: 20px;
  }
  .inventoryResponsive form {
    margin-top: 45px;
  }
  .inventoryResponsive form .formRow {
    margin-bottom: 20px;
  }
  .inventoryResponsive form .formRow.payRow {
    margin-bottom: 0px;
  }
  .inventoryResponsive form .formRow.payRow .width50 {
    margin-bottom: 20px;
  }
  .inventoryResponsive .insuranceTtyp600 {
    display: flex;
    flex-direction: row;
  }
  .inventoryResponsive .insuranceTtyp600 label {
    width: 120px;
  }
  .inventoryResponsive .insuranceTtyp600 label span {
    margin-top: 3px;
    line-height: 15px;
    text-transform: capitalize;
  }
  .inventoryResponsive .buttonRow600 {
    position: static;
    margin-top: 10px;
  }
  .insuranceTtyp600 input[type="radio"]:checked {
    border: 3px solid #2d6cb2;
    width: 14px;
    height: 14px;
  }
  /*input[type='radio'], input[type='checkbox']{width: 14px; height: 14px;}*/
  .inventoryResponsive .dependentSubTitle {
    font-size: 18px;
  }
  .addAssetResponsive h2.titleWithSidebar > span > svg {
    display: none;
  }
  .addAssetResponsive h2.titleWithSidebar {
    padding-left: 15px;
  }
  /*.addAssetResponsive .dependentBorderBox{padding:0px;}*/

  .institutionInventoryScreen .dependentSubTitle .vam {
    display: initial;
  }
  .institutionInventoryScreen .formRow .marginBottom30 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .institutionInventoryScreen .formRow {
    margin-bottom: 20px;
    margin-top: 0px;
  }
  .institutionInventoryScreen .width40,
  .institutionInventoryScreen .width40.city,
  .institutionInventoryScreen .width60.countryState,
  .institutionInventoryScreen .countryState {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  .institutionInventoryScreen .cityCountryState {
    margin-bottom: 0px;
  }
  .institutionInventoryScreen .cityCountryState .city {
    margin-bottom: 18px;
  }
  body .institutionInventoryScreen .addressRow .institutionStateLabel {
    margin: 0px;
  }
  .institutionInventoryScreen .dependentSubTitle {
    font-size: 18px;
  }

  .loandAgainstPolicyResponsive .addAssetForm .formRow {
    padding-right: 0px;
  }
  .loandAgainstPolicyResponsive .formRow.fullWidth.formRowGap {
    padding-right: 0px;
    margin-bottom: 0px;
  }
  .loandAgainstPolicyResponsive .formRow.fullWidth.formRowGap .width50 {
    margin-bottom: 20px;
  }
  .loandAgainstPolicyResponsive .amountWrap label {
    width: auto;
  }
  .loandAgainstPolicyResponsive .paymentTemrs span {
    font-size: 16px;
    margin-top: 2px;
  }
  .loandAgainstPolicyResponsive input[type="radio"] {
    height: 18px;
    width: 18px;
  }
  .loandAgainstPolicyResponsive .formRow label {
    margin-bottom: 10px;
  }
  .loandAgainstPolicyResponsive .radioAsset {
    padding-top: 0px;
    margin-bottom: 10px;
  }
  .loandAgainstPolicyResponsive .formRow .amountWrap label {
    margin-bottom: 0px;
  }

  .inventoryDetialBox {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;
    background: #faf8f4;
    margin-top: 10px;
  }
  .inventoryRow {
    padding-left: 0px;
    float: left;
  }
  .inventoryDetialBox .inventoryRow {
    width: 100%;
  }
  .inventory_Col_padding {
    padding: 10px 40px 0px 0px;
  }
  .inventory_Col_padding .cursorPointer {
    display: none;
  }
  .inventoryBox.opened .inventoryScreenTitle span.cursorPointer {
    display: none !important;
  }
  .invView .contorlAccessViewScreen {
    display: none;
  }
  .inventoryDetialBox .width50 {
    width: 50%;
  }
  .inventory_label {
    position: static;
    color: #112e53;
    font-size: 12px;
    font-weight: 600;
  }
  .inventory_value {
    color: #3d4551;
    font-size: 16px;
    line-height: 24px;
  }
  .btnAddEditWrap {
    right: 0px;
    top: 10px;
  }
  .inventoryDetialBox div.accessRow {
    padding-left: 0px;
    display: none;
  }
  .inventoryDetialBox div.showCol {
    display: block;
  }
  .btnAddEditWrap span svg {
    max-width: 18px;
  }
  .inventoryScreenTitle {
    font-size: 18px;
  }
  #Provider .inventory_label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  #Provider .inventory_value {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }
  .Location .inventory_label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .Location .inventory_value {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }
  .width50_600 {
    width: 50% !important;
  }
  /* .invView .noDataInventory{margin-top:15px;} */
  .institutionNameCol {
    color: #3d4551;
    font-size: 14px;
    line-height: 20px;
  }
  .contactBox {
    margin-top: 10px;
    padding: 0px;
  }
  .contactBox .contactInfo {
    width: 100%;
  }
  .contactBox .personList {
    width: 100%;
    background: #faf8f4;
    border-radius: 4px;
    padding: 0px;
    margin: 0px;
  }
  .contactBox .personInfo {
    display: block;
  }
  .personInfo .pEmail,
  .pAction {
    display: none;
  }
  .institutionNameCol {
    color: #3d4551;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .phoneNumberMobile {
    color: #71767a;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    margin: 0px 0px 8px 0px;
  }
  .personMain .mobileEmail {
    margin-bottom: 8px;
  }
  .personMain .pAddress {
    margin-bottom: 8px;
  }
  .phoneNumberMobile a {
    color: #71767a;
  }
  .contactIcon {
    display: inline-block;
    width: 20px;
  }
  span.contactIcon svg {
    vertical-align: -1px;
  }
  .personList .personMain {
    border-bottom: 1px solid #edeff0;
  }
  .pName.texAlingLeft.text24 {
    font-size: 16px;
    width: inherit;
    line-height: 20px;
  }
  .personList .person:last-child {
    border-bottom: 0px solid #edeff0;
  }
  .personList .pAddress {
    color: #71767a;
    font-size: 14px;
    margin-top: 0px;
    line-height: 20px;
    font-weight: 400;
  }
  .personList .mobileEmail {
    text-align: left;
  }
  .personList .mobileEmail a {
    color: #71767a;
    font-size: 14px;
    display: flex;
    word-break: break-all;
    line-height: 14px;
    padding-right: 10px;
    width: 100%;
    float: left;
  }
  .person .positionAbsouteOnly > svg {
    width: 32px;
    height: 32px;
  }
  .personList .personMain .person {
    padding-left: 65px;
  }
  .personList .personMain .person {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .personList .beneficiaryMain .person {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .personList .personMain .peronOtherInfo {
    padding-left: 25px;
    padding-bottom: 15px;
  }
  .peronOtherInfo > div {
    display: flex;
  }
  .contactBox .personInfo span {
    height: 32px;
  }
  .pAddress span.contactIcon svg {
    vertical-align: -3px;
  }
  .btnAddEditWrap {
    width: 38px;
  }
  .contactBox .personInfo {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .Beneficiary {
    margin: 0px;
    padding: 0px;
    margin-top: 10px;
  }
  .Beneficiary .person .positionAbsouteOnly {
    display: inherit;
  }
  .Beneficiary .personList {
    width: 100%;
    padding-right: 0px;
    margin-right: 0px;
  }
  .personList .beneficiaryMain {
    border-bottom: 1px solid #edeff0;
  }
  .beneficiaryMain .personInfo {
    padding: 0px;
  }
  .beneficiaryMain .peronOtherInfo {
    padding: 0px 0px 0px 25px;
  }
  .inventoryDetialBox .inventoryRow {
    margin-top: 15px;
  }
  .inventoryLocation svg,
  .invView .noDataInventory svg {
    width: 30px;
    height: 37px;
  }
  .inventoryLocation .noAddressText,
  .invView .noDataInventory .noAddressText {
    font-size: 16px;
    line-height: 18px;
  }
  .noAddressDescription {
    font-size: 13px;
    line-height: 17px;
  }

  .CoOwnerMainBox {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 5px;
  }
  .CoOwnerMainBox .personList {
    width: 100%;
    margin-top: 0;
  }
  .invView .inventoryRow span.inventory_label {
    max-width: 100%;
    width: 100%;
    display: block;
  }
  .inventory_value.accountWidth {
    max-width: 99px;
  }
  .inventory_value.accountWidth190 {
    max-width: 190px;
  }
  .accountShowhide600 {
    margin-left: 5px;
    margin-top: 1px;
  }

  /* .assetTransferView{padding:0px 15px;} */
  .inventory_value {
    min-height: inherit;
  }
  .providerDetailBox svg,
  .addressInfo svg {
    height: 40px;
    width: 40px;
    margin-top: 15px;
  }
  .invView .noAddressText {
    font-size: 16px;
  }
  .providerDetailBox .loaderPol,
  .loanAgainPolicyLoader,
  .loaderMarginTop {
    margin-top: 10px;
  }
  /*.invView .inventoryDetialBox .accessRowlg {display: none;}*/
  .marginLeft5_600 {
    margin-left: 5px;
    margin-top: 3px;
  }
  .invView {
    padding-bottom: 50px;
    float: left;
    width: 100%;
  }
  div#documentListRow.documentListRow {
    padding-bottom: 30px;
  }
  .pEmail {
    word-break: break-word;
    width: 100%;
  }
  .personList .mobileEmail a {
    margin-bottom: 8px;
  }
  #BankInformation .inventory_label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  #BankInformation .inventory_value {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }
  #Location .inventory_label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  #Location .inventory_value {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }
  .logoCol img {
    max-width: 187px;
  }
  .accountInfo .inventory_value.accountWidth {
    max-width: calc(100% - 26px);
  }
  /******Inventory responsive end*******/

  .fw-sm {
    width: 100% !important;
  }
  .myAccountRightCol {
    float: left;
    min-width: unset;
    width: 100%;
  }
  .tabTitle {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5px;
  }
  .tabRowMyAccount ul {
    height: 50px;
  }
  .tabRowMyAccount ul li:before {
    border-width: 26px;
    right: -30px;
  }
  .tabRowMyAccount ul li:nth-child(2):before {
    right: -51px;
  }
  .tabTitle:after {
    width: 37px;
    height: 35px;
    right: -17px;
  }
  .tabTitle span.tabText {
    font-size: 10px;
    line-height: 16px;
    margin-top: 2px;
    white-space: nowrap;
  }
  .tabRowMyAccount ul li:last-child:before {
    display: none;
    width: 130px;
  }
  .tabRowMyAccount ul li:first-child {
    width: 36%;
  }
  .tabRowMyAccount ul li:first-child .tabTitle {
    padding-left: 14px;
  }
  .tabRowMyAccount ul li:nth-child(2) {
    width: 27%;
  }
  .tabRowMyAccount ul li:nth-child(2).active {
    background: #f2a950;
  }
  .tabRowMyAccount ul li:nth-child(3) {
    width: 37%;
  }
  .tabTitle span.count {
    width: 21px;
    height: 21px;
    font-size: 14px;
    line-height: 21px;
  }
  .onBoardingFlow.myAccountRightCol .signInFormBox {
    padding: 0;
  }
  .tabContainerMyAccount {
    padding: 0px 0 95px 0;
  }
  .paddingTop55.newFormUI {
    padding-top: 0px;
  }
  .onBoardingFlow.myAccountRightCol .width32,
  .onBoardingFlow.myAccountRightCol label {
    width: 100%;
    margin: 0 0 12px !important;
  }
  .onBoardingFlow.myAccountRightCol .paddingRight16.formCol {
    padding-right: 0;
  }
  .onBoardingFlow.myAccountRightCol .paddingRight16.width50 {
    padding-right: 0;
    width: 100%;
  }
  .onBoardingFlow.myAccountRightCol .paddingleft16.width50 {
    padding-left: 0;
    width: 100%;
  }
  .onBoardingFlow.myAccountRightCol .paddingleft16.width50.cvcWrap {
    padding-left: 0;
    width: 100%;
    margin-top: 20px;
  }
  .onBoardingFlow.myAccountRightCol .maxWidth420,
  .onBoardingFlow.myAccountRightCol .maxWidth138px {
    max-width: unset;
    padding-left: 0;
  }
  .onBoardingFlow.myAccountRightCol .maxWidth138px {
    position: static;
  }
  .onBoardingFlow.myAccountRightCol .signUpFlowButtonRow {
    width: calc(100% - 28px);
  }
  .onBoardingFlow.myAccountRightCol .buttonHeight56,
  .onBoardingFlow.myAccountRightCol .backButton {
    height: 40px;
    width: 145px;
    font-size: 18px;
    line-height: 24px;
  }
  .onBoardingFlow.myAccountRightCol .backButton {
    width: 117px;
  }
  .onBoardingFlow.myAccountRightCol
    .firstLastNameSpace.fullWidth.marginTop768_20.arressFirstRow.myProfileAddres1 {
    margin: 5px 0;
  }
  .onBoardingFlow.myAccountRightCol .fullWidth.firstLastNameSpace {
    margin: 10px 0 5px;
  }
  .onBoardingFlow.myAccountRightCol .width50.myProfileAddres2 {
    margin-top: 15px;
  }
  .onBoardingFlow.myAccountRightCol
    .myAccountPersonalInfo
    .react-tel-input
    .form-control {
    font-size: 16px;
  }
  .onBoardingFlow.myAccountRightCol .dependentDefaultMainC0l.fullWidth {
    padding: 0 15px;
  }
  .onBoardingFlow.myAccountRightCol .dependentSubTitle.marginTop30 {
    font-size: 16px;
    margin-top: 10px;
  }
  /* .onBoardingFlow.myAccountRightCol .newFormUI.dependentStep1{margin-top: 0;padding-top: 0;} */
  .onBoardingFlow.myAccountRightCol .dependentGenderCol {
    display: flex;
    align-items: center;
  }
  .onBoardingFlow.myAccountRightCol .dependentGenderCol label {
    width: 70px;
  }
  .onBoardingFlow.myAccountRightCol .labelCol.label_gender,
  .onBoardingFlow.myAccountRightCol .signUpImgUploadBox {
    margin-bottom: 0 !important;
  }
  /* .onBoardingFlow.myAccountRightCol .dependentDefaultMainC0l .dependentSubTitle.marginTop30.marginBottom5{margin-top: 0;} */
  .onBoardingFlow.myAccountRightCol
    .dependentDefaultMainC0l
    .dependentSubTitle.marginTop30.marginBottom5.step2Title {
    margin-top: 10px;
  }
  .onBoardingFlow.myAccountRightCol .fullWidth.marginTop50 {
    margin-top: 10px;
  }
  .onBoardingFlow.myAccountRightCol .roleTypeList label {
    text-transform: none;
    margin-bottom: 20px !important;
  }
  .onBoardingFlow.myAccountRightCol .roleTypeList label span {
    font-size: 16px;
    color: #030303;
    line-height: 1.3;
  }
  .onBoardingFlow.myAccountRightCol .billingFormWrap {
    padding: 0 15px;
  }
  .onBoardingFlow.myAccountRightCol h3.generalInfoSubTitle {
    margin: 0;
    font-size: 16px;
  }
  .onBoardingFlow.myAccountRightCol
    h3.generalInfoSubTitle
    + .formRow.fullWidth {
    margin-top: 10px;
  }
  .onBoardingFlow.myAccountRightCol .billingBgheader.marginBottom30 {
    margin-bottom: 15px;
  }
  /* .onBoardingFlow.myAccountRightCol .signInFormBox{align-items: unset; justify-content: unset;} */
  .onBoardingFlow.myAccountRightCol .titleRow.dependentWizard {
    margin-top: 0;
  }
  /* .onBoardingFlow.myAccountRightCol .paddingTop30.newFormUI{padding-top: 10px;} */
  .onBoardingFlow.myAccountRightCol
    .maxWidth420.fullWidth.paddingRight16.formCol.selectBoxParend.myProfilePhoneCol1_600 {
    display: flex;
    align-items: flex-end;
  }
  .onBoardingFlow.myAccountRightCol
    .maxWidth420.fullWidth.paddingRight16.formCol.selectBoxParend.myProfilePhoneCol1_600
    .phoneTypeCol.maxWidth138px.phoneDropDownResponsiveCol {
    width: 100px;
  }
  .onBoardingFlow.myAccountRightCol
    .maxWidth420.fullWidth.paddingRight16.formCol.selectBoxParend.myProfilePhoneCol1_600
    .myProfilePhoneNumber600 {
    width: calc(100% - 120px);
    margin-left: auto;
    margin-bottom: 20px;
  }
  .onBoardingFlow.myAccountRightCol
    .maxWidth420.fullWidth.paddingRight16.formCol.selectBoxParend.myProfilePhoneCol1_600
    .errorRelativeRow {
    position: absolute;
    top: 85%;
  }
  .onBoardingFlow.myAccountRightCol input::-webkit-input-placeholder,
  .onBoardingFlow.myAccountRightCol select::-webkit-input-placeholder,
  .onBoardingFlow.myAccountRightCol textarea::-webkit-input-placeholder {
    /* Edge */
    font-size: 16px !important;
  }
  .onBoardingFlow.myAccountRightCol input::-ms-input-placeholder,
  .onBoardingFlow.myAccountRightCol select::-ms-input-placeholder,
  .onBoardingFlow.myAccountRightCol textarea::-ms-input-placeholder {
    /* Edge */
    font-size: 16px !important;
  }
  .onBoardingFlow.myAccountRightCol input::placeholder,
  .onBoardingFlow.myAccountRightCol select::placeholder,
  .onBoardingFlow.myAccountRightCol textarea::placeholder {
    /* Edge */
    font-size: 16px !important;
  }
  .onBoardingFlow .popUpBoxCol {
    min-width: unset;
  }
  .onBoardingFlow .signUpImgUploadBox .width268Center {
    left: -75px;
  }
  .onBoardingFlow .imageOptionList {
    width: 200px;
  }
  .onBoardingFlow .imageOptionList li,
  .onBoardingFlow .imageOptionList li label {
    font-size: 14px !important;
  }
  .onBoardingFlow .imageOptionList li label {
    margin-bottom: 0 !important;
  }
  .onBoardingFlow .signUpImgUploadBox .SignUp-custom-file-upload {
    margin-bottom: 0 !important;
    width: 66px !important;
  }

  .myAccountRightCol .myAccountPersonalInfo .marginTop32_600 {
    margin-top: 30px;
  }
  /*.onBoardingFlow.myAccountRightCol {}*/

  /******document css start*******/
  .documentListRow {
    padding-top: 15px;
  }
  .documentListCol {
    width: 100%;
  }
  /*.btn-primary.saveButton{min-width: inherit}*/
  /*.plusIcon span{line-height: 25px;}*/
  .documentListCol .inputfolderName textarea {
    display: none;
  }
  .inputfolderName p {
    margin: 0px;
    text-align: left;
  }
  .documentTitle span {
    font-size: 24px;
    justify-content: center;
  }
  .responsiveMoveFolderList .documentListbuttonRow.fullWidth {
    position: fixed;
    bottom: 0px;
    background: #fff;
    left: 0px;
  }
  /*h2.titleWithSidebar:before{height: 44px;}*/
  /*.mainRightSidebare{margin-top:40px;}*/
  .responsiveMoveFolderList {
    margin-top: 45px;
  }
  .documentBoxMain {
    margin: 0px;
    float: left;
    width: 100%;
  }
  .documentBoxMain h2.titleWithSidebar {
    float: left;
    width: 100%;
  }
  .addIconDocument {
    top: 18px;
  }
  .docList {
    height: 100%;
  }
  .paddingDocument {
    padding-top: 5px;
  }
  .listOfFolder {
    width: 100%;
  }
  .documentTopRow {
    top: 100px;
  }
  /*.buttonColMove.width50,.buttonColCancel.width50{width:50%;}*/
  .docList {
    height: auto;
  }
  span.fodlerIcon.paddingRight16 {
    display: inline-flex;
  }
  .documentListbuttonRow {
    padding: 20px 0px;
  }
  .backArrowDocument {
    display: inline-block;
    margin-top: 12px;
  }
  .documentListRow {
    min-height: 600px;
  }
  .documentPopUpActionList ul li:before {
    background: #c6cace;
  }
  body .documentListRow .documentListCol:hover,
  body .documentListRow .documentListCol.selectedFileFolder {
    background: transparent;
    border-color: transparent;
  }
  .documentItemDropDown {
    display: none;
  }
  .sortByName {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #71767a;
    padding: 15px 0px 0px 25px;
    cursor: pointer;
  }

  .documentListCol {
    height: inherit;
  }
  .documentTitle span {
    position: static;
  }
  h2.titleWithSidebar span {
    align-items: flex-start;
    display: inline-block;
  }
  .documentListRow {
    margin-top: 25px;
  }
  .documentListRow {
    background: transparent;
  }
  .hideDocRowList {
    display: none !important;
  }
  .documentListRow {
    min-height: inherit;
  }
  .documentListbuttonRow .buttonColCancel.width50,
  .documentListbuttonRow .buttonColMove.width50 {
    width: 50%;
  }
  .responsiveMoveFolderList {
    height: inherit !important;
    background: transparent;
    margin-top: 0px;
  }
  .documentListbuttonRow {
    padding: 20px 0px;
    position: fixed;
    bottom: 0px;
  }
  .documentListbuttonRow {
    background: #f4efe7;
  }
  .documentPopUp .cancelButtonCol input,
  .documentPopUp .createButtonCol input {
    width: 100%;
  }
  .documentListbuttonRow .buttonColCancel {
    border: 1px solid #000;
    color: #000;
    height: 30px;
    font-size: 12px;
    line-height: 14px;
    background: #fff;
    width: auto !important;
    padding: 0px 20px;
    line-height: 27px;
  }
  .documentListbuttonRow .buttonColMove {
    border: 1px solid #2d6cb2;
    color: #fff;
    height: 30px;
    font-size: 12px;
    line-height: 14px;
    background: #2d6cb2;
    width: auto !important;
    padding: 0px 20px;
    line-height: 27px;
    margin-left: 10px;
  }
  .documentListbuttonRow {
    padding: 10px 65px 10px 15px;
    display: flex;
    justify-content: flex-end;
    left: 0px;
  }
  .documentPopUpActionList ul li:before {
    background: #dfe1e2;
  }
  .documentPopUp .createButtonCol input {
    color: #2d6cb2;
  }
  .documentPopUp .cancelButtonCol input,
  .documentPopUp .createButtonCol input {
    text-transform: capitalize;
  }
  .documentTopRow.documentTopRowShow ul {
    position: relative;
  }
  .documentTopRow.documentTopRowShow ul:before {
    content: "";
    border-width: 9px;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    width: 0px;
    height: 0px;
    position: absolute;
    right: 12px;
    top: -19px;
    z-index: 10;
  }
  .documentTopRow {
    border-radius: 5px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
  h2.titleWithSidebar .plusIcon span {
    font-size: 33px;
    line-height: 23px;
  }
  .documentPopUp .createButtonCol .loaderSpinner {
    margin: 0px;
  }
  .documentPopUp .loaderSpinner .popUpNoButton,
  .documentPopUp .loaderSpinner .saveButtonDisable {
    line-height: 24px;
  }
  /*.rightPanelWidhtSidebarContainer{padding-bottom:50px;}*/
  .myAccountPersonalInfo .myAccountFormFiled .positionAbsouteOnly.fullWidth {
    position: static;
    padding-right: 0px !important;
  }
  /*.rightPanelWidhtSidebarContainer.paddinBottom110{padding-bottom:50px;}*/
  /******document css end*******/
  .bottom_70_600 {
    bottom: 70px;
  }
  .bottom_50_600 {
    bottom: 50px;
  }
  .bottom_20_600 {
    bottom: 20px;
  }
  .bottom_25_600 {
    bottom: 25px;
  }
  .input#react-select-2-input {
    height: 0px;
    width: 0px;
    overflow: hidden;
  }
  span.css-1f43avz-a11yText-A11yText,
  span#react-select-2-live-region {
    display: none !important;
  }
  .dependentResidencCol span {
    width: 100% !important;
  }
  div#root {
    z-index: 100;
  }

  /***Billing promotion css start*****/
  .promoCodeFormRow {
    padding-left: 0px;
    padding-right: 85px;
  }
  .promoCodeMain {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 36px;
  }
  .onlyPromoScreen {
    margin-left: 0px;
  }
  .titlePaddingNone {
    padding-left: 0px;
  }
  .errorMessagePromocode {
    line-height: 15px;
    margin-top: 2px;
  }
  .promoCodeMain label {
    font-size: 16px;
  }
  .promoCodeButton {
    width: 80px;
    padding: 0px;
  }
  .referralSuccess {
    padding-left: 30px;
  }
  /***Billing promotion css end*****/
  .ViewMedicationMain h2.titleWithSidebar.addIncomeTitle.addMedicalTitle,
  .ViewDoctorMain h2.doctorViewBottomMargin_600.addIncomeTitle {
    margin-bottom: 53px;
  }
  .navDependent_Profile {
    margin-left: 17px;
  }
  .selectedProfileName {
    margin-left: 7px;
  }

  html {
    position: relative;
    padding-top: 3px;
    margin-top: 3px;
  }
  html:before {
    content: "";
    background: #112e53;
    height: 5px;
    position: fixed;
    top: -2px;
    width: 100%;
  }
  .sideBarLeftCol,
  .rightPanelWidhtSidebarContainer,
  .titleNameAndTabHeader,
  .titleNameAndTabHeader:after,
  .lightBackgroundBlueColor {
    background: transparent;
  }
  div#root,
  body,
  html {
    background: #f4efe7;
    margin: 0px;
    padding: 0px;
    float: left;
    width: 100%;
  }
  h2.titleWithSidebar:before {
    background: transparent;
  }
  .dependentColradioButton [type="radio"] {
    background-size: 18px;
    width: 20px;
    height: 23px;
    min-width: 20px;
    background-position-x: 2px;
  }
  .navDependentList {
    width: 225px;
  }
  .sideBarNav ul li:hover .animationWidthOnly,
  .sideBarNav ul li:hover a span svg {
    color: #7aa4ce;
    fill: #7aa4ce;
  }
  .readOnlyMyAccountTitle.tabRowMyAccount ul li:first-child .tabTitle {
    justify-content: center;
    padding-left: 15px;
  }
  .readOnlyMyAccountTitle.tabRowMyAccount .tabTitle span.tabText {
    font-size: 14px;
  }

  .inventory_Col_padding {
    padding-bottom: 0;
    margin-top: 10px;
  }
  /* .assetTransferView .btnAddEditWrap.df.fcol.jcc.aic {right: -15px;} */
  .billingForm .billingBox {
    position: static;
  }

  .medicalPosTop {
    top: 28px;
    position: relative;
  }
  .tableTop.medicalTopRow.fullWidth.tableTopMain {
    margin-top: 38px;
  }
  .medicalListMain .tableTopMain {
    align-items: flex-end;
  }
  .medicalListMain .tableTop .marginBottom10 {
    margin-bottom: 0px;
  }
  .medicalListMain h2.titleWithSidebar {
    position: relative;
  }
  span.titleRightContent.positionAbsoute {
    font-size: 14px;
  }

  /***list screens header css start ****/
  .responsiveListTableTop.activityBoxCol .tableTopRight .lblTotal,
  .responsiveListTableTop .tableTopRight .lblTotal {
    right: inherit;
    left: 0px;
    top: -45px;
  }
  .fullWidth600Col {
    width: 100%;
    float: left;
  }
  h2.overViewLabel .positionRelative .positionAbsoute,
  .overViewLabel .positionRelative .positionAbsoute {
    position: static;
  }
  h2.overViewLabel {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  h2.overViewLabel span.titleRightContent2 {
    position: absolute !important;
    right: 0px;
    top: 0px;
    font-size: 14px;
  }
  .maxWidth600.marginLeftRightAuto {
    order: 3;
    width: 100%;
    float: left;
    margin-top: 5px;
  }
  .fullWidth600Col span {
    padding-left: 3px;
  }
  .activityLog.fullWidth600Col span {
    padding-left: 6px;
  }
  .documentTitle span.titleRightContent2.positionAbsoute {
    font-size: 14px;
    right: 10px;
    top: 23px;
  }
  .documentListCol {
    padding-left: 0px;
    padding-right: 0px;
  }
  h2.titleWithSidebar.documentTitle.marginBottom30 {
    margin-bottom: 10px;
  }
  .addIconDocument {
    top: 55px;
  }
  .documentTitle {
    margin-bottom: 22px !important;
  }
  .documentListRow {
    padding-top: 0px;
    margin-top: 10px;
  }

  .incomeTableTitle.responsiveTalbe .ellipsis span {
    width: 147px;
  }
  /***list screens header css end ****/

  .advocateTeamCall {
    margin-right: 7px;
  }
  .advocateTeamCall svg {
    width: 16px;
  }

  .expenseTableTitle.responsiveTalbe tr td:nth-child(2) span,
  .expenseTableTitle.responsiveTalbe tr td:nth-child(3) span {
    width: 160px;
  }
  .payRow .width50 + .width50.repeatMonthlyCol {
    padding-left: 0px;
    margin-top: 10px;
  }
  .repeatMonthlyCol .marginTop5.df.aic {
    margin-top: 0px !important;
  }

  .payRow .width50 + .width50 label.repeatMonthlyLabelIncome {
    left: 0px;
  }
  .payRow .width50 + .width50.repeatMonthlyColIncome {
    padding-left: 0px;
  }
  .repeatMonthlyColIncome .marginTop5.df.aic {
    margin-top: 0px !important;
  }
  .personalInfoNameRow,
  .formRow.attachAllFileCol {
    padding-left: 0px;
    margin-top: 10px !important;
  }
  /******dashboard start*****/
  /*.dashboardColumMain{width:100%; margin:0px;}*/
  /*.dashboardColum{background:#FAF8F5;}*/
  /*.titleWithSidebarDashboard{font-size:24px; line-height:32px;}*/
  /*.dashboardColum_L{border-right: 0px; align-items: flex-start; padding-top:15px;}*/
  /*!*.quikLinkTitle{display: none;}*!*/
  /*.addExpensesWrap .MuiSwitch-input, .addIncomesWrap .MuiSwitch-input{left: inherit !important;}*/

  /*.dashboardColum{position:relative;}*/
  /*.dashboardColum_L{width:70px; padding-top: 25px; padding-left: 15px;}*/
  /*.dashboardColum_L svg{height: 38px;}*/
  /*.dashboardColum_R_T p{min-height: inherit;}*/
  /*.dashboardColum_R{width: calc(100% - 70px);}*/
  /*.dashboardColum_R_B{padding-top: 10px;background: #fff;position: absolute;left: 0px; bottom:0px;display: flex;align-items: center;justify-content: center;padding-bottom: 10px;}*/
  .dashboardColumMain {
    width: 100%;
    margin: 0px;
  }
  .titleWithSidebarDashboard {
    font-size: 24px;
  }
  .dashboardColum_R {
    background: #fff;
  }
  .dashboardColum_R_T p {
    min-height: inherit;
  }
  /*.dashboardColum{height: 220px;}*/
  /******dashboard end*****/

  .viewDeleteButton {
    display: flex;
    align-items: center;
  }
  .repeatResponsiveCol .df {
    flex-direction: column;
  }
  .df.repeatResponsiveCol .df + .df {
    margin-top: 20px;
  }

  .documentFixedHeader {
    width: 100%;
    position: static;
  }
  .documentFixedHeader:before,
  .documentFixedHeader:after {
    display: none;
  }
  .documentContent {
    margin-top: 0px;
  }
  .docTitleRow {
    margin-top: 0px;
  }
  .inventory_label20Padding {
    padding-left: 0px;
  }
  .documentTopMargin20 {
    margin-top: 0px;
  }

  .popUpWidthTrialEnd {
    width: 100%;
  }
  .sideBarVisible .popUpWidthTrialEnd {
    width: 100%;
  }
  .bottom-spacer {
    height: 200px;
  }
  .joinAdvocord {
    min-width: inherit;
    max-width: inherit;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .joinAdvocord span {
    font-size: 14px;
  }
  .promoCodeBoxBilling {
    width: 100%;
  }
  .freeTrialCountdown {
    position: static;
    width: 100%;
    margin-top: 10px;
  }
  .billingForm .billingBox .payRow .promoCode {
    width: 100%;
  }
  .titleWelcomeToAccount {
    font-size: 20px;
  }
  .signUpFlow.marginTop70 {
    margin-top: 80px;
  }
  .googleLoginBUtton span {
    font-size: 12px;
  }
  .infoBoxInviteUser {
    padding: 8px 3px;
  }
  .googleSignUpButton {
    padding-left: 0px;
    padding-right: 0px;
  }
  .letUsCreate {
    font-size: 14px;
  }
  .infoTextInviteUser {
    font-size: 13px;
  }

  .tableTopLeft .dateStart .react-datepicker__triangle,
  .tableTopLeft .dateEnd .react-datepicker__triangle {
    right: 70px !important;
  }
  .dateStart
    .react-datepicker-popper[data-placement^="bottom"][data-placement^="bottom"] {
    padding-top: 100px !important;
  }
  .dateEnd .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 168px !important;
  }
  .linkToWebsite {
    justify-content: flex-start;
    font-size: 13px;
    text-align: left;
    margin-left: 0px;
  }

  .surveyTitle,
  .rolePopUpTitle {
    font-size: 24px;
    line-height: 27px;
  }
  .roleList span {
    line-height: 21px;
  }
  .surveyMainList label {
    margin-bottom: 16px;
    display: flex;
  }
  .surveyTitleList span,
  .roleList span {
    font-size: 15px;
  }
  .skipButton,
  .pageCount {
    font-size: 14px;
  }
  .surveyTitleList input[type="radio"] {
    width: 17px;
    height: 17px;
    min-width: 17px;
  }
  .surveyMainList label:before {
    width: 10px;
    height: 5px;
    left: 4px;
    top: 8px;
  }

  .durationMarginTop20 {
    margin-top: 20px;
  }
  .viewNoteBox .listNote,
  .viewNoteBox .listNote::placeholder,
  .commentExpenseTab {
    font-size: 16px !important;
  }
  span.notesSaveButton {
    width: 87px;
    font-size: 16px;
    padding: 5px 12px;
  }
  .viewNoteBox .listNoteTitle {
    font-size: 17px;
    word-break: break-all;
  }
  .viewNoteBox .listNoteDateTime .noteCreated {
    font-size: 20px;
    display: inline-block;
  }
  .viewNoteBox .listNoteDateTime .noteItemCreatedDate {
    font-size: 13px;
  }
  .noteEditDeleteCol svg {
    width: 22px;
  }
  .showLabel {
    font-size: 12px;
  }
  .addExpenseScreenMain .payRow .width50.activityDateMarginBottom20 {
    margin-bottom: 20px;
  }
  .inventoryRow.commentSetionViewScreen {
    padding-left: 0px;
  }
  .linkToWebsiteMargin {
    margin-top: 0px;
  }
  .inventoryRow.commentSetionViewScreen {
    margin-top: 20px;
  }
  .commentMarginResponsive {
    margin-top: 10px;
  }
  .commentSetionViewScreen label {
    font-size: 12px;
    line-height: 12px;
  }
  .commentExpenseTab {
    width: 50%;
  }
  .inventory_value .fileList.df.fcol.fullWidth.p0 {
    padding-top: 0px !important;
    padding-bottom: 15px !important;
  }
  .linkToWebsite {
    padding-left: 0px;
  }
  .priceListedIs {
    font-size: 12px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .wordBreak {
    word-break: break-word;
  }
  .responsiveMarginTop50fullWidth {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1366px) {
  .signColCenter {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .marginBottomInputCol {
    margin-bottom: 15px;
    margin-top: 0px;
  }
  .firstLastNameSpaceSignUp {
    margin-bottom: 15px;
  }
  .termAndPrivacyPolicyRowSignUp {
    margin-top: 20px;
  }
  .joinNowText {
    margin-top: 3px;
  }
  .passwordMatchBox ul li {
    margin-bottom: 0px;
  }
  .passwordMatchBox ul {
    margin-bottom: 0px;
    float: left;
  }
  .allRightReserveText {
    bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  .dependentTabContainerCol .generalInfoProfileImg {
    min-width: 61px;
  }
}
.breakWords {
  word-break: break-all;
}
.tableTopLeft .a12-icon {
  z-index: 1;
}
.thisActive {
  position: relative;
}
.icoWrap > i {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.001);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.thisActive > i {
  display: block;
}
.livedWrap label {
  margin-top: -10px;
}
.noteText {
  max-height: 150px;
  overflow-y: auto;
  line-height: 1.25 !important;
  padding-right: 5px;
}
/*Scrollbar*/
.ayScrollBar::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 9px;
  background: lightgray;
}
.ayScrollBar::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(17, 46, 83, 0.2);
  border-radius: 5px;
}
.ayScrollBar::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  border-radius: 9px;
  -webkit-box-shadow: inset 0 0 6px rgba(17, 46, 83, 1);
  background: rgba(17, 46, 83, 0.5);
}

/*body {min-height: 100vh;min-height: -webkit-fill-available;}*/
/*html {height: -webkit-fill-available;}*/

.height22 {
  height: 22px;
  display: inline-block;
}

@media screen and (min-width: 769px) {
  div.invBox {
    background: #faf8f4;
    padding: 20px 30px;
  }
}
@media screen and (max-width: 768px) {
  div.pad_0_5 {
    padding: 0px 5px !important;
  }
  .docotorDetails.invBox {
    margin-bottom: 15px;
  }
  .myAccountViewMain h3.df.aic {
    margin-bottom: 10px;
  }
}
.addExpensesWrap .dependentSubTitle,
.addIncomesWrap .dependentSubTitle {
  margin-bottom: 0;
}
.depAddress .dependentAddressRow,
.depAddress .dependentAdvocateTeamRow {
  background-color: #faf8f4;
  border-radius: 0;
}

.tableTopRight button.saveButton[disabled],
.tableTopRight button.saveButton[disabled]:hover {
  background: #c6cace !important;
  color: #fff;
  border: 1px solid #c6cace;
  box-shadow: none;
  cursor: default;
}

.abonelawMain .signUpLogoCol {
  background: #1f3e63;
  border: 4px solid #5586be;
  border-radius: 45px;
  padding: 10px 10px;
  width: 100%;
  margin: 15px 0px;
}
.abonelawMain .signUpLogoCol img {
  width: 100%;
}
.abonelawMain .letUsCreate {
  line-height: 20px;
  margin-top: 10px;
  display: inline-block;
}
.abonelawMain .trustedPartner {
  margin-bottom: 8px;
  font-size: 18px;
  color: #1c1d1f;
}
.abonelawMain.signColCenter {
  padding: 25px 50px 20px 50px;
}
.fontbold {
  font-weight: bold;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  body .paddingLeftPaddingRight35.abonelawMain {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.textAlignCenter {
  text-align: center;
}
.maxWidth500 {
  max-width: 500px;
}
.linkColorText,
.linkColorText:hover {
  color: #3d4551;
  text-decoration: underline;
}

.marginBottom40 {
  margin-bottom: 40px;
}
.needToTalkText {
  color: #004599;
  margin-bottom: 40px;
  font-size: 22px;
}

.needToTalkText a,
.needToTalkText a:hover {
  text-decoration: underline;
  color: #004599;
}
